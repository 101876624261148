import { Button, Grid } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { withUserPreferences } from "components/UserPreferences/WithUserPreferences"
import { userPreferencesComponentIds, userPreferencesTypes } from "components/UserPreferences/userPreferencesConsts";
import { IconMap } from "icons";
import { useEffect } from "react";

const VehiclesMapButton = ({ showMap, setShowMap, style, savedCustomValue, saveCustomValue }) => {

    useEffect(() => {
        if (savedCustomValue) {
            setShowMap(savedCustomValue === "true" ? true : false);
        }
    }, [savedCustomValue])

    return (
        <>
            <Authorize profile={permissionProfiles.ASSETS.ASSETS_PAGE_SHOW_MAP}>
                <Grid item>
                    <Button variant="outlined" color="primary" className={style}
                        startIcon={<IconMap />}
                        onClick={() => { setShowMap(!showMap); saveCustomValue((!showMap).toString()) }}>{showMap ? "Hide Map" : "Show Map"}</Button>
                </Grid>
            </Authorize>
        </>
    );
}

export default withUserPreferences(VehiclesMapButton, userPreferencesComponentIds.ASSET_MAP, userPreferencesTypes.CUSTOM_VALUE);