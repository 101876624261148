import { makeStyles } from "@material-ui/core"

export const vehicleDetailsPageStyle = makeStyles(theme => ({
    icon: {
        fontSize: '500%',
        marginRight: "8px"
    },
    iconDark: {
        fontSize: '500%',
        fill: '#000',
        marginRight: '-3rem',
    },
    card: {
        margin: '0.5em 0',
        padding: '1.5em 2em',
    },
    tabsWrapper: {
        padding: '0.5em 2em',
    },
    sideBySide: {
        height: '570px',
        overflowY: 'auto'
    },
    cardTitle: {
        fontSize: '1.2em'
    },
    noteCard: {
        backgroundColor: '#fbfaff',
    },
    titleRow: {
        // marginBottom: "32px"
    },
    titleWrapper: {
        height: '3em',
        marginBottom: '1em',
        '& svg': {
            marginRight: '0.5em'
        }
    },
    title: {
        margin: 0,
        padding: 0,
        fontWeight: 500,
        marginRight: "8px",
        color: theme.typography.color
    },
    detailLabel: {
        marginRight: "4px",
        fontWeight: 600
    },
    dateInfo: {
        marginRight: 8
    },
    dateIn: {
        color: theme.palette.success.dark
    },
    dateOut: {
        color: theme.palette.error.dark
    },
    details: {
        color: theme.palette.secondary.light,
        fontSize: 16,
        maxWidth: "900px",
        "&:not(:last-child)": {
            marginBottom: "20px"
        }
    },
    detail: {
        // minWidth: "290px",
        position: "relative",
        fontSize: '0.9em'
        // "&:not(:first-child)": {
        //     paddingLeft: "16px",
        //     "&::before": {
        //         content: "''",
        //         width: "2px",
        //         height: "27px",
        //         background: theme.palette.neutral.main,
        //         position: "absolute",
        //         top: "50%",
        //         left: "0px",
        //         transform: "translate(0px, -50%)",
        //     }

        // },
    },
    vehicleDetail: {
        display: 'flex',
    },
    vehicleDetailLabel: {
        marginRight: '4px',
    },
    maintenance: {
        fontSize: 16,
        color: theme.palette.secondary.light,
        marginBottom: -16
    },
    maintenanceField: {
        position: "relative",
        minWidth: 268,
        marginBottom: 16,
        paddingLeft: 16,
        "&:not(:first-child)": {
            "&::before": {
                content: "''",
                width: 2,
                height: "100%",
                background: theme.palette.neutral.main,
                position: "absolute",
                top: 0,
                left: 0,
            }
        }
    },
    bordered: {
        position: "relative",
        minWidth: 268,
        marginBottom: 16,
        "&:not(:first-child)": {
            paddingLeft: 16,
            "&::before": {
                content: "''",
                width: 2,
                height: "100%",
                background: theme.palette.neutral.main,
                position: "absolute",
                top: 0,
                left: 0,
            }
        }
    },
    maintenanceDetail: {
        fontSize: 20,
        color: theme.typography.color
    },
    parkingDetail: {
        marginBottom: 8,
    },
    imageDeleteIcon: {
        position: "absolute",
        bottom: 0,
        left: 0,
        opacity: 0,
        transition: "opacity 0.5s ease",
    },
    imageContainer: {
        position: "relative",
        width: 223,
        maxWidth: '100%',
        height: 120,
        margin: "0 1rem 1rem 0",
        borderRadius: 4,
        overflow: "hidden",
        "&:hover": {
            "& $imageDeleteIcon": {
                opacity: 1,
            }
        }
    },
    fileName: {
        position: "absolute",
        left: '50%',
        bottom: '4px',
        fontSize: 12,
        opacity: 0.6,
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap',
        borderRadius: '2px',
        padding: '2px',
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        opacity: 0,
        transition: "opacity 1s ease",
        objectFit: "cover"
    },
    imagePreviewWrapper: {
        height: '120px',
        marginTop: '10px',
        '& img': {
            //objectFit: 'contain',
            position: 'initial'
        }
    },
    placeholder: {
        height: '100%',
        width: '100%',
        background: theme.palette.common.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    placeholderIcon: {
        opacity: ".2",
        width: "5rem",
        height: "5rem"
    },
    imageVisible: {
        opacity: 1
    },
    conditionReport: {
        marginRight: "2rem",
        color: theme.palette.error.main
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 100px)',
        zIndex: '100'
    },
    dateHeader: {
        width: 150
    },
    cardMobile: {
        padding: "1rem"
    },
    titleMobile: {
        fontSize: 16
    },
    titleRowMobile: {
        marginBottom: 0
    },
    detailsMobile: {
        "&:not(:last-child)": {
            marginBottom: "8px"
        }
    },
    detailMobile: {
        width: "100%",
        "&:not(:first-child)": {
            paddingLeft: 8,
            "&::before": {
                display: "none"
            }
        },
    },
    imageMobile: {
        width: "100%"
    },
    imageContainerMobile: {
        width: "auto",
        minHeight: 160,
        marginRight: 0
    },
    noAssetDiv: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    placeholderDetail: {
        minWidth: '290px'
    },
    exportButtonWrapper: {
        marginLeft: 'auto',
        '& button': {
            marginLeft: '1em'
        }
    },
    input: {
        marginTop: 16
    },
    editButton: {
        width: 163,
        borderBottom: '1px solid #0000000d',
    },
    docTitle: {
        fontWeight: 600,
        padding: '0.5em 0'
    },
    dateWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    // timeInput: {
    //     marginTop: 16,
    //     color: theme.palette.text.secondary,
    //     backgroundColor: theme.palette.background.default,
    //     minWidth: 260,
    // },
    inventoryWrapper: {
        padding: '0.5em 0'
    },
    addInventoryBtn: {
        marginRight: '0.1rem',
        marginTop: ".5rem"
    },
    paper: {
        width: "100%",
        padding: '.7rem',
        marginTop: "1rem"
    },
    extendedDataWrapper: {
        marginTop: '1em',
        marginBottom: '1em',
        paddingTop: '1em',
        borderTop: '1px solid #efefef'
    },
    ticketsTitle: {
        padding: '2em 0em 2em .5em',
        fontWeight: 600,
        fontSize: 15
    },
    iconWrapper: {
        marginTop: '.5em'
    },
    ticketButton: {
        paddingBottom: '0em'
    },
    addButton: {
        width: '140px',
        '& path': {
            stroke: '#3f8ef6',
            height: '10px'
        }
    },
    extendedFieldGroupHeader: {
        color: theme.palette.primary.main,
        fontWeight: '600',
        fontSize: '0.9em'
    },
    extendedFieldGroups: {
        marginTop: '1em'
    },
    extendedFieldGroup: {
        borderTop: '1px solid #efefef',

        '&.MuiAccordion-root': {
            boxShadow: 'none',
        },
        '& .MuiAccordionSummary-root': {
            padding: 0
        },
        '& .MuiAccordionDetails-root': {
            padding: 0
        },
        '&.MuiAccordion-root:before': {
            content: 'unset'
        },
        '&.MuiAccordion-root.Mui-expanded': {
            margin: 0,
            minHeight: 'unset',
            paddingBottom: '1em',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'unset',
        }
    },
    extendedSectionContainer: {
        border: '1px solid #efefef'
    },
    section: {
        background: '#f0f0f0',
        border: '1px solid #ddd',
        padding: '1em',
        margin: '1em 0',
        borderRadius: '5px'
    },
    extendedFieldSection: {
        background: '#f0f0f0',
        border: '1px solid #ddd',
        height: '15em',
        overflowY: 'auto',
        padding: '1em',
        borderRadius: '5px'
    },
    extendedFieldSectionsWrapper: {
        marginTop: '1em',
        marginBottom: '1em'
    },
    fieldGroup: {
        marginBottom: '1em',
        paddingBottom: '1em',
        borderBottom: '1px solid #ddd',
        '&:last-of-type': {
            borderBottom: 'none',
        },
    },
    minimalSummary:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    tabItem:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    itemIcon: {
        paddingRight: '1em'
    },
    tabSelecter: {
        border: '1px solid lightGrey',
        borderRadius: 3,
        backgroundColor: theme.palette.background.paper,
        padding:"0.2rem 0.2rem 0.2rem 1rem",
        width: "300px"
    },
    menuItem:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 3,
        backgroundColor: theme.palette.background.default,
        padding:"0.5rem",
        margin:0,
        width: "100%",
    },  
    menuItemText:{
        fontFamily: 'Montserrat',
        fontSize: 14
    }
}));
