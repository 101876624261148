import { Grid, Popover, Popper, Slide } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { useState } from "react";
import { ReportSectionStyles } from "./ReportSectionStyles";

export const AddSectionButton = ({ onAddSection, index }) => {
    const classes = ReportSectionStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    // const openPanel = (e) => {
    //     setAnchorEl(e.currentTarget);
    // }

    const onAddSectionClick = (index, numberOfColumns) => {
        onAddSection(index, numberOfColumns);
        setAnchorEl();
    }

    return (
        <>
            <Grid container direction="row" alignItems="center" className={classes.addSectionButton}>
                <Grid item className={classes.addSectionIconContainer} onClick={() => onAddSectionClick(index, 1)} >
                    <AddIcon className={classes.addSectionIcon} />
                </Grid>
                <Grid item className={classes.addSectionLine}>
                </Grid>
            </Grid>
            {/* <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div container spacing={2} direction='row' className={classes.selectColumns}>
                    <div item className={classes.selecetColumn} onClick={() => onAddSectionClick(index, 1)}>
                        <div className={classes.columns}>
                            <div style={{ width: '20px' }} className={classes.oneColumn}></div>
                        </div>
                        One Column
                    </div>
                    <div item className={classes.selecetColumn} onClick={() => onAddSectionClick(index, 2)}>
                        <div className={classes.columns}>
                            <div style={{ width: '10px' }} className={classes.oneColumn}></div>
                            <div style={{ width: '10px' }} className={classes.oneColumn}></div>
                        </div>
                        Two Columns
                    </div>
                    <div item className={classes.selecetColumn} onClick={() => onAddSectionClick(index, 3)}>
                        <div className={classes.columns}>
                            <div style={{ width: '6px' }} className={classes.oneColumn}></div>
                            <div style={{ width: '6px' }} className={classes.oneColumn}></div>
                            <div style={{ width: '6px' }} className={classes.oneColumn}></div>
                        </div>
                        Three Columns
                    </div>
                </div>
            </Popover> */}
        </>
    )
}