import { makeStyles } from "@material-ui/core";

export const customFormStyle = makeStyles((theme) => ({
    card: {
        margin: '1rem 0 1rem',
        padding: "1rem"
    },
    logo: {
        maxWidth: "60px",
        marginLeft: "1em"
    },
    loadingCard: {   
        margin: '1rem 0 1rem',
        padding: "1rem",
        minHeight: '80vh'
    },
    itemWrapper: {
        margin: "0.5rem",
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.default,
        borderRadius: 3,
    },
    title: {
        fontSize: '1.2em',
        color: '#0061a9',
    },
    sub:{
        fontSize: '2em',
        color: '#0061a9',
    },
    buttonsWrapper: {
        padding: "0.25rem",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: 'center',
    },
    backButton: {
        marginTop: '1rem'
    }
}));