import { modes } from "../modes";
import { permissions } from "../permissions/permissions";

export const userManagementPermissionProfiles = {
    ACCESS: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.ACCESS, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    CREATE_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.CREATE, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    EDIT_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.EDIT, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    DELETE_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.DELETE, permissions.USER_MANAGEMENT.ADD_TO_BLACK_LIST,
        permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    EXPORT_USER_ALL: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.EXPORT_ALL, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },

    TOTAL_ACTIVE_USER_SEE: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_ACTIVE, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    TOTAL_VIEW_ACCESS_TABLE: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_VIEW_ACCESS_TABLE, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    TOTAL_VIEW_ACCESS_EXPORT: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_VIEW_ACCESS_EXPORT, permissions.USER_MANAGEMENT.EXPORT_ALL, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },

    TOTAL_INACTIVE_USER_SEE: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_INACTIVE, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    TOTAL_INACTIVE_VIEW_ACCESS_EXPORT: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_INACTIVE_VIEW_ACCESS_EXPORT, permissions.USER_MANAGEMENT.EXPORT_ALL, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    TOTAL_INACTIVE_VIEW_ACCESS_TABLE: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_INACTIVE_VIEW_ACCESS_TABLE, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },

    TOTAL_BLACK_LIST: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_BLACK_LIST, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    TOTAL_BLACK_LIST_VIEW_ACCESS_EXPORT: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_BLACK_LIST_VIEW_ACCESS_EXPORT, permissions.USER_MANAGEMENT.EXPORT_ALL, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    TOTAL_BLACK_LIST_VIEW_ACCESS_TABLE: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_BLACK_LIST_VIEW_ACCESS_TABLE, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },

    TOTAL_PURGE: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_PURGE, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    TOTAL_PURGE_VIEW_ACCESS_EXPORT: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_PURGE_VIEW_ACCESS_EXPORT, permissions.USER_MANAGEMENT.EXPORT_ALL, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    TOTAL_PURGE_VIEW_ACCESS_TABLE: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TOTAL_PURGE_VIEW_ACCESS_TABLE, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },

    ACTIVE_DASH_BY_ROL: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.ACTIVE_DASH_BY_ROL, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },

    ACTIVE_DASH_PER_CLIENT: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.ACTIVE_DASH_PER_CLIENT, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },

    ROLE_MANAGEMENT_ACCESS: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.ROLE_MANAGEMENT_ACCESS, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    RESET_PASSWORD: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.RESET_PASSWORD, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    SEND_RESTORE_PASSWORD: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.SEND_RESTORE_PASSWORD, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    ARCHIVE_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.ARCHIVE_USER, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    LOT_RELATIONS: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.LOT_RELATIONS, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    ACCESS_LOT_CLIENT_EMPTY_FIELD: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.ACCESS_LOT_CLIENT_EMPTY_FIELD, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    TASK: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.TASK, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
    PERMISSIONS: {
        [modes.EDIT_PERMISSION]: [permissions.USER_MANAGEMENT.PERMISSIONS, permissions.USER_MANAGEMENT.FULL_ACCESS_ALL]
    },
}