import { ButtonBase, Grid, Paper } from "@material-ui/core";
import { workorderApprovalStyle } from "../style";
import ActivityListDialog from "features/landing/components/MechanicalDashboard/ActivityList/ActivityListDialog";
import { activityStatuses } from "features/activity/activityConsts";

const WorkorderButton = ({ onClick, name, color, active = true, count, activityListDialog = false }) => {
    const classes = workorderApprovalStyle();

    return <Grid item xs={6} className={classes.woBtn} >
        <ButtonBase onClick={onClick}>
            <Paper className={`${active ? classes.active : classes.notActive} ${classes.filterItem}`} style={{ borderTop: `7px solid ${color}`, opacity: `${active ? 1 : 0.4}` }}>
                {activityListDialog &&
                    <div className={classes.listIcon}>
                        <ActivityListDialog status={[activityStatuses.PendingClientApproval]} />
                    </div>}
                <Grid item className={`${classes.count}`} >{count}</Grid>
                <Grid container spacing={1} alignItems="center">
                    <Grid item className={classes.filterName}>{name}</Grid>
                </Grid>
            </Paper>
        </ButtonBase>
    </Grid>
}
export default WorkorderButton;