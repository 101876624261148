import { makeStyles } from "@material-ui/core";

export const AttachmentsStyle = makeStyles((theme) => ({
    title: {
        fontSize: '1.5em',
        cursor: 'pointer',
        fontWeight: 500,
        padding: '.3em'
    },
    commentIcon: {
        marginRight: '0.2em'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        padding: '.5em'
    },
    attachmentsTooltip: {
        opacity: 0.5,
        transition: 'opacity 0.2s ease',
        '&:hover': {
            opacity: 1
        },
    },
    attachmentsTooltipOn: {
        opacity: 1,
    },
    documentTypes: {
        marginBottom: '1em'
    }
}))