import { Grid, Paper } from "@material-ui/core";
import NotificationProvider from "components/NotificationProvider";
import { selectRole } from "features/user/userSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Authorize from "../../../components/Authorize";
import { hasPermission } from "../../../components/Authorize/authorizeUtil";
import { permissionProfiles } from "../../../components/Authorize/permissionProfiles";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { IconProfile } from "../../../icons";
import RoleManagement from "../components/RoleManagement";
import ActiveUsersGraphic from "./ActiveUsersGraphic";
import ActiveUsersPerClient from "./ActiveUsersPerClient";
import HeaderUserManagement from "./Header";
import InactiveUsers from "./InactiveUsers";
import TotalActiveUsers from "./TotalActiveUsers";
import UsersTable from "./UsersTable";
import { useGetAllPermissionQuery } from "./permissionSlice";
import { useDeleteRoleMutation, useGetAllRolesWithDataQuery } from "./roleSlice";
import usersPageStyle from './styles';
import { useGetAllClientsManagerQuery, useGetAllLotsManagerQuery, useGetUserManagerQuery } from "./userManagementSlice";

const UserManagementPage = () => {
    const classes = usersPageStyle();
    let { data: users, error: usersError, isLoading: isLoadingUsers, refetch: refetchUsers } = useGetUserManagerQuery();
    let {
        data: permission,
        error: permissionError,
        isLoading: isLoadingPermission,
        refetch: refetchPermission
    } = useGetAllPermissionQuery();
    let {
        data: rolesWithAllData,
        error: rolesWithAllDataError,
        isLoading: isLoadingRolesData,
        refetch: refetchRolesWithData
    } = useGetAllRolesWithDataQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetAllClientsManagerQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetAllLotsManagerQuery();

    const [deleteRole, { isLoading: isLoadingDeleteRole }] = useDeleteRoleMutation();

    const userLoggedProfileRoleName = useSelector(selectRole);

    const [localUsersFiltered, setLocalUsersFiltered] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);
    const [localClients, setLocalClients] = useState([]);
    const [localLots, setLocalLots] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const roles = rolesWithAllData?.data ?? [];

    useEffect(() => {
        if (users) {
            setLocalUsersFiltered(users);
            setActiveUsers(users?.filter(user => !user.isInactiveUser && !user.isPurged && !user.isBlackList));
        }
        if (clients) {
            setLocalClients(clients.data);
        }
        if (lots) {
            setLocalLots(lots.data);
        }
    }, [users, permission, clients, lots]);


    // useEffect(() => {
    //     if (roles && roles?.length > 0) {
    //         let myRole = roles?.find(x => x.name === userLoggedProfileRoleName);
    //         let usersFiltered = [];
    //         let allUsersList = users ?? [];

    //         allUsersList = allUsersList?.filter((user) => user?.assignedRole && user?.assignedRole?.length > 0);
    //         allUsersList?.map((user) => {
    //             const assigmentRoles = [...user?.assignedRole];
    //             let canISeeRole = myRole?.rolesKeysVisibles.some(y => y == assigmentRoles[0]?.roleId);
    //             if (canISeeRole) {
    //                 usersFiltered.push(user);
    //             }
    //         });
    //         setLocalUsersFiltered(allUsersList);
    //     }
    // }, [roles, users]);

    const mapExportData = (v) => ({
        Email: v.email,
        "User Name": v.userName,
        "Last Login Date": v.lastLoginDateFormat,
    });

    const isLoadingDashboardData = isLoadingUsers || isLoadingPermission || isLoadingRolesData || isLoadingClients || isLoadingLots || isLoading;

    const onDeleteRole = async (role) => {
        if (!role) return;

        let body = {
            RoleKey: role.key,
            RoleId: role.id
        };
        let res = await deleteRole(body);

        if (res && !res.error) {
            refetchRolesWithData();
            NotificationProvider.success("Role deleted successfully");
            return true;
        } else {
            NotificationProvider.error(`Failed to delete the role ${role.Name}`);
            return false;
        }
    }

    return (<>
        <Paper className={`${classes.card} ${classes.dashboard}`} elevation={1}>
            {isLoadingDashboardData && <LoadingSpinner loading={isLoadingDashboardData} />}
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <HeaderUserManagement roles={roles} users={localUsersFiltered}
                        clients={localClients}
                        lots={localLots}
                        refetchUsers={refetchUsers}
                        mapExportData={mapExportData} />
                </Grid>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_ACTIVE_USER_SEE}>
                    <Grid item xs={6}>
                        <Paper elevation={1} className={classes.card}
                            style={{ borderTop: `7px solid #d0e1fd` }}>
                            <Grid className={classes.wrapper} container direction="column">
                                <TotalActiveUsers
                                    users={activeUsers}
                                    clients={localClients}
                                    lots={localLots}
                                    mapExportData={mapExportData}
                                    permission={permission}
                                    refetchUsers={refetchUsers}
                                    roles={roles} />
                            </Grid>
                        </Paper>
                    </Grid>
                </Authorize>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_INACTIVE_USER_SEE}>
                    <Grid item xs={6}>
                        <Paper elevation={1} className={classes.card}
                            style={{ borderTop: `7px solid #d0e1fd` }}>
                            <Grid className={classes.wrapper} container direction="column">
                                <InactiveUsers
                                    users={localUsersFiltered}
                                    clients={localClients}
                                    lots={localLots}
                                    permission={permission}
                                    refetchUsers={refetchUsers}
                                    roles={roles}
                                    mapExportData={mapExportData} />
                            </Grid>
                        </Paper>
                    </Grid>
                </Authorize>

                {/* <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_BLACK_LIST}>
                    <Grid item xs={6}>
                        <Paper elevation={1} className={classes.card}
                            style={{ borderTop: `7px solid #d0e1fd` }}>
                            <Grid className={classes.wrapper} container direction="column">
                                <BlackListUsers users={localUsersFiltered}
                                    clients={localClients}
                                    lots={localLots}
                                    mapExportData={mapExportData}
                                    permission={permission}
                                    refetchUsers={refetchUsers}
                                    roles={roles} />
                            </Grid>
                        </Paper>
                    </Grid>
                </Authorize> */}

                {/* <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_PURGE}>
                    <Grid item xs={6}>
                        <Paper elevation={1} className={classes.card}
                            style={{ borderTop: `7px solid #d0e1fd` }}>
                            <Grid className={classes.wrapper} container direction="column">
                                <PurgeUsers users={localUsersFiltered}
                                    clients={localClients}
                                    lots={localLots}
                                    permission={permission}
                                    refetchUsers={refetchUsers}
                                    roles={roles}
                                    mapExportData={mapExportData} />
                            </Grid>
                        </Paper>
                    </Grid>
                </Authorize> */}

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.ACTIVE_DASH_BY_ROL}>
                    <Grid item xs={6}>
                        <Paper elevation={1} className={classes.card}>
                            <Grid className={classes.wrapper} container direction="column">
                                <ActiveUsersGraphic users={activeUsers} loading={isLoadingUsers}
                                    roles={roles} />
                            </Grid>
                        </Paper>
                    </Grid>
                </Authorize>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.ACTIVE_DASH_PER_CLIENT}>
                    <Grid item xs={6}>
                        <Paper elevation={1} className={classes.card}>
                            <Grid className={classes.wrapper} container direction="column">
                                <ActiveUsersPerClient users={activeUsers} clients={localClients} />
                            </Grid>
                        </Paper>
                    </Grid>
                </Authorize>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.ROLE_MANAGEMENT_ACCESS}>
                    <Grid item xs={12}>
                        <Paper elevation={1} className={classes.card}>
                            <Grid className={classes.wrapper} container direction="column">
                                <RoleManagement
                                    allRoles={roles}
                                    refetchRolesWithData={refetchRolesWithData}
                                    users={localUsersFiltered}
                                    title={"Role Management"}
                                    clients={localClients}
                                    lots={localLots}
                                    isLoading={isLoadingRolesData}
                                    isLoadingDeleteRole={isLoadingDeleteRole}
                                    onDeleteRoleFunc={onDeleteRole}
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                </Authorize>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_VIEW_ACCESS_TABLE}>
                    <Grid item xs={12}>
                        <Paper elevation={1} className={classes.card}>
                            <Grid className={classes.wrapper} container direction="column">
                                <UsersTable
                                    users={activeUsers}
                                    searchVal={""}
                                    clients={localClients}
                                    lots={localLots}
                                    roles={roles}
                                    permission={permission}
                                    refetchUsers={refetchUsers}
                                    title={<><IconProfile /> Active Users </>}
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                </Authorize>
            </Grid>
        </Paper>
    </>);
}
export default UserManagementPage;