import { Grid } from "@material-ui/core";

const { shimmerLoaderStyle } = require("./style");

const ShimmerLoader = ({ count = 1, height = '50' }) => {
    const classes = shimmerLoaderStyle();


    return (
        <div>
            {Array.from({ length: count }, (_, k) => (
                <div style={{ height: `${height}px` }} className={classes.shine}></div>))
            }
        </div>
    );
}
export default ShimmerLoader;