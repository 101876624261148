
import { Grid, Paper, Typography } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import LoadingSpinner from 'components/LoadingSpinner';
import { activityColors, activityStatusById, activityStatuses } from "features/activity/activityConsts";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import FormatService from "services/formatService";
import { WorkOrderCardStyle } from "./WorkOrderCardStyle";
import WorkOrderActions from "./WorkOrderActions";

const WorkOrderCard = ({ workOrder, isLoadingData, handleDone }) => {
    const classes = WorkOrderCardStyle();
    let { data: activityItems, error: activityItemsError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const activityType = activityItems?.find(item => item.id == workOrder?.activityItemId);
    const status = workOrder?.activityId;
    const stillPending = status == activityStatuses.PendingClientApproval;

    const isLoading = () => {
        return isLoadingData || isLoadingActivityItems;
    }

    return (
        <>

            <div className={classes.divWrap}>
                <LoadingSpinner loading={isLoading()} />
                {workOrder && <Paper className={classes.card} style={{ borderStyle: 'solid', borderColor: `${activityColors[status]}`, borderWidth: stillPending ? '0 0 0 7px' : '2px 2px 2px 7px' }}>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2} className={classes.container}>

                        <Grid item xs={8} className={classes.textWrapper}>
                            <div className={classes.wrapper}>
                                <Typography className={classes.lightText}>
                                    {workOrder?.clientName}
                                </Typography>

                                {stillPending && <Typography className={classes.lightText}>
                                    {FormatService.formatDateNoConversion(workOrder?.activityDate)}
                                </Typography>}
                            </div>

                            <Typography className={classes.regText}>
                                Type of activity
                            </Typography>
                            <Typography className={classes.boldText}>
                                {activityType?.name ?? 'Unknown'}
                            </Typography>
                            <Typography className={classes.regText}>
                                {`VIN: ${workOrder?.vehicle?.descriptor || '-'}`}
                            </Typography>
                            <Typography className={classes.regText}>
                                {`Asset ID: ${workOrder?.vehicle?.assetId || '-'}`}
                            </Typography>
                            <Typography className={classes.regText}>
                                <div className={classes.indicatorWrapper}>
                                    <div item xs={2}>
                                        <div className={classes.indicator} style={{ background: activityColors[status] }}></div>
                                    </div>
                                    {activityStatusById?.[status]?.name}
                                </div>
                            </Typography>
                            <Authorize profile={permissionProfiles.ACTIVITY.ACTIVITY_EDIT}>
                                <Typography className={classes.boldText}>
                                    Cost: ${workOrder?.price}
                                </Typography>
                            </Authorize>
                            <Typography className={classes.boldText}>
                                Price: ${workOrder?.revenue}
                            </Typography>
                        </Grid>

                        <WorkOrderActions workOrder={workOrder} handleDone={handleDone} stillPending={stillPending} />

                    </Grid>
                </Paper>}
            </div>
        </>
    )
}

export default WorkOrderCard;