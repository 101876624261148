import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, InputAdornment, Paper, TextField } from "@material-ui/core";
import { Alert, IconButton, Typography } from "@mui/material";
import { useUserResetPasswordMutation } from "../../../usersSlice";
import { validatePassword } from "../../validatePasswordUtil";
import { useParams } from "react-router-dom";
import NotificationProvider from "../../../../../components/NotificationProvider";
import userManager from "../../../../../app/userManager";
import { changePasswordPageStyle } from "./changePasswordPageStyle";
import { AppContext } from "../../../../../App";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconParqLight from "../../../../../icons/IconParqLight";

const ChangePasswordPage = () => {
    const [resetPassword] = useUserResetPasswordMutation();
    const classes = changePasswordPageStyle();
    const { dontShowHeaderApp, setDontShowHeaderApp } = useContext(AppContext);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [editingPassword, setEditingPassword] = useState(false);
    const [editingConfirm, setEditingConfirm] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const [validPassword, setValidPassword] = useState(false);
    const [validConfirm, setValidConfirm] = useState(false);
    const [validBoth, setValidBoth] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        setDontShowHeaderApp(true);
    }, []);

    const params = useParams();
    const { userId } = params;
    const onReset = async () => {
        setIsSaving(true);

        let objToSend = {
            userId: userId,
            password: confirmPassword,
            confirmPassword: confirmPassword
        };

        let result = await resetPassword(objToSend);

        if (result?.error) {
            console.log(result?.error);
            NotificationProvider.error("New password failed to save");
        } else {
            NotificationProvider.success("Password changed successfully.");
            userManager.signinRedirect()
                .then(user => console.log('signIn', user));
        }
        setIsSaving(false);
    };

    const onChangePasswords = (newPassword, newConfirmPassword) => {
        setEditingPassword(newPassword !== '');
        setEditingConfirm(newConfirmPassword !== '');

        const passwordToValidate = newPassword !== '' ? newPassword : password;
        const passwordConfirmToValidate = newConfirmPassword !== '' ? newConfirmPassword : confirmPassword;

        let validPasswords = validatePassword(
            passwordToValidate,
            passwordConfirmToValidate
        );

        setValidPassword(validPasswords.validPassword);
        setValidConfirm(validPasswords.validConfirm);
        setValidBoth(validPasswords.validBoth);

        setPassword(passwordToValidate);
        setConfirmPassword(passwordConfirmToValidate);

        if (validPasswords.validBoth) {
            setDisableSave(false);
        } else {
            setDisableSave(true);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    return (
        <>
            <Box sx={{ display: 'flex', height: '100vh' }}>
                {/* Left blue panel */}
                <Box sx={{
                    bgcolor: 'primary.main',
                    color: 'white',
                    p: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <IconParqLight />
                    <br />
                    <Typography variant="h5" gutterBottom>
                        Start your fleet management with us!
                    </Typography>
                    <Typography variant="body1">
                        Log in to gain secure access to powerful tools that will help you efficiently manage your fleet.
                    </Typography>
                </Box>

                {/* Right white panel */}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: "100%",
                    height: '100vh'
                }}>
                    <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                        <Paper elevation={3} style={{ padding: "20px", borderRadius: "10px" }}>
                            <Typography variant="h4" style={{ alignItems: "left" }} gutterBottom>
                                Welcome to ParQ
                            </Typography>

                            <Box component="form" sx={{
                                flex: 1, display: 'flex',
                                flexDirection: 'column', alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <TextField
                                    id="password"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    onChange={(e) => onChangePasswords(e.target.value, '')}
                                    required
                                    error={!validPassword && editingPassword}
                                    helperText={!validPassword && editingPassword &&
                                        "Password requires a minimum of 8 characters, upper and lower case letters, a number and a special character"}
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={toggleShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <TextField
                                    id="confirmpassword"
                                    label="Confirm Password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    fullWidth
                                    onChange={(e) => onChangePasswords('', e.target.value)}
                                    required
                                    error={!validConfirm && editingConfirm}
                                    helperText={!validConfirm && editingConfirm && "Passwords do not match"}
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={toggleShowConfirmPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Button
                                    onClick={onReset}
                                    disabled={isSaving || disableSave}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ marginTop: "20px" }}
                                >
                                    {isSaving ? "Saving..." : "Reset Password"}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default ChangePasswordPage;
