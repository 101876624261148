import { Button, Grid } from '@material-ui/core';
import Authorize from 'components/Authorize/Authorize.js';
import { permissionProfiles } from 'components/Authorize/permissionProfiles.js';
import DataListHeader from "components/DataListHeader";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import NotificationProvider from 'components/NotificationProvider.js';
import PageComponent from "components/Page/PageComponent";
import { useGetClientsWithConfigsQuery } from 'features/clients/clientConfigSlice';
import { useGetInvoiceCustomerQuery } from 'features/invoice/loads/loadInvoiceSlice.js';
import { useGetLotsQuery } from "features/lots/lotSlice";
import usePrompt from 'hooks/usePrompt.js';
import { orderBy, sortBy } from 'lodash';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { arrayToObject } from 'utils/object-util';
import BasicModal from "../../../../../../src/components/BasicModal";
import ExportButton from "../../../../../../src/components/ExportButton/ExportButton";
import SelectFieldsPopup from "../../../../../components/SelectFieldsPopup/selectFieldsPopup.js";
import { useGetSubMarketsQuery } from "../../../../lots/components/LotSubMarketsPage/lotSubMarketsSlice";
import { InvoiceTypes } from '../../../invoiceConsts.ts';
import BillingReportsDialog from '../../../reports/generateReports/BillingReportsDialog';
import InvoiceScreenSelector from '../../../shared/InvoiceScreenSelector';
import { PCOInvoiceColumns, StorageInvoiceStatus, StorageInvoiceStatuses, storageInvoiceTypes } from '../../storageInvoiceConsts.js';
import { setFilters, useApproveStorageInvoiceMutation, useGetInvoiceReportsMutation, useGetStorageInvoicesQuery } from '../../storageInvoiceSlice.ts';
import PCOInvoiceTable from '../PCOInvoice/PCOInvoiceTable';
import StorageInvoice from '../StorageInvoice/StorageInvoice';
import StorageInvoiceHeader from '../StorageInvoiceHeader/StorageInvoiceHeader';
import { WeeklyStorageInvoiceHeader } from '../StorageInvoiceHeader/WeeklyStorageInvoiceHeader';
import StorageInvoiceItemized from '../StorageInvoiceItemized/StorageInvoiceItemized';
import StorageInvoiceReGenerate from '../StorageInvoiceReGenerate';
import StorageInvoiceStatusDisplay from '../StorageInvoiceStatusDisplay/StorageInvoiceStatusDisplay.js';
import StorageInvoiceSummary from '../StorageInvoiceSummary/StorageInvoiceSummary';

const StorageInvoicePage = () => {
    const { triggerPrompt } = usePrompt();
    const { filters } = useSelector(state => state.storageInvoiceState);
    const [openBillingReports, setOpenBillingReports] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const params = new URLSearchParams(window.location.search);
    const clientIdParam = params.get('clientId');
    const dispatch = useDispatch();
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isLoading: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery();
    clientsWithConfigs = clientsWithConfigs || [];
    let clientsWithConfigsLookup = arrayToObject(clientsWithConfigs);

    const config = clientsWithConfigsLookup?.[filters?.['clientId']];
    const invoiceCycleType = config?.config?.lotConfig?.invoiceCycleType;

    const needsFiltering = !filters?.['clientId']
        || (!filters?.['month'] && invoiceCycleType == storageInvoiceTypes.MONTHLY)
        || (!filters?.['startDate'] && invoiceCycleType == storageInvoiceTypes.WEEKLY);


    let { data: invoices, error, isFetching } = useGetStorageInvoicesQuery({
        clientId: filters?.['clientId'],
        month: invoiceCycleType == storageInvoiceTypes.MONTHLY ? filters?.['month'] : null,
        year: invoiceCycleType == storageInvoiceTypes.MONTHLY ? filters?.['year'] : null,
        cycleStart: invoiceCycleType == storageInvoiceTypes.WEEKLY ? filters?.['startDate'] : null,
        cycleEnd: invoiceCycleType == storageInvoiceTypes.WEEKLY ? filters?.['endDate'] : null
    }, { skip: needsFiltering });

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: submarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();
    const [getInvoiceReports, { isLoading: removingLot }] = useGetInvoiceReportsMutation();
    const [approveInvoice, { isLoading: isApproving }] = useApproveStorageInvoiceMutation();

    let { data: customers, error: customerError, isLoading: isLoadingCustomer } = useGetInvoiceCustomerQuery({});
    const customersLookup = arrayToObject(customers);

    let [isDownloadingReports, setIsDownloadingReports] = useState(false);

    const lastInvoice = orderBy(invoices ?? [], ['year'], ['desc'])?.[0] ?? {};
    const invoiceDetails = lastInvoice?.storageInvoiceDetails?.map(det => {
        let resultInfo;
        let result = lots?.find(l => l.id === det?.lotId);

        if (typeof (result) === "undefined") {
            result = submarkets?.find(l => l.id === det?.subMarketId);
            resultInfo = [result?.name];
        }
        else {
            resultInfo = [result?.name, result?.addressLine1, result?.city, result?.state, result?.zipCode]?.join(', ');
        }

        return ({
            ...det,
            lot: result,
            lotInfo: resultInfo,
        });
    });

    const invoiceData = {
        ...lastInvoice,
        details: sortBy(invoiceDetails, [d => !!d.subMarketId, d => d.lotInfo])
    };

    useEffect(() => {
        if (clientIdParam && filters?.clientId == '') {
            dispatch(setFilters({ ...filters, ...{ clientId: clientIdParam } }));
        }
    }, [clientIdParam]);

    let reportColumns = [
        { name: "Invoice Report", key: "1", selectedByDefault: true, width: 280 },
        { name: "Lot/Day Matrix PMF Days", key: "2", selectedByDefault: true, width: 200 },
        { name: "VIN Report PMF Days", key: "3", selectedByDefault: true },
        { name: "Lot/Day Matrix Day Count", key: "4", selectedByDefault: true, width: 200 },
        { name: "VIN Report Day Count", key: "5", selectedByDefault: true, width: 200 }
    ];

    const [selectedColumns, setSelectedColumns] = useState([...reportColumns]?.filter(c => c.selectedByDefault));

    const [openSelectReportsPopup, setOpenSelectReportsPopup] = useState(false);

    const isChecked = (checked, columnName) => {
        return [...checked].filter(column => column ? column.name === columnName : false).length > 0;
    };

    const onSubmitSelectedFields = async (checked) => {
        setSelectedColumns(
            reportColumns.map((column) => (isChecked(checked, column.name) ? column : null))
                .filter(column => column)
        );

        let reportsToSend = [];
        reportColumns.map((column) => (isChecked(checked, column.name) ? column : null)).forEach((id) => {
            if (id !== null) {
                reportsToSend.push(parseInt(id.key));
            }
        });

        let reportsToSendObj = {
            reportTypes: reportsToSend,
            clientId: parseInt(filters?.clientId),
            month: invoiceCycleType == storageInvoiceTypes.MONTHLY ? parseInt(filters?.['month']) : null,
            year: invoiceCycleType == storageInvoiceTypes.MONTHLY ? parseInt(filters?.['year']) : null,
            cycleStart: invoiceCycleType == storageInvoiceTypes.WEEKLY ? filters?.['startDate'] : null,
            cycleEnd: invoiceCycleType == storageInvoiceTypes.WEEKLY ? filters?.['endDate'] : null,
            storageInvoiceId: lastInvoice?.id,
            invoiceCycleType: invoiceCycleType
        };

        setOpenSelectReportsPopup(false);

        setIsDownloadingReports(true);
        await getInvoiceReports(reportsToSendObj);
        setIsDownloadingReports(false);
    };

    const onSelectExistingClick = () => {
        setOpenSelectReportsPopup(true);
        setIsDownloadingReports(true);
    };

    const onCloseModal = () => {
        setOpenSelectReportsPopup(false);
        setIsDownloadingReports(false);
    };


    const onApproveInvoice = async () => {
        triggerPrompt({
            title: "Approve Invoice",
            content: <div>
                The approved invoice will be sent to QuickBooks in a few hours.
                <br />Are you sure you want to continue?
            </div>,
            onConfirm: async () => {
                const res = await approveInvoice(invoiceData?.id);
                if (!res?.error) {
                    NotificationProvider.success('Invoice approved successfully');
                } else {
                    NotificationProvider.error('Failed to approve invoice');
                }
            },
            onCancel: async () => {
            },
        });
    };


    const mapExportData = row => {
        const invoiceData = {};
        let invoice = ({
            ...row,
            ...row?.storageInvoiceVehicles?.[0],
            lotName: row?.lotStorageInvoiceDetails?.[0]?.name,
            numberOfLines: row?.storageInvoiceActivities?.length + 1,
            totalCharge: row?.storageInvoiceDetails?.[0]?.totalFee,
            customerName: customersLookup?.[row.qbCustomerId]?.fullyQualifiedName,
            statusId: row.status,
            statusName: StorageInvoiceStatuses[row.status].name,
            storageInvoiceId: row?.storageInvoiceVehicles?.[0]?.storageInvoiceId ?? row.id
        });
        PCOInvoiceColumns?.map(col => {
            if (col.name !== "Notes") {
                invoiceData[col.name] = invoice[col.exportKey ?? col.key];
            }
        });

        return invoiceData;
    };


    const invoicesToExport = () => {
        let invoiceData = Array.isArray(invoices) ? [...invoices] : [];
        let invoicesToExport = selectedTab === 0
            ? invoiceData.filter(i =>
                i.status !== StorageInvoiceStatus.CANCELED &&
                i.status !== StorageInvoiceStatus.SENT_TO_QB &&
                i.status !== StorageInvoiceStatus.APPROVED
            )
            : invoiceData.filter(i =>
                i.status === StorageInvoiceStatus.CANCELED ||
                i.status === StorageInvoiceStatus.SENT_TO_QB ||
                i.status === StorageInvoiceStatus.APPROVED
            );
        return orderBy(invoicesToExport, 'cycleEnd', 'desc');
    };

    return (
        <PageComponent
            loading={isFetching}
            header={
                <DataListHeader
                    titleSize={5}
                    titleComponent={(
                        <InvoiceScreenSelector
                            selected={InvoiceTypes.STORAGE}
                        />
                    )}
                    actionButtons={
                        <Grid container alignItems='center' spacing={1}>
                            {invoiceCycleType !== storageInvoiceTypes.PER_CHECKOUT ? <>
                                <StorageInvoiceStatusDisplay invoice={invoiceData} />
                                {
                                    <>
                                        {invoiceData?.status === StorageInvoiceStatus.PENDING && <Authorize profile={permissionProfiles.INVOICE.INVOICES_MANAGE}>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isApproving}
                                                    onClick={onApproveInvoice}>
                                                    Approve Invoice
                                                </Button>
                                            </Grid>
                                        </Authorize>}
                                        <StorageInvoiceReGenerate
                                            invoiceCycleType={invoiceCycleType}
                                            invoiceData={invoiceData}
                                        />
                                        {/* {invoiceCycleType == storageInvoiceTypes.MONTHLY && !!invoiceData &&  */}
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onSelectExistingClick}
                                                disabled={isDownloadingReports || needsFiltering}>
                                                Export
                                            </Button>
                                        </Grid>
                                        {/* } */}
                                        {invoiceCycleType == storageInvoiceTypes.WEEKLY && <Authorize profile={permissionProfiles.REPORTS.VIEW_BILLING_REPORTS}>
                                            <Grid item>
                                                <Button variant='contained' color="primary" onClick={() => setOpenBillingReports(true)}>
                                                    Generate Reports
                                                </Button>
                                            </Grid>
                                        </Authorize>}
                                    </>
                                }</> :
                                <>
                                    {filters?.['clientId'] &&
                                        <Authorize profile={permissionProfiles.REPORTS.VIEW_BILLING_REPORTS}>
                                            <Grid item>
                                                <Button variant='contained' color="primary" onClick={() => setOpenBillingReports(true)}>
                                                    Generate Reports
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <ExportButton fileLabel="Export" data={invoicesToExport()} className='' mapExportData={mapExportData} />
                                            </Grid>
                                        </Authorize>

                                    }
                                </>}
                        </Grid>}
                />}>
            {invoiceCycleType == storageInvoiceTypes.WEEKLY ?
                <WeeklyStorageInvoiceHeader invoiceCycleType={invoiceCycleType} /> :
                <StorageInvoiceHeader invoiceCycleType={invoiceCycleType} />
            }

            <BasicModal
                open={openSelectReportsPopup}
                header={`Select Reports`}
                handleClose={() => onCloseModal(false)}
                component={<SelectFieldsPopup columns={reportColumns}
                    selectedColumns={selectedColumns}
                    isChecked={isChecked}
                    onSubmit={onSubmitSelectedFields}
                    handleClose={() => onCloseModal(false)} />}
            />
            {needsFiltering ?
                <EmptyPlaceholder text={'Please select client and month'} /> :
                !!invoiceData ?
                    <>
                        {invoiceCycleType == storageInvoiceTypes.PER_CHECKOUT &&
                            <div style={{ marginTop: '1em' }}>
                                <PCOInvoiceTable invoices={invoices} customersLookup={customersLookup} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                            </div>}
                        {invoiceCycleType == storageInvoiceTypes.MONTHLY && <>
                            <Grid direction='column' spacing={1}>
                                {invoiceData?.details?.map(invoice => <Grid item key={invoice.id}>
                                    <StorageInvoice invoice={invoice} /></Grid>)}
                            </Grid>
                            <StorageInvoiceSummary invoice={invoiceData} />
                        </>}
                        {invoiceCycleType == storageInvoiceTypes.WEEKLY && <>
                            <Grid direction='column' spacing={1}>
                                {invoiceData?.details?.map(invoice => <Grid item key={invoice.id}>
                                    <StorageInvoiceItemized invoice={invoice} /></Grid>)}
                            </Grid>
                        </>}
                    </> :
                    <EmptyPlaceholder text={'No Invoices'} />}
            {openBillingReports && <BillingReportsDialog open={openBillingReports} onClose={() => setOpenBillingReports(false)} clientId={filters?.['clientId']} />}
        </PageComponent>
    );
};

export default StorageInvoicePage;
