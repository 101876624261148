import { Grid } from "@material-ui/core";
import BasicModal from "components/BasicModal";
import NotificationProvider from "components/NotificationProvider";
import FadeInImage from "features/vehicles/components/FadeInImage";
import Carrousel from "features/vehicles/components/FadeInImage/Carrousel";
import { useDeleteDocumentMutation, useUpdateVehicleDocumentTypeMutation } from "features/vehicles/vehicleAttachmentsSlice";
import { IconBill } from "icons";
import { useState } from "react";
import FormatService from "services/formatService";
import { VehicleDocumentsStyle } from "./style";

const VehicleDocuments = ({ vehicleDetails, documentTypes }) => {
    const classes = VehicleDocumentsStyle();

    let [deleteDocument] = useDeleteDocumentMutation();
    let [updateDocType] = useUpdateVehicleDocumentTypeMutation();

    const [openCarousel, setOpenCarousel] = useState(false);

    const onDeleteConfirm = async (doc) => {
        const docToDelete = { ...doc, createdDate: new Date(doc.createdDate) }
        const res = await deleteDocument(docToDelete);
        if (res.error) {
            NotificationProvider.error("Failed to delete the document");
        } else {
            NotificationProvider.success("Successfully deleted the document");
        }
    }

    const onEditConfirm = async (data) => {
        const result = await updateDocType(data);
        if (result.error) {
            NotificationProvider.error("Failed to update the document type");
        } else {
            NotificationProvider.success("Successfully updated the document type");
        }
    }

    let vehicleDocuments = vehicleDetails?.documents;

    vehicleDocuments = vehicleDocuments?.map(a => ({
        ...a,
        createdDate: FormatService.formatDate(a.createdDate)
    }))

    const docsForCarousel = {};

    vehicleDocuments?.forEach(img => {
        if (!docsForCarousel[img.createdDate]) docsForCarousel[img.createdDate] = [];
        docsForCarousel[img.createdDate].push(img);
    });

    
    return (
        <>
            <div className={classes.title}><IconBill /> Documents</div>
                {!!vehicleDocuments?.length &&
                    <Grid container>
                        {<Grid container direction="column">
                            <Grid container>
                                {vehicleDocuments?.map((doc, i) =>
                                    <Grid item key={i}>
                                        <FadeInImage
                                            img={doc}
                                            alt={`Vehicle Document ${i}`}
                                            onDelete={onDeleteConfirm}
                                            onEdit={onEditConfirm}
                                            documentTypes={documentTypes}
                                            showCarousel={true}
                                            imagesForCarousel={docsForCarousel}
                                            isDocument={true}
                                            // noteType={NoteEntityTypes.VEHICLE_IMAGE}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>}
                    </Grid>
                }
            <BasicModal
                open={openCarousel}
                header={'Documents'}
                wide={true}
                handleClose={() => setOpenCarousel(false)}
                component={<Carrousel
                    images={vehicleDocuments}                    
                />}
            />
        </>
    )
}

export default VehicleDocuments;