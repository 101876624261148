import { makeStyles } from "@material-ui/core";

export const totalAssetWidgetStyle = makeStyles((theme) => ({
    tooltip: {
        padding: '0.5em 1em',
        background: '#fff',
        borderRadius: '5px',
        boxShadow: '2px 3px 10px #d7d6d6',
    },
    graphWrapper: {
        height: '100%',
        minHeight: '20rem'
    },
    label: {
        backgroundColor: 'white'
    },
    filterIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));