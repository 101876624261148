import { baseApi } from "app/baseApi";
import { baseQuery } from "app/baseQuery";
import { apiTags } from "app/store/apiTags";

const apiPrefix = 'attachment';

const getCacheKey = (args) => {
    return `${args.entityType}_${args.entityId}`
}

/**
 * This is used to refresh the attachments api for a specific entity
 * Provide the entity id and type (based on the AttachmentEntityType const) and get the cache key that needs to be invalidated using the invalidateTags
 * @param {*} entityId 
 * @param {*} entityType 
 * @returns 
 */
export const getAttachmentsInvalidateTag = (entityId, entityType) => {
    return { type: apiTags.ATTACHMENTS, id: getCacheKey({ entityId, entityType }) };
}

export const attachmentsApi = baseApi.injectEndpoints({
    reducerPath: 'attachmentsApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.ATTACHMENTS],
    endpoints: (builder) => ({
        getEntityAttachments: builder.query({
            query: ({ entityId, entityType }) => `${apiPrefix}?entityId=${entityId}&entityType=${entityType}`,
            providesTags: (result, error, arg) => [{ type: apiTags.ATTACHMENTS, id: getCacheKey(arg) }],
        }),
        addAttachment: builder.mutation({
            query: attachment => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: attachment
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.ATTACHMENTS, id: getCacheKey(arg) },
            ],
        }),
        deleteAttachment: builder.mutation({
            query: (attachment) => ({
                url: `${apiPrefix}/delete`,
                method: "POST",
                body: { id: attachment.id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.ATTACHMENTS, id: getCacheKey(arg) },
            ]
        }),
        updateDocumentType: builder.mutation({
            query: (attachment) => ({
                url: `${apiPrefix}/documentType`,
                method: 'POST',
                body: attachment
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.ATTACHMENTS, id: getCacheKey(arg) },               
            ]
        })
    })
});

export const { useGetEntityAttachmentsQuery, useAddAttachmentMutation, useDeleteAttachmentMutation, useUpdateDocumentTypeMutation } = attachmentsApi;