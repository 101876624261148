import { makeStyles } from "@material-ui/core";

export const assetLocationWidgetStyle = makeStyles((theme) => ({
    tooltip: {
        padding: '0.5em 1em',
        background: '#fff',
        borderRadius: '5px',
        boxShadow: '2px 3px 10px #d7d6d6',
    },
    graphTootltip: {
        background: theme.palette.background.paper,
        padding: '0.1px 0.6em',
        boxShadow: theme.shadows[3],
        maxWidth: '9rem'
    },
    lotAddress: {
        fontSize: 10
    },
    lotContainer: {
        paddingLeft: '1em'
    },
    filterIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));