import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from "react-window";
import WorkOrderCard from "../../WorkorderDashboard/WorkOrderCard/WorkOrderCard";
import { activityTableStyle } from './style';

const ActivityListConfirm = ({ activities }) => {
    const classes = activityTableStyle()

    const Row = ({ index, style }) => (
        <div style={style}>
            <WorkOrderCard
                workOrder={activities?.[index]}
                handleDone={() => { }}
                key={index}
            />
        </div>
    );

    return (
        <>
            {!!activities?.length ?
                <div style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className="List"
                                height={height}
                                itemCount={activities?.length}
                                itemSize={215}
                                width={width}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </div>
                : <EmptyPlaceholder text='There are no activities matching this criteria' />
            }
        </>
    )
}

export default ActivityListConfirm;