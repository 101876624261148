import { Grid } from "@material-ui/core";
import WidgetTitle from "../WidgetTitle/WidgetTitle";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { useGetVehiclesQuery } from "features/vehicles/vehicleSlice";
import { groupBy } from "lodash";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { arrayToObject } from "utils/object-util";
import { theme } from "styles/theme";
import { assetLocationWidgetStyle } from "./style";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { useState } from "react";
import DropdownFilter from "components/DropdownFilter";
import { useGetStatusesForClientQuery } from "features/vehicles/VehicleStatus/vehicleStatusSlice";
import LotsDropdown from "components/SharedDropdowns/LotsDropDown";
import FilterService from "services/filterService";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import { IconCar, IconLot, IconPmfLogo, IconVehicle } from "icons";

const AssetLocationWidget = () => {
    const classes = assetLocationWidgetStyle();

    let { data: vehiclesData, error: vehicleError, isLoading: isLoadingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory: false });
    let { data: lots, error: lotError, isLoading: isLoadingLots, refetch: refetchLots } = useGetLotsQuery();
    let { data: clientVehicleStatuses, error: clientVehicleStatusesError, loading: clientVehicleStatusesLoading } = useGetStatusesForClientQuery({ clientId: 0 })

    vehiclesData = vehiclesData || [];
    lots = lots || [];
    clientVehicleStatuses = clientVehicleStatuses?.filter(s => s.onLot) || [];

    const { dashboardFilters } = useSelector(state => state.fleetManagementDashboardState);

    const loading = isLoadingVehicles || isLoadingLots || clientVehicleStatusesLoading;

    const [pmfLotFilter, setPmfLotFilter] = useState(1);

    const [filters, setFilters] = useState({
        'lotId': { value: [] },
        'vehicleStatusId': { value: [] }
    })

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    const filteredData = FilterService.filter(filters, null, vehiclesData, [])?.filter(f => (!dashboardFilters?.clientId || dashboardFilters?.clientId == f?.clientId));;

    const groupedByLots = groupBy(filteredData, 'lotId');
    const lotsLookup = arrayToObject(lots);

    const finalData = sortBy(Object.keys(groupedByLots).map(key => ({
        lotId: +key,
        lotName: lotsLookup?.[key]?.name,
        count: groupedByLots[key]?.length,
        pmfLot: lotsLookup?.[key]?.pmfLot ? 1 : 2
    }
    ))?.filter(item => pmfLotFilter ? item?.pmfLot == pmfLotFilter : true), [d => d.lotName]);

    const CustomTooltip = ({ payload }) => {
        return (
            <div className={classes.graphTootltip}>
                <p>
                    <div style={{ fontSize: 11 }}>Lot:</div>
                    <div style={{ fontSize: 10 }}><b>{`${lotsLookup?.[payload?.[0]?.payload.lotId]?.name} ${lotsLookup?.[payload?.[0]?.payload.lotId]?.addressLine1}`}</b></div>
                    <div style={{ color: theme.palette.primary.main }}><b>{payload?.[0]?.payload?.count} assets</b></div>
                </p>
            </div>
        );
    };

    return <Grid container direction="column">
        <WidgetTitle
            title='Assets Locations'
            info={`This shows how many of your Assets are on each of the Lots now. 
                    You can filter by Lot and Status to see what is the disposition of your fleet at each location.`}
            loading={loading}
            filtersList={{ ...filters, 'pmfLotFilter': { value: pmfLotFilter } }}
            filters={
                <Grid container spacing={1}>
                    <Grid className={classes.filterIcon} item xs={2}>
                        <IconPmfLogo height={17} fill="#0090fe"/>
                    </Grid>
                    <Grid item xs={10}>
                        <DropdownFilter
                            identifier="pmfLot"
                            value={pmfLotFilter}
                            onSelect={(val) => setPmfLotFilter(val)}
                            values={[
                                { id: 1, name: 'PMF Lots' },
                                { id: 2, name: 'Non PMF Lots' },
                            ]}
                            showEmpty={true}
                        />
                    </Grid>
                    <Grid className={classes.filterIcon} item xs={2}>
                        <IconLot />
                    </Grid>
                    <Grid item xs={10}>
                        <LotsDropdown
                            title='Lot'
                            identifier='lot'
                            values={lots}
                            onSelect={(val) => onDropFilter(val, 'lotId')}
                            value={filters?.['lotId']?.value}
                            showEmpty={true}
                            multiple={true}
                            pmfLot={pmfLotFilter == 1}
                        />
                    </Grid>
                    <Grid className={classes.filterIcon} item xs={2}>
                        <IconCar width={20}/>
                    </Grid>
                    <Grid item xs={10}>
                        <DropdownFilter
                            title="Status"
                            identifier="status"
                            value={filters?.['vehicleStatusId']?.value}
                            onSelect={(val) => onDropFilter(val, 'vehicleStatusId')}
                            values={clientVehicleStatuses ?? []}
                            showEmpty={true}
                            multiple={true}
                        />
                    </Grid>
                </Grid>
            } />
        <Grid item style={{ height: 400, paddingRight: '0.5rem' }}>
            {!!finalData?.length ?
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={finalData}
                        margin={{ top: 30, right: 50, left: 10, bottom: 5 }}
                    >
                        <XAxis
                            type="category"
                            axisLine={false}
                            interval={~~(finalData?.length / 40)}
                            dataKey="lotName"
                            tickLine={false}
                            tick={{
                                color: theme.palette.primary.main,
                                fontSize: '0.8em',
                                fontWeight: '500',
                                dx: -4,
                            }}
                            angle={-90} // Adjust angle to rotate labels
                            textAnchor="end" // Aligns the text properly when rotated
                            height={50}
                        />
                        <YAxis
                            type="number"
                            dataKey="count"
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={(value) => Math.round(value) == value ? value : ''}
                        />
                        <Tooltip
                            content={<CustomTooltip />}
                            cursor={{ fill: '#F3F5F6' }}
                        />
                        <Bar
                            dataKey="count"
                            fill={theme.palette.primary.main}
                            maxBarSize={8}
                        >
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
                : <EmptyPlaceholder text='No Assets' />}
        </Grid>
    </Grid>
}
export default AssetLocationWidget;