export const reservationsStatuses = {
    New: 1,
    WaitingDocuments: 2,
    DocumentsReceived: 3,
    VehiclesPickedUp: 4,
    Returned: 5,
    Billing: 6,
    Cancelled: 7,
}

export const reservationStatusList = [
    {
        id: reservationsStatuses.New,
        name: 'New'
    },
    {
        id: reservationsStatuses.WaitingDocuments,
        name: 'Awaiting Documents'
    },
    {
        id: reservationsStatuses.DocumentsReceived,
        name: 'Documents Received'
    },
    {
        id: reservationsStatuses.VehiclesPickedUp,
        name: 'Vehicles Picked Up'
    },
    {
        id: reservationsStatuses.Returned,
        name: 'Returned'
    },
    {
        id: reservationsStatuses.Billing,
        name: 'Billing'
    },
    {
        id: reservationsStatuses.Cancelled,
        name: 'Cancelled'
    },
]

export const reservationsPickupType = {
    TransportToDSP: 1,
    PickUpAtPMFLocation: 2
}

export const reservationPickupTypeList = [
    {
        id: reservationsPickupType.TransportToDSP,
        name: 'Transport to DSP'
    },
    {
        id: reservationsPickupType.PickUpAtPMFLocation,
        name: 'Pick Up at PMF location'
    }
];

export const paveCheckinStatus = {
    Pending: 1,
    InProgress: 2,
    Completed: 3
}

export const paveCheckinStatusLabel = {
    [paveCheckinStatus.Pending]: 'Pending',
    [paveCheckinStatus.InProgress]: 'In progress',
    [paveCheckinStatus.Completed]: 'Completed',
}

export const documentTypeEnum = {
    PAVEConditionReport: 1,
    OtherConditionReport: 2,
    BOL: 3,
    COI: 4,
    ACH: 5,
    RentalAgreement: 6,
    BillofSale: 7,
    RepairEstimate: 8,
    RepairInvoice: 9
} 

export const reservationDocumentTypes = {
    COI: 0,
    ACHAuthorizationForm: 1,
    RentalAgreement: 2
}

export const reservationDocumentTypesList = [
    {
        id: reservationDocumentTypes.COI,
        name: 'COI',
        documentType: documentTypeEnum.COI
    },
    {
        id: reservationDocumentTypes.ACHAuthorizationForm,
        name: 'ACH Authorization Form',
        documentType: documentTypeEnum.ACH
    },
    {
        id: reservationDocumentTypes.RentalAgreement,
        name: 'Rental Agreement',
        documentType: documentTypeEnum.RentalAgreement
    }
]