import { Chip, CircularProgress, Grid, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import RoleList from "./RoleList";
import RoleManagementHeader from "./RoleManagementHeader";
import UserPerRoleDialog from "./UserPerRoleDialog";
import roleManagementStyles from "./roleManagementStyles";
import RoleConfigurationDialog from "./RoleConfigurationDialog";
import ConfirmationPopup from "components/ConfirmationPopup";
import { useUpdateRolesMutation } from "../../UserManagement/roleSlice";
import NotificationProvider from "../../../../components/NotificationProvider";

const useStyles = roleManagementStyles;

const RoleManagement = ({
    allRoles = [],
    refetchRolesWithData,
    users,
    title,
    clients,
    lots,
    isLoading,
    isLoadingDeleteRole,
    onDeleteRoleFunc
}) => {

    const classes = useStyles();
    const [updateRoles, { isUpdating }] = useUpdateRolesMutation();

    const [roles, setRoles] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [deletingRole, setDeletingRole] = useState(null);
    const [showLoader, setShowLoader] = useState(false);

    const usersAssignedText = "Role has users assigned";
    const deleteRoleText = "Delete Role";

    useEffect(() => {
        if (!allRoles?.length) {
            return;
        }

        if (!searchQuery || searchQuery === "") {
            setRoles(allRoles);
        } else {
            const rolesFiltered = allRoles.filter((rol) => rol?.name?.toLowerCase()?.includes(searchQuery));
            setRoles(rolesFiltered);
        }
    }, [allRoles, searchQuery]);

    useEffect(() => {
        if (!users) return;
        setAllUsers(users);
    }, [users]);


    const onChangePermission = async (role, p_category, p_permission, p_val, attribute) => {
        const objectToArray = async (object) => Object.values(object);

        if (!role) return;

        const rolIndex = [...roles]?.findIndex((rl) => rl?.id == role?.id);
        const catIndex = role?.categories.findIndex((cat) => cat.id == p_category?.id);
        const perIndex = role?.categories[catIndex]?.permissions.findIndex(per => per.id == p_permission?.id);

        const permissionFound = { ...role.categories[catIndex]?.permissions[perIndex] };
        permissionFound[attribute] = p_val;
        const permissionsUpdated = ({ ...role?.categories[catIndex]?.permissions, [perIndex]: permissionFound });
        const permissionArray = await objectToArray(permissionsUpdated);


        const categoryUpdated = ({ ...role?.categories[catIndex], ["permissions"]: permissionArray });
        const categoriesUpdated = ({ ...role?.categories, [catIndex]: categoryUpdated });
        const categoriesArray = await objectToArray(categoriesUpdated);

        const roleUpdated = ({ ...role, ["categories"]: categoriesArray });

        let result = ({ ...roles, [rolIndex]: roleUpdated });
        result = await objectToArray(result);
        setRoles(result);
    }

    const onChangeAllPermissions = async (role, category, val) => {
        const objectToArray = (object) => Object.values(object);

        if (!role) return;

        const rolIndex = [...roles]?.findIndex((rl) => rl?.id == role?.id);
        const catIndex = role?.categories.findIndex((cat) => cat.id == category?.id);
        const permissions = [...role.categories[catIndex]?.permissions];

        let permissionsUpdated = permissions?.map((permission) => ({
            ...permission,
            isActiveInMapping: val,
            isVisibleMapping: val
        }));
        const categoryUpdated = ({ ...role?.categories[catIndex], ["permissions"]: permissionsUpdated });
        const categoriesUpdated = ({ ...role?.categories, [catIndex]: categoryUpdated });
        const categoriesArray = objectToArray(categoriesUpdated);

        const roleUpdated = ({ ...role, ["categories"]: categoriesArray });

        let result = ({ ...roles, [rolIndex]: roleUpdated });
        result = objectToArray(result);
        setRoles(result);
    }

    const onDeleteRole = async (role) => {

        if (!role) return;
        setDeletingRole(role);
    }

    const onConfirmedDeleteRole = async (role) => {

        if (!role) return;
        let result = await onDeleteRoleFunc(role);
        if (result) {
            let rolesUpdated = [...roles]?.filter((rol) => rol.id != role?.id);
            setRoles(rolesUpdated);
        }
        setDeletingRole(null)
    }

    const onCancel = async () => {
        if (!allRoles) return;
        setRoles(allRoles ?? []);
    }

    const onSaveFunc = async (p_roles, p_ori_roles) => {
        setShowLoader(true);
        let body = {
            NewRoles: p_roles,
            OriginalRoles: p_ori_roles
        };
        let res = await updateRoles(body);


        if (res && !res.error) {
            refetchRolesWithData();
            NotificationProvider.success("Roles updated successfully");
        } else {
            NotificationProvider.error("Failed to update roles");
        }
        setShowLoader(false);
    }

    const onSave = async (role) => {
        await onSaveFunc([role], allRoles);
    }

    const getActionText = (hasUsersAssigned) => (!hasUsersAssigned) ? deleteRoleText : usersAssignedText;
    const renderChips = (role) => role?.isRoleForClient && <Chip size="small" color="primary" label="For Clients" />

    const renderCategoryActions = (role) => {
        const usersFiltered = allUsers?.filter((user) => user.assignedRole.some((rolAssigned) => rolAssigned?.roleId == role?.key));
        const hasUsersAssigned = usersFiltered && usersFiltered?.length > 0;

        if (hasUsersAssigned) {
            return <>
                {renderChips(role)}
                {/* <RoleConfigurationDialog role={role} allRoles={roles} refetchRolesWithData={refetchRolesWithData} /> */}
                <Tooltip title="Show Users">
                    <UserPerRoleDialog clients={[...clients]} lots={[...lots]} permission={[]}
                        refetchUsers={refetchRolesWithData} roles={[role]} user={usersFiltered} />
                </Tooltip>
            </>
        }

        return <>
            {/* {renderChips(role)} */}
            {/* <RoleConfigurationDialog role={role} allRoles={roles} refetchRolesWithData={refetchRolesWithData} /> */}
            {/* <Tooltip title={getActionText(hasUsersAssigned)}>
                <IconButton onClick={() => onDeleteRole(role)} style={{ position: 'relative' }}>
                    <DeleteIcon
                        color="error"
                        size="small"
                    />
                </IconButton>
            </Tooltip> */}
        </>
    }

    return (<>
        <Grid justifyContent="space-between" className={classes.container}>
            <RoleManagementHeader
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                title={title}
                refetchRolesWithData={refetchRolesWithData}
                roles={roles}
            />
            {(showLoader || isLoading) ?
                <div className={classes.loaderContainer}>
                    <CircularProgress size={'3em'} />
                </div>
                :
                <RoleList
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onChangeAllPermissions={onChangeAllPermissions}
                    onChangePermission={onChangePermission}
                    onDeleteRole={onDeleteRole}
                    onSave={onSave}
                    renderCategoryActions={renderCategoryActions}
                    roles={roles}
                />
            }
            <ConfirmationPopup
                onCancel={() => setDeletingRole(null)}
                onConfirm={() => onConfirmedDeleteRole(deletingRole)}
                open={deletingRole !== null}
                loading={isLoadingDeleteRole}
            >
                {deletingRole ? `Are you sure you want to delete the "${deletingRole?.name}" role?` : null}
            </ConfirmationPopup>
        </Grid>
    </>);
}
export default RoleManagement;