import { useGetMechanicalActivitiesQuery } from "features/activity/activitySlice";
import WidgetTitle from "../WidgetTitle/WidgetTitle";
import { activityStatuses } from "features/activity/activityConsts";
import { Grid } from "@material-ui/core";
import LotsDropdown from "components/SharedDropdowns/LotsDropDown";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useMemo, useState } from "react";
import { GraphLoader } from "../../WorkorderDashboard/GraphLoader";
import WorkorderButtons from "./WorkorderList/WorkorderButtons";
import { workorderApprovalStyle } from "./style";
import WorkorderList from "./WorkorderList/WorkorderList";
import _ from "lodash";
import { arrayToObject } from "utils/object-util";
import { useGetClientsQuery } from "features/clients/clientSlice";
import FilterService from "services/filterService";
import { useSelector } from "react-redux";
import { IconLot } from "icons";

const WorkorderApprovalWidget = () => {
    const classes = workorderApprovalStyle();

    let { data: mechanicalActivities, error: mechanicalActivitiesError, refetch, isFetching: isLoadingMechanicalActivities } = useGetMechanicalActivitiesQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots, refetch: refetchLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    lots = lots || [];
    const clientAccounts = _.flatten(clients?.map(c => c.clientAccounts));
    const { dashboardFilters } = useSelector(state => state.fleetManagementDashboardState);

    const [isPendingApproval, setIsPendingApproval] = useState(false);

    const [filters, setFilters] = useState({
        'lotId': { value: [] },
    })

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    const clientLookup = useMemo(() => arrayToObject(clients), [clients]);
    const clientAccountsLookup = useMemo(() => arrayToObject(clientAccounts), [clientAccounts]);


    const mappedData = useMemo(() => mechanicalActivities?.map(activity => {
        var client = clientLookup?.[activity.clientId];
        var clientAccount = clientAccountsLookup[activity.clientAccountId];
        return {
            ...activity,
            clientName: client?.name + (client?.name != clientAccount?.name ? ": " + clientAccount?.name : ""),
        }
    }), [mechanicalActivities]);

    const filteredData = FilterService.filter(filters, null, mappedData, [])?.filter(f => (!dashboardFilters?.clientId || dashboardFilters?.clientId == f?.clientId));

    const pendingApprovalActivities = filteredData?.filter(w => w.activityStatus == activityStatuses.PendingClientApproval) ?? [];
    const flaggedActivities = filteredData?.filter(w => w?.latestFlag) ?? [];

    const loading = isLoadingMechanicalActivities || isLoadingLots;

    return <Grid container direction="column" className={classes.graphWrapper}>
        <Grid item>
            <WidgetTitle
                title='Work Orders Requiring Attention'
                loading={loading}
                info={`This widget shows you the work orders that require attention. The first card is a count of Work Orders that were flagged. You can click on them to see what comment the technician left in case there is an action for you, otherwise, the information there is just for you to follow.\nThe second column are the work orders pending final pricing approval from the client before the PMF team starts working on them. You are able to approve them right from this widget.`}
                filtersList={filters}
                filters={
                    <Grid container spacing={1}>
                        <Grid className={classes.filterIcon} item xs={2}>
                            <IconLot />
                        </Grid>
                        <Grid item xs={10}>
                            <LotsDropdown
                                title='Lot'
                                identifier='lot'
                                values={lots}
                                onSelect={(val) => onDropFilter(val, 'lotId')}
                                value={filters?.['lotId']?.value}
                                showEmpty={true}
                                multiple={true}
                            />
                        </Grid>
                    </Grid>
                } />
        </Grid>
        <Grid item style={{ height: 300 }}>
            <WorkorderButtons
                isPendingApproval={isPendingApproval}
                setIsPendingApproval={setIsPendingApproval}
                PACount={pendingApprovalActivities?.length}
                NACount={flaggedActivities?.length} />

            <WorkorderList activities={isPendingApproval ? pendingApprovalActivities : flaggedActivities} showActions={isPendingApproval} />
        </Grid>
        <GraphLoader loading={loading} />
    </Grid>
}
export default WorkorderApprovalWidget;