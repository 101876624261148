import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const reportsPermissionProfile = {
    VIEW_STORAGE_REPORTS: {
        [modes.EDIT_PERMISSION]: [permissions.REPORTS.STORAGE],
    },
    VIEW_BILLING_REPORTS: {
        [modes.EDIT_PERMISSION]: [permissions.REPORTS.BILLING],
    },
    VIEW_COST_CODES_PAGE: {
        [modes.EDIT_PERMISSION]: [permissions.REPORTS.VIEW_COST_CODES]
    }
}