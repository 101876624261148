import { Button, FormControlLabel, Grid, IconButton, ListItem, Checkbox} from "@material-ui/core";
import CustomInput from "components/CustomInput";
import UserPermissionManagementStyle from "./UserPermissionManagementStyle";
import { permissionDisplay } from "components/Authorize/permissions/PermissionList/permissionDisplay";
import { useState } from "react";

const useStyles = UserPermissionManagementStyle;
const UserPermissionCategory = ({ category, onChangePermission, onAddPermission, onDeletePermission }) => {
    const classes = useStyles();

    const [isAdding, setIsAdding] = useState(false);
    const [newPermission, setNewPermission] = useState({ key: null, id: -1 });

    const onChangePermissionBridge = async (p_permission, p_val) => {
        await onChangePermission(category, p_permission, p_val);
    };

    const onDeletePermissionBridge = async (p_permission) => {
        await onDeletePermission(category, p_permission);
    };

    const getValues = (item) => {
        const permissionOptions = permissionDisplay?.map((p) => ({
            id: p.key,
            name: p.name,
            description: p.description ? p.description : null
        }));
        return permissionOptions;
    };

    const renderOption = (value) => {
        return (
            <div className={classes.dropdown}>
                <div className={classes.name}>{value?.name}</div>
                <div className={classes.description}>{value?.description}</div>
            </div>
        );
    };

    const onChangeNewPermission = (val) => {
        setNewPermission({ key: val });
    };

    const onSaveNewPermission = async () => {
        setIsAdding(false);
        await onAddPermission(category, newPermission);
    };

    const renderPermission = (permission) => {
        const hasToShowPermission = permission?.isVisibleMapping || permission?.isActiveInMapping;
        return hasToShowPermission ? (
            <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox />}
                    label={permission?.name}
                    checked={permission?.isActiveInMapping}
                    onChange={(e, checked) => onChangePermissionBridge(permission, checked)}
                />
            </Grid>
        ) : null;
    };

    const visiblePermissions = category?.permissions?.filter(
        (permission) => permission.isVisibleMapping || permission.isActiveInMapping
    );

    if (visiblePermissions.length === 0) {
        return null;
    }

    return (
        <Grid container direction="row" spacing={2} xs={12}>
            {isAdding ? (
                <Grid container spacing={12}>
                    <Grid item xs={12} className={classes.dropdown}>
                        <CustomInput
                            key={newPermission?.id}
                            label="Permission"
                            elementType="dropdown"
                            values={getValues(null)}
                            value={newPermission.key}
                            onChange={onChangeNewPermission}
                            showEmpty={true}
                            renderOption={renderOption}
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    {visiblePermissions.map((permission) => renderPermission(permission))}
                </>
            )}
        </Grid>
    );
};

export default UserPermissionCategory;
