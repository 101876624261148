import { Button, Grid, List, ListItem } from '@material-ui/core';
import { selectIsClient, selectUserClientAccountId } from 'features/user/userSlice';
import _ from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomInput from '../../../components/CustomInput';
import DropZoneInDialog from '../../../components/DropZoneInDialog';
import ImportResultsDialog from '../../../components/ImportResultsDialog/ImportResultsDialog';
import NotificationProvider from "../../../components/NotificationProvider";
import { activityStatusesList } from '../activityConsts';
import { useStyles } from "../ActivityReportPageStyles";
import { useGetAllActivityItemsQuery, useGetImportTemplateMutation, useImportActivitiesMutation } from '../activitySlice';

function ImportActivities({ clients, refetch }) {
    const classes = useStyles();

    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let [importActivities, { data: importedData, error: importError, isLoading: importLoading }] = useImportActivitiesMutation();
    const [importTemplate, { data: importActivitiesTemplate, error: templateError, isLoading: templateLoading }] = useGetImportTemplateMutation();

    const [selectedClient, setSelectedClient] = useState();
    const [showResults, setShowResults] = useState(false);

    let loggedInUserClientAccountIds = useSelector(selectUserClientAccountId)
    const isClient = useSelector(selectIsClient);

    let clientAccounts = _.flatten(clients?.map(c => c.clientAccounts));

    const downloadTemplate = async () => {
        await importTemplate();
    }

    const onSave = async (file) => {
        let fd = new FormData();
        fd.append("uploaded_file", file);
        fd.append("clientAccountId", selectedClient ?? clientAccounts?.[0]?.id);

        const res = await importActivities(fd);
        if (res.error) {
            NotificationProvider.error("Failed to import activities");
            NotificationProvider.error(res.error?.data?.[0]);
        } else {
            if (res?.data != []) {
                setShowResults(true);
            }
        }
    }

    return (
        <>
            <DropZoneInDialog
                labelDialog={'Import Activities'}
                buttonLabel={'Import Activities'}
                actionButtonLabel={'Save'}
                onSave={onSave}
                valid={!!selectedClient && !Array.isArray(loggedInUserClientAccountIds)}
                formContent={
                    <div className={classes.dialog}>                         
                        {clientAccounts?.length > 1 && 
                            <CustomInput
                                id="clientAccount"
                                label="Client"
                                value={selectedClient}
                                elementType="dropdown"
                                onChange={val => setSelectedClient(val)}
                                values={clientAccounts}
                                showEmpty
                                required
                            />
                        }
                        <Button 
                            color="primary" 
                            className={classes.downloadButton} 
                            onClick={downloadTemplate} 
                            disabled={templateLoading}
                        >
                            Download Template
                        </Button>
                        <br />
                        <br />
                        <Grid container direction='row' className={classes.activityIdLists}>
                            <Grid item xs={isClient ? 12 : 6} className={classes.box}>
                                <Grid item className={classes.directions}>
                                    Use the following codes to indicate the activity to add:
                                </Grid>
                                <List>
                                    {activityItems?.map((item, index) => (
                                        <ListItem key={index}>
                                            {item?.name} - {item?.id}
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            
                            {!isClient && <Grid item xs={6} className={classes.box}>
                                <Grid item className={classes.directions}>
                                    Use the following text to indicate the activity status to add:
                                </Grid>
                                <List>
                                    {activityStatusesList?.map((status, index) => (
                                        <ListItem key={index}>{status?.name}</ListItem>
                                    ))}
                                </List>
                            </Grid>}
                        </Grid>
                    </div>
                }
            />
            <ImportResultsDialog
                importedRows={importedData?.importedActivities}
                failedRows={importedData?.failedActivities}
                skippedRows={importedData?.skippedActivities}
                open={showResults || importLoading}
                setOpen={setShowResults}
                loading={importLoading}
                error={importError}
                reloadData={refetch} 
                dataType="Activities"
                getDisplayFields={(item) => [
                    { label: 'Lot', value: item.activity?.lotName },
                    { label: 'VIN / Asset ID', value: item.activity?.descriptor },
                    { label: 'Activity', value: item.activity?.activityName }
                ]}
            />
        </>
    );
}

export default ImportActivities;