import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Paper } from '@material-ui/core';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@mui/material";
import Authorize from 'components/Authorize/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import DropdownFilter from "components/DropdownFilter";
import NotificationProvider from "components/NotificationProvider";
import { activityStatuses } from "features/activity/activityConsts";
import { useGetAllActivityItemsQuery, useGetAllActivityTypesQuery } from 'features/activity/activitySlice';
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';
import { selectIsClient, selectUserClientId, userHasOneClient } from 'features/user/userSlice';
import { IconCar } from 'icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import FormatService from "services/formatService";
import LoadingSpinner from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { useAddActivityMutation } from '../../../../activity/activitySlice';
import { useGetLotsQuery } from '../../../../lots/lotSlice';
import SingleNote from '../../../../notes/SingleNote';
import { useGetActivityPricingByParametersQuery } from "../../../../pricing/pricingSlice";
import ActivityType from './ActivityType/ActivityType';
import AssetsSelector from './Assets/AssetsSelector';
import { resetFilters, setFilters } from './Assets/assetsFiltersSlice';
import { CreateWorkOrderStyles } from './CreateWorkOrderStyles';
import LotMap from './Lot/LotMap';
import LotSelector from './Lot/LotSelector';
import LotSelectorItem from './Lot/LotSelectorItem';
import ActivityPricing from './Pricing/ActivityPricing';
import ServiceProvider from './ServiceProvider/ServiceProvider';
import ServiceProviderSelectorItem from './ServiceProvider/ServiceProviderSelectorItem';

const CreateWorkOrderPage = ({ open, setOpen }) => {
    const classes = CreateWorkOrderStyles();
    const isNarrowScreen = useMediaQuery("(max-width: 1600px)");

    const dispatch = useDispatch();
    const { filters } = useSelector(state => state.assetsFilters);
    const isClient = useSelector(selectIsClient);
    const clientId = useSelector(selectUserClientId);
    const selectedClient = isClient ? parseInt(clientId) : !!filters?.['clientId']?.value ? parseInt(filters?.['clientId']?.value) : 0;

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery(true);
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery(selectedClient);
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    let { data: clients, error: clientsError, isLoading: isLoadingClients } = useGetClientsQuery();

    lots = lots || [];
    activityItems = activityItems || [];
    activityTypes = activityTypes || [];
    serviceProviders = serviceProviders || [];

    const [assets, setAssets] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState(null);
    const [lot, setLot] = useState(null);
    const [note, setNote] = useState({ content: '', viewByClient: true });
    const [serviceProvider, setServiceProvider] = useState(null);
    const [activity, setActivity] = useState(null);
    const [customActivity, setCustomActivity] = useState(null);
    const [activityType, setActivityType] = useState(null);
    const [pricing, setPricing] = useState([]);
    const [errors, setErrors] = useState({});
    const [saveWasClicked, setSaveWasClicked] = useState(false);
    const hasOneClaim = useSelector(userHasOneClient);

    let { data: prices, error: pricesError, isLoading: isLoadingPrices, refetch: refetchPricing } = useGetActivityPricingByParametersQuery({
        activityItemId: activity,
        lotId: lot,
        vehicleTypeIds: vehicleTypes,
        serviceProviderId: serviceProvider,
        clientId: isClient ? clientId : filters['clientId']?.value
    }, { skip: !activity || !lot || !vehicleTypes || !serviceProvider });

    const [addActivity, { isLoading: isLoadingSave }] = useAddActivityMutation();

    const onChangeClient = (prop, filterValue) => {
        setAssets([]);
        dispatch(setFilters({ ...filters, ...{ [prop]: { value: filterValue?.id ?? '' } } }));
    };

    const handleClose = () => {
        setOpen(false);
        setAssets([]);
        setLot(null);
        setNote({ content: '', viewByClient: true });
        setServiceProvider(null);
        setActivityType(null);
        setActivity(null);
        setCustomActivity(null);
        dispatch(setFilters({ ...filters, ['clientId']: '' }));
        dispatch(resetFilters());
    }

    const handleNoteChange = (field, value) => {
        setNote({ ...note, [field]: value });
    }

    const handleSelectAssets = (assets) => {
        setAssets(assets);
        //if only one asset is selected and lot wasn't selected yet, select it's lot by default
        if (assets?.length == 1 && lot == null) {
            setLot(assets[0]?.lotId);
        }

        setVehicleTypes(assets.map(s => s.vehicleTypeId));
    }

    const isCustomActivity = () => {
        if (!activity) {
            return false;
        }

        let activityItem = activityItems?.find(a => a.id === activity);

        return activityItem?.isCustom;
    };

    const getPricing = () => {
        const pricing = [];
        assets?.map(asset => {
            let priceForType = prices?.filter(p => p.vehicleType == asset.vehicleTypeId)?.[0]?.activityPrices?.[0];
            const activityPricing = {
                vehicleId: asset?.id,
                price: priceForType?.internalCost ?? 0.00,
                revenue: priceForType?.charge ?? 0.00
            }
            pricing?.push(activityPricing);
        })
        return pricing;
    }

    const checkNotSupported = () => {
        //find if any combination isn't supported
        let checkedNotSupported = false;

        prices?.forEach(price => {
            price?.activityPrices?.forEach(ap => {
                if (ap.notSupported) {
                    checkedNotSupported = true;
                }
            })
        });

        return checkedNotSupported;
    }

    const validate = () => {
        const msgs = {};
        let valid = true;

        if (!(assets?.length > 0)) {
            valid = false;
            msgs['assets'] = 'Assets are required';
        }

        if (!lot) {
            valid = false;
            msgs['lot'] = 'Lot is required';
        }

        if (!activityType) {
            valid = false;
            msgs['activityType'] = 'Activity Type is required';
        }

        if (!activity) {
            valid = false;
            msgs['activity'] = 'Activity is required';
        }

        if (isCustomActivity() && !customActivity) {
            valid = false;
            msgs['customActivity'] = 'Custom Activity is required';
        }

        if (checkNotSupported()) {
            valid = false;
            msgs['notSupported'] = 'One or more of the combination are not supported';
        }

        setErrors(msgs);
        return valid;
    }

    const handleSave = async () => {
        setSaveWasClicked(true);
        if (!validate()) {
            return;
        }

        let date = new Date(moment().format("MMM DD, yyyy, hh:mm a") + " UTC");
        let activityDate = date.toISOString();

        let data = {
            activityTypeId: activityType,
            activityItemId: activity,
            activityId: isClient ? activityStatuses.RequestedByClient : activityStatuses.PendingApproval,
            vehicleIds: assets?.map(a => a.id),
            serviceProviderId: serviceProvider,
            lotId: lot,
            price: 0.0,
            revenue: 0.0,
            activityPricings: getPricing(),
            activityDate,
            note: note,
            customActivity
        };

        let result = await addActivity(data);
        if (result && !result.error) {
            NotificationProvider.success("Work order created successfully");
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
        }
        handleClose();
    }

    useEffect(() => {
        if (saveWasClicked)
            validate();
    }, [assets, lot, activity, activityType, customActivity, serviceProvider, prices]);

    useEffect(() => {
        if (prices?.length && activity && lot && vehicleTypes && serviceProvider)
            refetchPricing();
        else {
            setPricing([]);
        }
    }, [assets, activity, lot, vehicleTypes, serviceProvider, clientId, filters?.['clientId']?.value]);

    useEffect(() => {
        //set pricing array
        const pricingArr = [];
        assets?.map(asset => {
            const priceForType = prices?.find(p => p.vehicleType == asset.vehicleTypeId)?.activityPrices[0];
            const descriptor = " " + asset.descriptor ?? asset.assetId;
            const cost = FormatService.formatCurrency(priceForType?.internalCost ?? 0.00);
            const revenue = FormatService.formatCurrency(priceForType?.charge ?? 0.00);
            const notSupported = priceForType?.notSupported
            pricingArr.push({ descriptor, cost, revenue, notSupported });
        })
        setPricing(pricingArr);

    }, [prices, isLoadingPrices]);

    const isLoading = isLoadingLots || isLoadingServiceProviders || isLoadingActivityTypes || isLoadingActivityItems || isLoadingSave;

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xl'>
            <DialogContent>
                <LoadingSpinner loading={isLoading} />
                <Grid container direction="row" spacing={2}>

                    <Grid item xs={12}>
                        <Paper className={classes.card}>
                            <Grid container display="felx" alignItems='center'>
                                <Grid item className={classes.titleIcon}>
                                    <IconCar />
                                </Grid>
                                <Grid item className={classes.title}>
                                    Create Work Order
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {!hasOneClaim && <Grid item xs={12}>
                        <Paper className={classes.card}>
                            <div className={classes.sectionTitle}>
                                Select Client
                            </div>
                            <DropdownFilter
                                values={clients}
                                onSelect={(val) => onChangeClient('clientId', val)}
                                value={filters?.['clientId']?.value}
                                title={'Client'}
                                showEmpty
                                withTypeAhead
                                newAutoCompleteStyle
                            />
                            {!filters?.['clientId']?.value &&
                                <Grid xs={12} className={classes.alertWrapper}>
                                    <Alert severity='error' >
                                        Client is required
                                    </Alert>
                                </Grid>}
                        </Paper>
                    </Grid>}

                    <Grid item xs={12}>
                        <Paper className={classes.card}>
                            <div className={classes.sectionTitle}>
                                Select Assets
                            </div>
                            <AssetsSelector
                                assets={assets}
                                setAssets={handleSelectAssets}
                                isNarrowScreen={isNarrowScreen}
                                disabled={(!filters['clientId']?.value) && !isClient}
                            />
                            {!!errors['assets'] &&
                                <Grid xs={12} className={classes.alertWrapper}>
                                    <Alert severity='error' >
                                        {errors['assets']}
                                    </Alert>
                                </Grid>}
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <Paper className={classes.card}>
                                    <div className={classes.sectionTitle}>
                                        Select A Location
                                    </div>
                                    <LotSelector
                                        lots={lots}
                                        clientId={selectedClient}
                                        isLoading={isLoadingLots}
                                        lot={lot}
                                        setLot={setLot}
                                        isNarrowScreen={isNarrowScreen}
                                    />
                                    {!!errors['lot'] &&
                                        <Grid xs={12} className={classes.alertWrapper}>
                                            <Alert severity='error' >
                                                {errors['lot']}
                                            </Alert>
                                        </Grid>}
                                </Paper>
                            </Grid>

                            <Grid item xs={8}>
                                <Paper className={classes.card}>
                                    <div className={classes.mapWrapper}>
                                        <LotMap
                                            lots={lots}
                                            lot={lot}
                                            setLot={setLot}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} >
                            <Grid item xs={4}>
                                <Paper className={classes.card}>
                                    <div className={classes.sectionTitle}>
                                        Select A Service Provider
                                    </div>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid item xs={12}>
                                            <ActivityType
                                                activity={activity}
                                                setActivity={setActivity}
                                                customActivity={customActivity}
                                                setCustomActivity={setCustomActivity}
                                                isCustomActivity={isCustomActivity}
                                                activityItems={activityItems}
                                                activityType={activityType}
                                                setActivityType={setActivityType}
                                                activityTypes={activityTypes}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ServiceProvider
                                                serviceProvider={serviceProvider}
                                                setServiceProvider={setServiceProvider}
                                                serviceProviders={serviceProviders}
                                                isNarrowScreen={isNarrowScreen}
                                            />
                                        </Grid>
                                    </Grid>
                                    {!!errors['notSupported'] &&
                                        <Grid xs={12} className={classes.alertWrapper}>
                                            <Alert severity='error' >
                                                {errors['notSupported']}
                                            </Alert>
                                        </Grid>}
                                </Paper>
                            </Grid>

                            <Grid item xs={8}>
                                <Paper className={classes.card}>
                                    <div className={classes.sectionTitle}>
                                        Notes
                                    </div>
                                    <SingleNote
                                        note={note}
                                        handleNoteChange={handleNoteChange}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.summaryCard}>
                            <Grid container direction="row" spacing={1} >
                                <Grid item xs={12}>
                                    <Grid container display="felx" alignItems='center'>
                                        <Grid item className={classes.titleIcon}>
                                            <IconCar />
                                        </Grid>
                                        <Grid item className={classes.title}>
                                            Work Order Summary
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.sectionTitle}>
                                        Assets
                                    </div>
                                    <AssetsSelector
                                        assets={assets}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={isNarrowScreen ? 10 : 4}>
                                        <div className={classes.sectionTitle}>
                                            Location
                                        </div>
                                        {lot > 0 ? <LotSelectorItem
                                            option={lots?.find(a => a.id == parseInt(lot))}
                                            isNarrowScreen
                                        /> : <div className={classes.summaryItem} />}
                                    </Grid>
                                </Grid>

                                <Grid item xs={isNarrowScreen ? 10 : 4}>
                                    <div className={classes.sectionTitle}>
                                        Service Provider
                                    </div>
                                    {serviceProvider > 0 ? <ServiceProviderSelectorItem
                                        option={serviceProviders?.find(a => a.id == parseInt(serviceProvider))}
                                        isNarrowScreen
                                    /> : <div className={classes.summaryItem} />}
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.sectionTitle}>
                                        Activity Type
                                    </div>
                                    <div className={classes.summaryItemBorder}>
                                        {activityTypes?.find(a => a.id == parseInt(activityType))?.name}
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.sectionTitle}>
                                        Activity
                                    </div>
                                    <div className={classes.summaryItemBorder}>
                                        {activityItems?.find(a => a.id == parseInt(activity))?.name}
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.sectionTitle}>
                                        Notes
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: note?.content }} className={classes.summaryItemBorder} />
                                </Grid>

                                <Authorize profile={permissionProfiles.DASHBOARD.WORKORDER_CLIENT_FILTER}>
                                    <Grid item xs={12}>
                                        <div className={classes.sectionTitle}>
                                            Estimated Cost
                                        </div>
                                        <div className={classes.summaryItemBorder}>
                                            {(!serviceProviders?.length) ?
                                                'Pricing and Availability varies based on location. ParkMyFleet will reach out to you soon with more information' :
                                                <ActivityPricing pricing={pricing} cost />}
                                        </div>
                                    </Grid>
                                </Authorize>

                                <Grid item xs={12}>
                                    <div className={classes.sectionTitle}>
                                        Estimated Charge
                                    </div>
                                    <div className={classes.summaryItemBorder}>
                                        <ActivityPricing pricing={pricing} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                {isLoading && <CircularProgress size={'1em'} />}
                <div className={classes.actionWrapper}>
                    <Button
                        className={classes.actionButton}
                        onClick={handleClose}
                        color='primary'
                        variant='outlined'
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.actionButton}
                        disabled={isLoading}
                        onClick={handleSave}
                        color='primary'
                        variant='contained'
                    >
                        Save
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
export default CreateWorkOrderPage;