import { Button, Menu } from "@material-ui/core";
import { useState } from "react";
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import DropZoneInDialog from "components/DropZoneInDialog";
import NotificationProvider from "components/NotificationProvider";
import { useUploadDocumentMutation } from "../vehicleAttachmentsSlice";
import CustomInput from "components/CustomInput";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import LoadingSpinner from "components/LoadingSpinner";
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle";

export const VehiclePhotoDocsMenu = ({ onEdit, vehicleDetails, documentTypes }) => {
    const classes = vehicleDetailsPageStyle();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [documentType, setDocumentType] = useState();

    let [uploaddocument, { isLoading: uploadingDoc, isError, isSuccess }] = useUploadDocumentMutation();
    
    const uploadDocument = async (file) => {
        const d = new Date();
        let fileBase64 = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            fileBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');
            const res = uploaddocument({
                "id": 0,
                "vehicleId": vehicleDetails?.id,
                "fileUrl": "",
                "filename": file.name,
                "file_type": file.type,
                "createdAt": d.toISOString(),
                "is_Deleted": 0,
                "filedata": fileBase64,
                "documentType": parseInt(documentType)
            })
            if (res.error) {
                NotificationProvider.error("Failed to upload the document");
            } else {
                NotificationProvider.success("Successfully uploaded the document");
            }
        };
        setDocumentType();
    };

    return (
        <>
            <Button
                onClick={handleClick}
                endIcon={<KeyboardArrowDownOutlinedIcon/>}
                variant="outlined"
                color="primary"
            >
                Add
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Authorize profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_EDIT_ADD_PHOTO}>
                    <Button 
                        className={classes.editButton}
                        onClick={() => onEdit(true)}
                    >
                        Add Photo
                    </Button>
                </Authorize>
                <Authorize profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_UPLOAD_DOCUMENT}>
                    <DropZoneInDialog
                        labelDialog={'Upload Document'}
                        buttonLabel={'Add Document'}
                        btnStyle=''
                        onSave={uploadDocument}
                        formContent={
                            <div style={{ paddingBottom: '1em' }}>
                                <CustomInput
                                    id="documentType"
                                    label='Document Type'
                                    elementType='dropdown'
                                    value={documentType}
                                    values={documentTypes}
                                    onChange={(val) => setDocumentType(val)}
                                    showEmpty
                                />
                            </div>
                        }
                        accept={{
                            'application/pdf': ['.pdf'],
                            'image/jpg': ['.jpg'],
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpeg']
                        }}
                        actionButtonLabel="Upload"
                        className={classes.upload}
                    />
                </Authorize>
            </Menu>
        </> 
    )
}
