import { Grid, Paper } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { activityColors, activityStatusById, activityStatuses } from "features/activity/activityConsts";
import { useGetAllAliasesQuery } from "features/activity/activityStatusAliasSlice";
import ActivityStatusAliasView from "features/activityStatusAlias";
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { selectIsClient, selectUserClientId } from "features/user/userSlice";
import { useTitle } from "hooks/useTitle";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import FormatService from "services/formatService";
import { useGetAllActivityItemsQuery, useGetAllActivityTypesQuery, useGetMechanicalActivitiesQuery } from '../../../activity/activitySlice';
import ActivityListTable from "../MechanicalDashboard/ActivityList/ActivityListTable";
import ApproveWorkOrderDialog from "./ApproveWorkOrder/ApproveWorkOrderDialog";
import { DashboardFilters } from "./DashboardFilters";
import WorkorderByStatusCountCard from "./WorkorderByStatusCountCard/WorkorderByStatusCountCard";
import WorkorderCostByTypeGraph from "./WorkorderCostByTypeGraph/WorkorderCostByTypeGraph";
import WorkorderCostTotal from "./WorkorderCostTotal/WorkorderCostTotal";
import WorkorderCostTrendGraph from "./WorkorderCostTrendGraph/WorkorderCostTrendGraph";
import { workorderDashboardPageStyles } from "./WorkorderDashboardPageStyles";
import WorkorderSlaGraph from "./WorkorderSlaGraph/WorkorderSlaGraph";
import WorkorderTypesCard from "./WorkorderTypesCard/WorkorderTypesCard";

const WorkorderDashboardPage = () => {
    useTitle('Workorder Dashboard');

    const { filters } = useSelector(state => state.workorderDashboardState);
    const classes = workorderDashboardPageStyles();
    let userClientId = useSelector(selectUserClientId);
    const { workorderid } = useParams();
    const [openApproveWorkOrder, setOpenApproveWorkOrder] = useState(workorderid != null);

    let { data: mechanicalActivities, error: mechanicalActivitiesError, refetch, isFetching: isLoadingMechanicalActivities } = useGetMechanicalActivitiesQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientsError, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: aliases, error: aliasError, isLoading: isLoadingAlias } = useGetAllAliasesQuery();
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);

    //const twelveMonthsAgo = moment().subtract(12, 'months');
    const filteredActivities = mechanicalActivities?.filter(v =>
        //(moment(v.activityDate, 'YYYY-MM-DD').isBetween(twelveMonthsAgo, moment(), undefined, '[]'))//This is done in the API already
        (!filters.lotId?.length || filters.lotId.includes(v.lotId))
        && (!filters.clientId || filters.clientId == v.clientId)
        && (filters.month == 0 || filters.month == v.activityMonth)
    );

    const monthSelected = filters.month != 0;
    const isClient = useSelector(selectIsClient);

    const mapExportData = row => {
        const data = {
            'Activity': activityItems?.find(a => a.id == row.activityItemId)?.name,
            'Activty Status': aliases?.find(a => a.activityStatusId === row.activityStatus && a.clientId === row.clientId)?.alias ?? activityStatusById?.[row.activityStatus]?.name,
            'Lot': lots?.find(l => l.id == row.lotId)?.name,
            'AssetID': row.assetId,
            'VIN': row.descriptor,
            'Within SLA': row.withinSla ? 'Yes' : 'No',
            'Activity Date': FormatService.formatDate(row.activityDate),
            'Charge': row.revenue,
        }

        if (!isClient) {
            data['Cost'] = row.price
            data['Client'] = clients?.find(c => c.id == row.clientId)?.name;
        }
        return data;
    };

    const isLoadingDashboardData = isLoadingMechanicalActivities || isLoadingActivityItems || isLoadingLots || isLoadingClients || isLoadingActivityTypes;
    const clientConfigs = clientsWithConfigs?.find(c => c.id === userClientId);
    const canSeeFlags = !isClient || (isClient && clientConfigs?.config?.lotConfig?.seeFlags);

    let clientId = !!userClientId?.length ? parseInt(userClientId) : !!filters?.clientId.length ? filters?.clientId : undefined;

    return (
        <>
            <Grid container direction="row" spacing={3} >
                <Grid item xs={12}>
                    <DashboardFilters refetch={refetch} workorders={filteredActivities} />
                </Grid>
                <Grid item xs={2} >
                    <Paper elevation={1} className={classes.card} style={{ borderTop: `7px solid ${activityColors[activityStatuses.RequestedByClient]}` }}>
                        <WorkorderByStatusCountCard
                            loading={isLoadingDashboardData}
                            workorders={filteredActivities}
                            statuses={[
                                activityStatuses.RequestedByClient,
                                activityStatuses.RequestedByVendor,
                                activityStatuses.AutoRequested]}
                            text={'Not Started'}
                            mapExportData={mapExportData}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Paper elevation={1} className={classes.card} style={{ borderTop: `7px solid ${activityColors[activityStatuses.PendingApproval]}` }}>
                        <WorkorderByStatusCountCard
                            loading={isLoadingDashboardData}
                            workorders={filteredActivities}
                            statuses={[activityStatuses.PendingApproval]}
                            text={<ActivityStatusAliasView activityIds={[activityStatuses.PendingApproval]} clientId={clientId} readonly={true} />}
                            mapExportData={mapExportData}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={2} >
                    <Paper elevation={1} className={classes.card} style={{ borderTop: `7px solid ${activityColors[activityStatuses.PendingClientApproval]}` }}>
                        <WorkorderByStatusCountCard
                            loading={isLoadingDashboardData}
                            workorders={filteredActivities}
                            statuses={[activityStatuses.PendingClientApproval]}
                            text={<ActivityStatusAliasView activityIds={[activityStatuses.PendingClientApproval]} clientId={clientId} readonly={true} />}
                            mapExportData={mapExportData}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={2} >
                    <Paper elevation={1} className={classes.card} style={{ borderTop: `7px solid ${activityColors[activityStatuses.PricingApprovedByClient]}` }}>
                        <WorkorderByStatusCountCard
                            loading={isLoadingDashboardData}
                            workorders={filteredActivities}
                            statuses={[activityStatuses.PricingApprovedByClient, activityStatuses.InProgress]}
                            text={'Approved and In Progress'}
                            mapExportData={mapExportData}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Paper elevation={1} className={classes.card} style={{ borderTop: `7px solid ${activityColors[activityStatuses.PendingInvoice]}` }}>
                        <WorkorderByStatusCountCard
                            loading={isLoadingDashboardData}
                            workorders={filteredActivities}
                            statuses={[activityStatuses.PendingInvoice]}
                            text={<ActivityStatusAliasView activityIds={[activityStatuses.PendingInvoice]} clientId={clientId} readonly={true} />}
                            mapExportData={mapExportData}
                        />
                    </Paper>
                </Grid>
                {canSeeFlags && <Grid item xs={2} >
                    <Paper elevation={1} className={classes.card} style={{ borderTop: `7px solid #E8A48E` }}>
                        <WorkorderByStatusCountCard
                            loading={isLoadingDashboardData}
                            workorders={filteredActivities}
                            filterDataByField='latestFlag'
                            text={'Requiring Attention'}
                            mapExportData={mapExportData}
                            additionalFilters={{ flaggedStr: { value: 'Yes' } }}//TODO, this is not a good implementation
                        />
                    </Paper>
                </Grid>}
                <Authorize profile={permissionProfiles.DASHBOARD.WORKORDER_SLA}>
                    <Grid item xs={3}>
                        <Paper className={classes.card} elevation={1}>
                            <WorkorderSlaGraph
                                loading={isLoadingDashboardData}
                                workorders={filteredActivities}
                            />
                        </Paper>
                    </Grid>
                </Authorize>
                <Grid item xs={isClient ? 6 : 4}>
                    <Paper className={classes.card} elevation={1}>
                        {monthSelected ? <WorkorderCostTotal loading={isLoadingDashboardData} workorders={filteredActivities} /> : <WorkorderCostTrendGraph
                            loading={isLoadingDashboardData}
                            workorders={filteredActivities}
                        />}
                    </Paper>
                </Grid>
                <Grid item xs={isClient ? 6 : 5}>
                    <Paper className={classes.card} elevation={1}>
                        <WorkorderCostByTypeGraph
                            loading={isLoadingDashboardData}
                            showAverage={!monthSelected}
                            workorders={filteredActivities}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.card} elevation={1}>
                        <WorkorderTypesCard
                            loading={isLoadingDashboardData}
                            showAverage={!monthSelected}
                            workorders={filteredActivities}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ position: 'relative' }}>
                    <ActivityListTable allowActions={false} infoTextOnTable='The selected filters will apply to this table only, and will not affect the dashboard widgets' />
                </Grid>
            </Grid >
            <ApproveWorkOrderDialog open={openApproveWorkOrder} setOpen={setOpenApproveWorkOrder} workOrderId={workorderid} />
        </>
    );
};

export default WorkorderDashboardPage;
