import { makeStyles } from "@material-ui/core"

export const fadeInImageStyle = makeStyles(theme => ({
    imageDeleteIcon: {
        opacity: 0,
        transition: "opacity 0.5s ease"
    },
    imageContainer: {
        position: "relative",
        width: 223,
        maxWidth: '100%',
        height: 120,
        margin: "0 1rem 1rem 0",
        borderRadius: 4,
        overflow: "hidden",
        "&:hover": {
            boxShadow: '1px 2px 5px grey',
            "& $imageDeleteIcon": {
                opacity: 1,
            },
            "& $editImageIcon": {
                opacity: 1,
            },
            "& $buttomsContainer": {
                opacity: 1,
            }
        }
    },
    fileName: {
        position: "absolute",
        left: '50%',
        bottom: '1em',
        fontSize: 12,
        opacity: 0.9,
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap',
        borderRadius: '2px',
        padding: '2px',
        color: theme.palette.primary.main,
    },
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        opacity: 0,
        transition: "opacity 1s ease",
        objectFit: "cover"
    },
    imageEditing: {
        position: "absolute",
        top: 0,
        left: 0,
        height: '80%',
        width: '100%',
        opacity: 0,
        transition: "opacity 1s ease",
        objectFit: "cover"
    },
    imagePreviewWrapper: {
        height: '120px',
        marginTop: '10px',
        '& img': {
            //objectFit: 'contain',
            position: 'initial'
        }
    },
    imagePreviewWrapperEditting:{
        height: '320px',
        marginTop: '10px',
        '& img': {
            position: 'initial'
        }
    },
    placeholder: {
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#ebebeb'
    },
    placeholderIcon: {
        opacity: ".7",
        width: "2.5em",
        height: "2.5em",
        zIndex:1
    },
    imageVisible: {
        opacity: 1
    },
    editImageIcon: {
        position: "absolute",
        bottom: 0,
        left: 0,
        opacity: 0,
        transition: "opacity 0.5s ease",
        marginLeft: "50px"
    },
    buttomsContainer: {
        display: "flex",
        position: "absolute",
        bottom: 0,
        left: 0,
        opacity: 0,
        transition: "opacity 0.5s ease"
    },
    icon: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
}));
