import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    input: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    inputField: {
        width: 250,
        padding: '0.2em',
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: "flex-start",
        padding: '0.5em 0',
        alignItems: "center"
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: "space-between",
        padding: '0 0 0.5em 1em',
        alignItems: "center"
    },
    label: {
        fontSize: "1rem",
        display: 'flex',
        padding: '0.5em 0',
    },
    add: {
        paddingLeft: 20,
        paddingBottom: 20,
    },
    selectTransporter: {
        padding: 20,
        width: '500px'
    },
    selectActionButtons: {
        padding: '0rem 0 1rem',
        textAlign: 'right',
        margin: '1rem'
    },
    multiSelect: {
        width: '250px',
        flexWrap: 'wrap',
        display: 'flex',
    },
    listItem: {
        padding: '0.5em 0',
        margin: '1rem 0'
    },
    rateButton: {
        width: 200,
        paddingLeft: 65,
        paddingRight: 65,
        marginBottom: '.5em',
    },
    menuItem: {
        paddingLeft: 50,
        paddingRight: 50,
    },
    card: {
        margin: "0.5rem 0",
        padding: '1em 1.5em',
        minHeight: "7rem",
    },
    title: {
        margin: '1rem 0 0 1rem',
        fontWeight: 450,
        paddingTop: "5px",
        color: theme.palette.primary.main,
        fontSize: '1.2em',
    },
    washPriceField: {
        marginLeft: '0.5em',
        whiteSpace: 'nowrap',
        width: '50%'
    },
    prices: {
        display: "flex",
        alignItems: 'center',
        flexDirection: 'row',
        width: '60%'
    },
    spacing: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    icon: {
        minWidth: "30px",
        "& img": {
            width: "20px"
        }
    },
    radioButton: {
        height: `${'20px'}`,
        width: `${'20px'}`,
        margin: "0.5rem"
    },
    radioButtonLabel: {
        flex: 1
    },
    subTitle: {
        margin: 0,
        fontWeight: 700,
        paddingTop: "5px",
        color: theme.palette.grey[600],
        fontSize: '16px',
    },
    informationText: {
        padding: '0 1em 0.5em 1em',
    },
    inputLabel: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    inputText: {
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    },
    inputNumberWrapper: {
        height: '25px',
        width: '41px',
        margin: '0 0.5rem 0 0.5rem'
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: "row-reverse",
        alignItems: "center",
        paddingLeft: '1rem',
    },
    inputNumber: {
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    },
    rectangle: {
        backgroundColor: theme.palette.background.default,
        height: '2px',
        margin: '0.5rem',
        '& fieldset': {
            border: 'none'
        }
    },
    iconWrapper: {
        backgroundColor: theme.palette.primary.light,
        height: '50px',
        width: '35px',
        borderRadius: '2px',
        '& fieldset': {
            border: 'none'
        },
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        marginLeft: "0.7rem",
    },
    iconsWrapper: {
        alignItems: "center",
        display: 'flex',
        justifyContent: "space-between"
    },
    monthlyBillingReport: {
        display: "flex",
        justifyContent: "end"
    },
    addButton: {
        display: "flex",
        justifyContent: "end",
        padding: '0.5rem 0 0 0'
    },
    saveButton: {
        display: "flex",
        justifyContent: "end",
        margin: '0.5rem 0 0.5rem 1rem'
    },
    businessUnitWrapper: {
        alignItems: "center",
        display: 'flex',
        justifyContent: "flex-start"
    },
    deliveredStatusReadonly: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginRight: '1rem'
    },
    cardTitle: {
        fontWeight: 'bold',
        marginBottom: '0.5em'
    }
}))