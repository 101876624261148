import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import usersPageStyle from './styles';
import { IconDownload } from "../../../../icons";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import TotalUserListDialog from "./totalUserListDialog";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";
import { Lot } from "../../../clients/clientConfigs/lots/components/Lot";

const TotalActiveUsers = ({
    users,
    mapExportData,
    clients,
    lots,
    roles,
    permission,
    refetchUsers
}) => {

    const classes = usersPageStyle();
    const usersFilter = users ?? [];
    const totalUsers = usersFilter?.length ?? 0;

    return (
        <>
            <Grid className={classes.cardWrapper} container direction="column">
                <div className={classes.actions}>
                    <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_VIEW_ACCESS_TABLE}>
                        <TotalUserListDialog user={usersFilter} clients={clients} lots={lots} roles={roles}
                            permission={permission}
                            refetchUsers={refetchUsers} />

                    </Authorize>
                    <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_VIEW_ACCESS_EXPORT}>
                        <ExportButton className={classes.exportButton}
                            btnStyle='text'
                            label=""
                            icon={<IconDownload />}
                            fileLabel="Export"
                            data={usersFilter}
                            mapExportData={mapExportData} />
                    </Authorize>
                </div>
                <Grid item className={classes.count} >{totalUsers}</Grid>
                <Grid item>
                    <div className={classes.text}>
                        Total Active Users
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default memo(TotalActiveUsers);
