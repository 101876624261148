import { makeStyles } from "@material-ui/core";

export const fleetManagementDashboardStyle = makeStyles((theme) => ({
    widgetWrapper: {
        boxShadow: '2px 3px 10px #d7d6d6',
        height: '45vh'
    },
    widgetWrapperBig: {
        height: '58vh',
    }
}));