import { Grid } from '@material-ui/core';
import MapWrapper from "components/MapWrapper/MapWrapper";
import { googleMapService } from "components/MapWrapper/googleMapService";
import { useGetLotsQuery } from "features/lots/lotSlice";
import LotsMapMarker from "features/maps/markers/LotsMapMarker/LotsMapMarker";
import VehicleMapMarker from "features/maps/markers/VehicleMapMarker/VehicleMapMarker";
import { useGetVehicleLocationInfoQuery } from "features/vehicles/vehicleSlice";
import { VehicleMapStyle } from "./style";
import { AssetFilterTypes, assetLocationsTypes, assetLocationsTypesById } from 'features/vehicles/VehicleMap/VehicleMapConsts';
import TelematicsMarker from '../markers/TelematicsMarker/TelematicsMarker';
import { activityStatuses } from 'features/activity/activityConsts';
import { arrayToObject } from 'utils/object-util';
import moment from 'moment';

export const VehicleMap = ({
    vehicleDetails,
    activityLogData,
    latestLoad
}) => {
    const classes = VehicleMapStyle();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();

    let { data: vehicleLocationInfo, error, isLoading } = useGetVehicleLocationInfoQuery({ id: vehicleDetails?.id }, { skip: !vehicleDetails });

    const lotLookup = arrayToObject(lots);

    // const lotMarkers = lots?.map(lot => {
    //     return {
    //         ...lot,
    //        
    //         address: googleMapService.convertAddressPartsToAddressString(lot),
    //         icon: <LotsMapMarker
    //             lot={lot}
    //             color={lot.id == vehicleDetails?.domicileLot ? '#115B43' : lot.pmfLot ? "#0090FE" : "#E48900"}
    //             text={lot.name}
    //         />
    //     }
    // })

    const latestActivity = activityLogData?.find(a => a.activityId == activityStatuses.InProgress || a.activityId == activityStatuses.PendingInvoice)
    const latestVd = activityLogData?.find(a => !!a.vehicleDetailsId);
    const latestUpdate = moment(latestActivity?.date) > moment(latestVd?.date) ? latestActivity : latestVd;
    const vdInfo = vehicleDetails.descriptor ?? vehicleDetails.assetId ?? vehicleDetails.licensePlate;
    const markers = vehicleLocationInfo ? [
        //...lotMarkers,
        // {
        //     ...vehicleLocationInfo,
        //     address: vehicleLocationInfo?.latitude ? null : googleMapService.convertAddressPartsToAddressString(vehicleLocationInfo),
        //     icon: <VehicleMapMarker
        //         color="#0090FE"
        //         text={vehicleDetails?.vin ?? vehicleDetails?.descriptor ?? vehicleDetails?.assetId}
        //     />,
        //     center: true
        // },
        ...(
            !latestLoad?.isComplete ? [
                {
                    icon: assetLocationsTypesById?.[AssetFilterTypes.TRANSPORT]?.icon(`Pickup: ${lotLookup[latestLoad?.pickupLocationId]?.name}`),
                    address: googleMapService.convertAddressPartsToAddressString(lotLookup[latestLoad?.pickupLocationId] ?? {})
                },
                {
                    icon: assetLocationsTypesById?.[AssetFilterTypes.TRANSPORT]?.icon(`Delivery: ${lotLookup[latestLoad?.deliveryLocationId]?.name}`),
                    address: googleMapService.convertAddressPartsToAddressString(lotLookup[latestLoad?.deliveryLocationId] ?? {})
                }
            ] : []
        ),
        {
            icon: assetLocationsTypesById?.[AssetFilterTypes.LAST_CHECKIN]?.icon(lotLookup[latestVd?.lotId]?.name),
            address: googleMapService.convertAddressPartsToAddressString(lotLookup[latestVd?.lotId] ?? {}),
            center: true
        },
        {
            icon: assetLocationsTypesById?.[AssetFilterTypes.MECHANICAL]?.icon(lotLookup[latestActivity?.lotId]?.name),
            address: googleMapService.convertAddressPartsToAddressString(lotLookup[latestActivity?.lotId] ?? {})
        },
        {
            icon: assetLocationsTypesById?.[AssetFilterTypes.RECENT_UPDATE]?.icon(lotLookup[latestUpdate?.lotId]?.name),
            address: googleMapService.convertAddressPartsToAddressString(lotLookup[latestUpdate?.lotId] ?? {})
        },
        ...(vehicleDetails?.lastGpsLocation ?
            [{
                icon:
                    <TelematicsMarker text={`${vdInfo}`}
                        wrapperSize="30" iconSize="18" tooltip={`Latitude: ${vehicleDetails.lastGpsLocation?.lat}, Longitude: ${vehicleDetails.lastGpsLocation?.long}`} />,
                id: vehicleDetails.id,
                center: false,
                latitude: vehicleDetails.lastGpsLocation?.lat,
                longitude: vehicleDetails.lastGpsLocation?.long,
                address: "",
            }] : [])
        ,

    ] : [];

    return (
        <div className={classes.mapContainer}>
            <div className={classes.mapWrapper}>
                {vehicleLocationInfo && <MapWrapper
                    defaultZoom={8}
                    markers={markers}
                />}
            </div>
            <Grid className={classes.lengendWrapper} container justifyContent="space-between">
                {/* <Grid item className={`${classes.legendItem} ${classes.legendItemPmf}`}>Closest PMF Sites</Grid>
                <Grid item className={`${classes.legendItem} ${classes.legendItemClient}`}>Closest Client Sites</Grid>
                <Grid item className={`${classes.legendItem} ${classes.legendItemDomicile}`}>Domicile Lot</Grid> */}
                {assetLocationsTypes?.map((location, index) => <>
                    <Grid item xs={2} container alignItems='center' className={`${classes.legendItem} `}>
                        <Grid xs={3}>{location.icon()}</Grid>
                        <Grid xs={9} className={classes.itemName}> {location?.name}</Grid>
                    </Grid>
                </>)}
            </Grid>
        </div>
    )
}