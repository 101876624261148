import { Link } from "@material-ui/core";
import { assetsListWidgetStyle } from "./style";
import { useGetStatusesForClientQuery } from "features/vehicles/VehicleStatus/vehicleStatusSlice";
import { arrayToObject } from "utils/object-util";

const AssetItem = ({ asset }) => {
    const classes = assetsListWidgetStyle();
    let { data: clientVehicleStatuses, error: clientVehicleStatusesError, loading: clientVehicleStatusesLoading } = useGetStatusesForClientQuery({ clientId: 0 })
    clientVehicleStatuses = clientVehicleStatuses || [];

    const statusLookup = arrayToObject(clientVehicleStatuses);

    return <div>
        <div className={classes.indicatorWrapper}>
            <div>
                <div className={classes.indicator} style={{ background: 'lightGreen' }}></div>
            </div>
            <Link style={{ /*color: "#46535d",*/ paddingLeft: 3 }} href={`/assets/detail/${asset?.id}`}
                target="_blank">{!!asset?.descriptor ? asset?.descriptor : asset?.assetId}
            </Link>
        </div>
        {!!asset?.descriptor &&
            <div className={classes.details}>
                Asset Id: {asset?.assetId ?? '-'}
            </div>}
        <div className={classes.details}>
            License Plate: {asset?.licensePlate ?? '-'}
        </div>
        <div className={classes.details}>
            Status: {statusLookup?.[asset?.vehicleStatusId]?.label ?? '-'}
        </div>
    </div>
}
export default AssetItem;