import { Grid } from "@material-ui/core";
import { EditFormOutput } from "features/Forms/FormOutput/EditFormOutput/EditFormOutput";
import FormOutput from "features/Forms/FormOutput/FormOutput";
import { formTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { customFormStyle } from "./style";
import { IconCar } from 'icons';
import Toolbar from '@material-ui/core/Toolbar';
import { useGetClientsQuery } from "features/clients/clientSlice";
import _, { first } from "lodash";

export const VehicleCustomForm = ({ vehicle, formType, customFormResponse, isLoading, mode = 'download' }) => {
    let {
        data: clients,
        error: clientError,
        isLoading: isLoadingClients
    } = useGetClientsQuery(null);
    clients = clients || [];
    const clientAccounts = _.flatten(clients?.map(c => c.clientAccounts));
    var classes = customFormStyle();

    return (
        <>
            {mode == 'edit' ? <EditFormOutput customFormResponse={customFormResponse} lotId={vehicle.lotId} formType={formType} />
                : <FormOutput
                    formType={`${formType == formTypes.Checkin ? "Check-In" : formType == formTypes.Checkout ? "Check-out" : "Condition Report"} `}
                    vin={vehicle?.descriptor ?? vehicle?.assetId}
                    clientName={clientAccounts?.find(c => (c.id === customFormResponse?.clientAccountId))?.name}
                    loading={isLoading}
                    fileName={`${formType == formTypes.Checkin ? "checkin" : "checkout"}-${vehicle?.descriptor}.pdf`}
                    headerContent={
                        <Grid style={{ borderBottom: '2px solid #AADAFF' }}>
                            <Toolbar disableGutters>
                                <img
                                    src="/images/ParqAIlogo.png"
                                    className={`${classes.logo} `}
                                    alt="parq.ai"
                                />
                            </Toolbar>
                            <Grid container alignItems='center' justifyContent="space-between" >
                                <h1 className={`${classes.title}`}>{`${formType == formTypes.Checkin ? "Check-In" : formType == formTypes.Checkout ? "Check-out" : "Condition Report"} `}</h1>

                            </Grid>
                            <div className={`${classes.sub}`}> <IconCar />{vehicle?.descriptor ?? vehicle?.assetId}</div>
                            <h1 className={`${classes.sub}`}>{clientAccounts?.find(c => (c.id === customFormResponse?.clientAccountId))?.name}</h1>
                        </Grid>
                    }
                    {...customFormResponse}
                />}
        </>
    )
}