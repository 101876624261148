
function SvgIconPmfLogo(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 80.8" height='0.8em' fill='#0061a9' fillRule='evenodd' {...props}>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path class="cls-1"
                        d="M10.72,49.61H74.28a4.12,4.12,0,0,1,4.11,4.11v8.81a4.12,4.12,0,0,1-4.11,4.12H10.72a4.12,4.12,0,0,1-4.11-4.12V53.72a4.12,4.12,0,0,1,4.11-4.11ZM42.5,6.19,3.86,19A2.94,2.94,0,0,1,2,13.37L42.5,0,83,13.37A2.94,2.94,0,1,1,81.14,19L42.5,6.19ZM18.55,43.44c3-14.16,10.07-12.39,24-12.27,13.88-.12,21-1.89,24,12.27Zm54,0C68.69,24.33,58.1,26,42.5,26S16.31,24.33,12.44,43.44H10.72A10.32,10.32,0,0,0,.44,53.72v8.81A10.31,10.31,0,0,0,10.72,72.81h4v8h8.7v-8H61.53v8h8.7v-8h4A10.31,10.31,0,0,0,84.56,62.53V53.72A10.32,10.32,0,0,0,74.28,43.44ZM66.64,53.56a4.57,4.57,0,1,1-4.57,4.57,4.57,4.57,0,0,1,4.57-4.57Zm-47.86,0a4.57,4.57,0,1,1-4.57,4.57,4.57,4.57,0,0,1,4.57-4.57Z" />
                </g>
            </g>
        </svg>
    );
}

export default SvgIconPmfLogo;
