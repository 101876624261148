import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
export const activityListTabsList = {
    PendingApprovelTable: 0,
    PendingApprovelList: 1

}
export const activityListTabs = [
    {
        key: activityListTabsList.PendingApprovelTable,
        label: 'Pending Approval Table',
    },
    {
        key: activityListTabsList.PendingApprovelList,
        label: 'Confirm Work Orders',
    },
];

const ActivityListTabs = ({selectedTab, setSelectedTab}) => {

    return (
        <TabsWrapper tabs={activityListTabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
    )
}

export default ActivityListTabs;
