import { useGetClaimsByUserIdQuery, useGetUserLotRelationsForUserQuery } from '../../usersSlice';
import usersPageStyle from '../UsersPageStyle';
import EditUserPermissions from './userPermissions/EditUserPermissions';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { Card, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, Box, Paper, Grid } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { useEffect, useState } from 'react';
import EditUserLotRelations from './userLotRelations/EditUserLotRelations';
import { useSelector } from 'react-redux';
import { selectUserId } from 'features/user/userSlice';
import EditUserInformation from './userInformation/EditUserInformation';
import EditUserRestrictions from './userRestrictions/EditUserRestrictions';
import { hasPermission } from 'components/Authorize/authorizeUtil';
import { TabsWrapper } from 'components/TabsWrapper/TabsWrapper';
import { IconKey, IconLot, IconUser } from 'icons';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import UserTasksList from './UserTasks/UserTasksList';
import { permissions } from 'components/Authorize/permissions/permissions';

const useStyles = usersPageStyle;

const EditUserDialog = ({ user, onCancel }) => {
    const classes = useStyles();

    const userId = useSelector(selectUserId);

    let { data: userLotRelations, error: relationsError, isLoading: isLoadingRelations } = useGetUserLotRelationsForUserQuery(userId, { skip: !userId });
    let { data: userClaims, error: userClaimsError, isLoading: isLoadingUserClaims } = useGetClaimsByUserIdQuery(user?.id, { skip: !user?.id });

    userLotRelations = userLotRelations || [];
    userClaims = userClaims?.data || [];

    const [relations, setRelations] = useState(userLotRelations);

    useEffect(() => {
        setRelations(userLotRelations);
    }, [isLoadingRelations]);


    const tabs = [
        {
            label: <><IconUser width={25} height={25} className={classes.labelIcon} />User Information</>,
            component: <EditUserInformation user={user} userClaims={userClaims} relations={userLotRelations}></EditUserInformation>
        },
        {
            label: <><IconLot width={25} height={25} className={classes.labelIcon} />Lot Relations</>,
            component: <Paper className={classes.paper}>
                <EditUserLotRelations userId={user?.id} relations={relations} setRelations={setRelations} onCancel={onCancel} />
            </Paper>
        },
        {
            label: <><IconKey width={25} height={25} className={classes.labelIcon} />Access</>,
            component: <EditUserRestrictions user={user} userClaims={userClaims} userLotRelations={userLotRelations}></EditUserRestrictions>
        },
        {
            label: <><AlarmOnOutlinedIcon width={25} height={25} className={classes.labelIcon} />Tasks</>,
            component: <Paper className={classes.componentWrapper}><UserTasksList userId={user?.id}/></Paper>
        }
    ]
    if (hasPermission(permissions.USER.MANAGE_PERMISSIONS.key)) {
        tabs.push({
            label: <><VerifiedUserOutlinedIcon width={25} height={25} className={classes.labelIcon} />Permissions</>,
            component: <EditUserPermissions userId={user?.id} onCancel={onCancel} />
        })
    }
    return (
        <Dialog open={!!user?.id} onClose={() => onCancel()} aria-labelledby="form-dialog-title" fullWidth maxWidth='lg' >
            <DialogTitle id="form-dialog-title">
                <Grid container direction='row' alignItems='center' spacing={2}>
                    <Grid item>
                        <IconUser width={40} height={40} className={classes.titleIcon} />
                    </Grid>
                    <Grid item>
                        Edit User
                        <div className={classes.subTitle}>{user?.email}</div>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <div className={classes.editUserDialogContent}>
                    <TabsWrapper tabs={tabs} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { onCancel() }} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default EditUserDialog;
