import { modes } from "../modes";
import { roles } from "../roles";

export const auditPermissionProfile = {
    AUDIT_UPLOAD: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR],
    },
    AUDIT_RUN: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    AUDIT_DELETE: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    AUDIT_STATUS: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ACCOUNT_MANAGER, roles.SUPERVISOR]
    },
}