import UsersTable from "../UsersTable";
import { IconProfile } from "../../../../icons";

import { DialogContent, IconButton } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import { Dialog } from "@mui/material";
import { useState } from "react";


const TotalUserListDialog = ({ user, permission, clients, lots, roles, refetchUsers }) => {

    const [showList, setShowList] = useState(false);
    return (
        <>
            <IconButton size="small" onClick={() => setShowList(true)}><ListIcon /></IconButton>
            <Dialog maxWidth='xl'
                fullWidth open={showList} onClose={() => setShowList(false)}>
                <DialogContent>
                    <UsersTable
                        permission={permission}
                        users={user}
                        roles={roles}
                        clients={clients}
                        lots={lots}
                        searchVal={""}
                        refetchUsers={refetchUsers}
                        title={<><IconProfile /> Total Users</>}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default TotalUserListDialog;