import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { QuickbooksAccountsStyle } from "./style";
import CustomInput from "components/CustomInput";
import DropdownFilter from "components/DropdownFilter";
import { InvoiceTypesList } from "features/invoice/invoiceConsts.ts";
import NotificationProvider from "components/NotificationProvider";
import { useAddClientQuickBooksMappingMutation, useUpdateClientQuickBooksMappingMutation } from "./QuickBooksAccountMappingSlice";
import { useState } from "react";
import { Alert } from "@mui/material";

const AddQuickBooksMapping = ({ 
    open, setOpen, 
    mappingToAdd, setMappingToAdd, 
    mappings, clients, lots, quickbooksCustomers,
    isEditing, setIsEditing
}) => {
    const classes = QuickbooksAccountsStyle();

    const [duplicateError, setDuplicateError] = useState(false);
    const [addMapping, { isLoading: isLoadingAdd }] = useAddClientQuickBooksMappingMutation();
    const [editMapping, { isLoading: isLoadingEdit }] = useUpdateClientQuickBooksMappingMutation();

    const onChangeValue = (key, val) => {
        setMappingToAdd({...mappingToAdd, [key]: val})
    }

    const onCancel = () => {
        setOpen(false);
        setMappingToAdd();
        setIsEditing(false);
        setDuplicateError(false);
    }

    const onSave = async () => {
        if(!validateDuplicates()) { 
            setDuplicateError(true);
            return;
        }

        let result = isEditing ? await editMapping({ id: mappingToAdd?.id, quickbooksId: mappingToAdd?.quickbooksId }) 
                        : await addMapping(mappingToAdd);

        if (result && !result.error) {
            NotificationProvider.success("Mapping saved successfully");
            onCancel();
        } else {
            NotificationProvider.error("Failed to save the mapping");
        }
    }

    let valid = mappingToAdd?.type && mappingToAdd?.clientId && mappingToAdd?.quickbooksId;

    const validateDuplicates = () => {
        let res = !mappings?.some((mapping) =>
            mapping?.type === mappingToAdd?.type &&
            mapping?.clientId === mappingToAdd?.clientId &&
            mapping?.lotId === mappingToAdd?.lotId && (
                mapping?.quickbooksId === mappingToAdd?.quickbooksId || // Exact duplicate
                mapping?.quickbooksId !== mappingToAdd?.quickbooksId   // different QB customers
            ) && mapping?.id != mappingToAdd?.id // when editing exclude itself 
        );
        return res;
    };
    
    let isLoading = isLoadingAdd || isLoadingEdit;
  
    return (
        <Dialog open={open} maxWidth={'xs'}>
            <DialogTitle>
                {isEditing ? "Edit Mapping" : "Add New Mapping"}
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column'>
                    <Grid item className={classes.fields}>
                        <CustomInput
                            label='Type'
                            elementType='dropdown'
                            value={mappingToAdd?.type}
                            values={InvoiceTypesList}
                            onChange={(val) => onChangeValue("type", +val)}
                            showEmpty
                            required
                            disabled={isEditing}
                        />
                    </Grid>
                    <Grid item className={classes.fields}>
                        <CustomInput
                            label='Client'
                            elementType='dropdown'
                            value={mappingToAdd?.clientId}
                            values={clients}
                            onChange={(val) => onChangeValue("clientId", +val)}
                            showEmpty
                            required
                            disabled={isEditing}
                        />                        
                    </Grid>
                    <Grid item className={classes.fields}>
                        <DropdownFilter
                            title={'Lot'}
                            id="lotId"
                            value={mappingToAdd?.lotId}
                            onSelect={(val) => onChangeValue("lotId", val?.id)}
                            elementType="dropdown"
                            values={[{ name: 'All Lots', id: null }, ...lots]}
                            withTypeAhead
                            showEmpty
                            shrink={mappingToAdd?.lotId}
                            disabled={isEditing}
                        />
                    </Grid>
                    <Grid item className={classes.fields}>
                        <CustomInput
                            label='QuickBooks Customer'
                            elementType='dropdown'
                            value={mappingToAdd?.quickbooksId}
                            values={quickbooksCustomers?.map(q => ({...q, name: q.fullyQualifiedName}))}
                            onChange={(val) => onChangeValue("quickbooksId", +val)}
                            showEmpty
                            required
                        />
                    </Grid>
                </Grid>
                
                {duplicateError && <Alert severity='error' className={classes.alert}>
                    A mapping between these options already exists
                </Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color='primary' variant="contained">
                    Cancel
                </Button>
                <Button onClick={onSave} color='primary' variant="contained" disabled={!valid}>
                    Save
                </Button>
                {isLoading && <CircularProgress size={'1em'} />}
            </DialogActions>
        </Dialog>
    );
}

export default AddQuickBooksMapping;