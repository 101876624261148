import { Button, Grid } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DataTable from "components/DataTable/DataTable";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { InvoiceTypesList } from "features/invoice/invoiceConsts.ts";
import { useGetInvoiceCustomerQuery } from "features/invoice/loads/loadInvoiceSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import useSortOptions from "hooks/useSortOptions";
import { IconAdd, IconDeleteBlue, IconEdit } from "icons";
import { orderBy } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterService, { fieldTypes } from "services/filterService";
import { arrayToObject } from "utils/object-util";
import AddQuickBooksMapping from "./AddQuickBooksMapping";
import { resetFilters, setFilters, useDeleteClientQuickBooksMappingMutation, useGetMappingsQuery } from "./QuickBooksAccountMappingSlice";
import { QuickbooksAccountsStyle } from "./style";
import usePrompt from "hooks/usePrompt";
import NotificationProvider from "components/NotificationProvider";

const QuickbooksAccountMappingPage = ({}) => {
    const classes = QuickbooksAccountsStyle();

    let { data: quickbooksCustomers, error: customerError, isLoading: isLoadingCustomer } = useGetInvoiceCustomerQuery({});
    let { data: quickbooksParqMapping, error: mappingError, isLoading: isLoadingMapping } = useGetMappingsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    
    quickbooksCustomers = quickbooksCustomers || [];
    quickbooksParqMapping = quickbooksParqMapping?.data || [];
    clients = clients || [];
    lots = lots || [];

    const isLoading = () => isLoadingCustomer || isLoadingMapping || isLoadingClients || isLoadingLots;

    const customersLookup = arrayToObject(quickbooksCustomers);
    const clientsLookup = arrayToObject(clients);
    const lotsLookup = arrayToObject(lots, 'id');

    const mappings = quickbooksParqMapping?.map(mapping => ({
        ...mapping,
        typeName: InvoiceTypesList.find(t => t.id == mapping?.type)?.name,
        clientName: clientsLookup[mapping?.clientId]?.name,
        quickbooksAccName: customersLookup[mapping?.quickbooksId]?.fullyQualifiedName,
        lotName: mapping?.lotId ? lotsLookup[mapping?.lotId]?.name : 'All Lots'
    }))

    const [deleteMapping] = useDeleteClientQuickBooksMappingMutation();

    const [mappingToAdd, setMappingToAdd] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const dispatch = useDispatch();    
    const { triggerPrompt } = usePrompt();
    const [sortOptions, sortData] = useSortOptions();
    const { filters } = useSelector(state => state.quickBooksMappingFilters); //check
    
    const updateFilters = (prop, value) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    }

    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue);
    };
    
    const onResetFilters = () => {
        dispatch(resetFilters());
    };

    let filterConfig = [
        { type: fieldTypes.TEXT, size: 2, title: 'Client', key: 'clientId', values: clients },
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.TEXT, size: 2, title: 'Type', key: 'type', values: InvoiceTypesList }
    ];

    let tableColumns = [
        { name: "Type", key: "typeName" },
        { name: "Parq client name", key: "clientName" },
        { name: "QB client name", key: "quickbooksAccName" },
        { name: "Lot name", key: "lotName"}
    ];

    let filteredMappings = FilterService.filter(filters, null, mappings, filterConfig);

    const rowActions = (rowId) => ([
        {
            icon: <IconEdit />,
            onClick: () => onEdit(rowId),
            profile: permissionProfiles.INVOICE.EDIT_QUICKBOOKS_ACCOUNT_MAPPING
        },
        {
            icon: <IconDeleteBlue width={'18'} />,
            onClick: () => onDelete(rowId),
            profile: permissionProfiles.INVOICE.EDIT_QUICKBOOKS_ACCOUNT_MAPPING
        }
    ]);

    const onEdit = (id) => {
        setMappingToAdd(mappings?.find(m => m.id === id));
        setOpenDialog(true);
        setIsEditing(true);
    };

    const onDelete = (id) => {
        triggerPrompt({
            title: "Delete Mapping",
            content: "Are you sure you want to delete this mapping?",
            onConfirm: async () => {
                let res = await deleteMapping(id);

                if (!res?.error) {
                    NotificationProvider.success('Deleted successfully');
                } else {
                    NotificationProvider.error('Failed to delete');
                }
            },
        });
    }
    
    return (
        <Authorize profile={permissionProfiles.INVOICE.VIEW_QUICKBOOKS_ACCOUNT_MAPPING}>
            {isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                <div style={{ height: 'calc(100vh - 95px)' }}>
                    <DataTable
                        columns={tableColumns}
                        rows={orderBy(
                            filteredMappings,
                            sortOptions.columnToSort,
                            sortOptions.sortDirection
                        )}
                        filters={filters}
                        onDropFilter={onDropFilter}
                        dropDownsConfig={filterConfig}
                        rowIdentifier='id'
                        onSort={sortData}
                        sortDirection={sortOptions.sortDirection}
                        columnToSort={sortOptions.columnToSort}
                        noItemsMessage='There are no mappings matching this criteria to display'
                        withFilterPanel={true}
                        onResetFilters={onResetFilters}
                        title={'QuickBooks Account Mapping'}
                        actionButtons={<Authorize profile={permissionProfiles.INVOICE.EDIT_QUICKBOOKS_ACCOUNT_MAPPING}>
                            <Grid item>
                                <Button 
                                    variant='contained' 
                                    color='primary' 
                                    onClick={() => setOpenDialog(true)}
                                    startIcon={<IconAdd />}
                                >
                                    Add New Mapping
                                </Button>
                            </Grid>
                        </Authorize>}
                        actions={rowActions}
                    />
                </div>
            }
            <AddQuickBooksMapping 
                open={openDialog} setOpen={setOpenDialog}
                mappingToAdd={mappingToAdd} setMappingToAdd={setMappingToAdd} 
                mappings={mappings} clients={clients} lots={lots} quickbooksCustomers={quickbooksCustomers} 
                isEditing={isEditing} setIsEditing={setIsEditing}
            />
        </Authorize>
    )
}

export default QuickbooksAccountMappingPage;