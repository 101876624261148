import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    header: {
    },
    headerTitle: {
        display: "flex",
        alignItems: "center",
        fontSize: "1.25rem",
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    headerIcon: {
        marginRight: theme.spacing(1),
    },
    filters: {
        marginBottom: theme.spacing(2),
    },
    root: {
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
        border: 0,
        height: '100%'
    },
    manageIcon: {
        color: theme.palette.primary.main
    },
    dataSearchContainer: {
        marginTop: 10
    },
    container: {
        height: 800
    },
    card: {
        padding: 20
    },
    roleName: {
        fontSize: "1.0rem", fontWeight: "bold", color: "black"
    },
    rolesContainer: { overflowY: 'auto', maxHeight: 400, height: "auto" },
    rolesCategoryContainer: { padding: 15 },
    accordioTitle: { fontSize: "1.0rem", fontWeight: "bold" },
    accordioCategoryTitle: { fontSize: "1.0rem", fontWeight: "bold", color: theme.palette.primary.main },
    roleDesc: { fontSize: "1rem", fontWeight: "bold", color: "black", padding: 15 },
    chevronIcon: {
        color: theme.palette.primary.main
    },
    deletePer: {
        color: "red",
        fontSize: "1rem"
    },
    roleCatQuan: {
        fontSize: "9px", fontWeight: "bold", color: "black"
    },
    roleHeader: { display: "flex", justifyContent: "space-between", alignItems: "center" },
    permissionSectionColor: {
        backgroundColor: "#f4f4f4",
        borderRadius: '5px',
        padding: '5px 10px'
    },
    loaderContainer: { display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }
}));