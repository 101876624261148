export const userManagementPermissions = {
    ACCESS: {
        key: 'User.management.access',
        name: 'Access'
    },
    FULL_ACCESS_ALL: {
        key: 'User.management.full.access.all',
        name: 'Full Access All'
    },
    DELETE: {
        key: 'User.management.Delete',
        name: 'Delete users'
    },    
    CREATE: {
        key: 'User.management.Create',
        name: 'Create users'
    },
    EDIT: {
        key: 'User.management.Edit',
        name: 'Edit users'
    },
    RESET_PASSWORD: {
        key: 'User.management.reset.password',
        name: 'Reset Password Users'
    },
    ARCHIVE_USER: {
        key: 'User.management.archive',
        name: 'Archive Users'
    },
    ADD_TO_BLACK_LIST: {
        key: 'User.management.add.to.black.list',
        name: 'Add to Black List'
    },
    SEND_RESTORE_PASSWORD: {
        key: 'User.management.restore.password',
        name: 'Restore Password Users'
    },
    EXPORT_ALL: {
        key: 'User.management.Export.All',
        name: 'Export All'
    },    
    
    TOTAL_ACTIVE: {
        key: 'User.management.total.active',
        name: 'Total Active Users'
    },
    TOTAL_VIEW_ACCESS_EXPORT: {
        key: 'User.management.total.active.view.access.export',
        name: 'Access Total Active Users Export'
    },
    TOTAL_VIEW_ACCESS_TABLE: {
        key: 'User.management.total.active.view.access.table',
        name: 'Access Total Active Users Table'
    },

    
    TOTAL_INACTIVE: {
        key: 'User.management.total.inactive',
        name: 'Total Inactive Users'
    },
    TOTAL_INACTIVE_VIEW_ACCESS_EXPORT: {
        key: 'User.management.total.inactive.view.access.export',
        name: 'Access Total Inactive Users Export'
    },
    TOTAL_INACTIVE_VIEW_ACCESS_TABLE: {
        key: 'User.management.total.inactive.view.access.table',
        name: 'Access Total Inactive Users Table'
    },

    
    TOTAL_BLACK_LIST: {
        key: 'User.management.total.blacklist',
        name: 'Total Inactive Users'
    },
    TOTAL_BLACK_LIST_VIEW_ACCESS_EXPORT: {
        key: 'User.management.total.blacklist.view.access.export',
        name: 'Access Total Inactive Users Export'
    },
    TOTAL_BLACK_LIST_VIEW_ACCESS_TABLE: {
        key: 'User.management.total.blacklist.view.access.table',
        name: 'Access Total Inactive Users Table'
    },

    
    TOTAL_PURGE: {
        key: 'User.management.total.purge',
        name: 'Total Purge Users'
    },
    TOTAL_PURGE_VIEW_ACCESS_EXPORT: {
        key: 'User.management.total.purge.view.access.export',
        name: 'Access Total Purge Users Export'
    },
    TOTAL_PURGE_VIEW_ACCESS_TABLE: {
        key: 'User.management.total.purge.view.access.table',
        name: 'Access Total Purge Users Table'
    },
    
    
    ACTIVE_DASH_BY_ROL: {
        key: 'User.management.dash.by.role',
        name: 'Total Active By Role'
    },
    ACTIVE_DASH_PER_CLIENT: {
        key: 'User.management.dash.per.client',
        name: 'Total Active Per Client'
    },
    ROLE_MANAGEMENT_ACCESS: {
        key: 'User.management.role.management.access',
        name: 'Role Management Access'
    },
    LOT_RELATIONS:{
        key: 'User.management.role.relations',
        name: 'Role Relations Access'
    },
    USER_INFORMATION:{
        key: 'User.management.user.information',
        name: 'User information Access'
    },
    ACCESS_LOT_CLIENT:{
        key: 'User.management.lot.client.access',
        name: 'Lot & Client Access'
    },
    ACCESS_LOT_CLIENT_EMPTY_FIELD: {
        key: 'User.management.lot.client.access.empty',
        name: 'Lot & Client ADD empty Access'
    },
    TASK:{
        key: 'User.management.task.access',
        name: 'Task Access'
    },
    PERMISSIONS:{
        key: 'User.management.permission.access',
        name: 'Permission Access'
    },
};
