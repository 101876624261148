import { makeStyles } from "@material-ui/core";

export const widgetTitleStyle = makeStyles((theme) => ({
    widgetTitle: {
        color: theme.palette.primary.dark,
        fontWeight: '600',
        paddingLeft: '1.5rem',
        fontSize: 14,
        display: 'flex'
    },
    header: {
        paddingTop: '1rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        width: '100%'
    },
    filterIcon: {
        '& path': {
            fill: theme.palette.primary.main,
            stroke: theme.palette.primary.main
        }
    },
    filters: {
        padding: '1rem',
        minWidth: '20vw',
        maxWidth: '20vw',
    },
    badge: {
        backgroundColor: '#ff5555'
    },
}));