import { Grid } from "@material-ui/core";
import { hasPermission } from "components/Authorize/authorizeUtil";
import { permissions } from "components/Authorize/permissions/permissions";
import NotificationProvider from "components/NotificationProvider";
import { activityStatuses } from "features/activity/activityConsts";
import { mechanicalWorkTaskStyle } from "features/mechanicalWork/mechanicalWorkTaskStyle";
import { useGetAllActivitiesQuery, useUpdateActivityLogMutation } from "features/activity/activitySlice";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { activityPermissionProfile } from "components/Authorize/permissionProfiles/activityPermissionProfile";
import Authorize from "components/Authorize";
import { arrayToObject } from "utils/object-util";
import { useEffect, useState } from "react";
import { useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';
import ActivityStatusAliasView from "features/activityStatusAlias/ActivityStatusAliasView";

const MechanicalWorkDetails = ({ activity, currentActivityItem }) => {

    const classes = mechanicalWorkTaskStyle();

    const [updateActivityLog, { isLoading }] = useUpdateActivityLogMutation();

    let { data: activities } = useGetAllActivitiesQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: serviceProviders, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();

    const lotLookup = arrayToObject(lots);

    const clientLookup = arrayToObject(clients);

    const activityLookup = arrayToObject(activities);

    const serviceProviderLookup = arrayToObject(serviceProviders);

    const [status, setStatus] = useState(activity?.activityId)

    useEffect(() => {
        setStatus(activity?.activityId)
    }, [activity])

    const activityInfo = {
        ...activity,
        lot: lotLookup?.[activity?.lotId],
        client: clientLookup?.[activity?.vehicle?.clientId],
        status: activityLookup?.[activity?.activityId],
        serviceProvider: serviceProviderLookup?.[activity?.serviceProviderId]
    }

    const onSaveAction = async (val) => {
        let prevStatus = status;
        setStatus(val);
        const result = await updateActivityLog({
            ...activityInfo,
            status: val ?? activityInfo?.activityId,
            inventoryUsed: null
        });

        if (result.error) {
            NotificationProvider.error("Failed to update the activity");
            setStatus(prevStatus);
        } else {
            NotificationProvider.success("Successfully updated the activity");
        }
    };

    const statusValues = ((!currentActivityItem?.noCharge && !hasPermission(permissions.ACTIVITIES.MANAGE_PENDING_INVOICE.key)) ? activities?.filter(a => a.id !== activityStatuses.Completed && a.id !== activityStatuses.CompleteBilling) : activities) ?? [];

    return (
        <>
            <Grid container direction="row" alignItems="center" spacing={1}
                className={classes.details}>
                <Grid item className={classes.detail}>
                    <Grid container>
                        <div className={classes.detailLabel}>Workorder ID:</div>
                        <div>{activityInfo?.workOrderId}</div>
                    </Grid>
                </Grid>
                <Grid item className={classes.detail}>
                    <Grid container>
                        <div className={classes.detailLabel}>Asset ID:</div>
                        <div>{activityInfo?.vehicle?.assetId}</div>
                    </Grid>
                </Grid>
                <Grid item className={classes.detail}>
                    <Grid container>
                        <div className={classes.detailLabel}>VIN:</div>
                        &nbsp;<NavLink exact
                            to={`/assets/detail/${activityInfo?.vehicleId}`}>
                            {activityInfo?.vehicle?.descriptor}
                        </NavLink>
                        &nbsp;
                    </Grid>
                </Grid>
                <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_SAVE}>
                    <Grid item className={classes.detail}>
                        <Grid container>
                            <div className={classes.detailLabel}>Client:</div>
                            <div>{activityInfo?.client?.name}</div>
                        </Grid>
                    </Grid>
                </Authorize>
                <Grid item className={classes.detail}>
                    <Grid container>
                        <div className={classes.detailLabel}>Lot:</div>
                        <div>{activityInfo?.lot?.name}</div>
                    </Grid>
                </Grid>
                <Grid item className={classes.detail}>
                    <Grid container>
                        <div className={classes.detailLabel}>Service Provider:</div>
                        <div>{activityInfo?.serviceProvider?.name}</div>
                    </Grid>
                </Grid>
            </Grid >

            <Grid container direction="row" alignItems="center" spacing={1}
                className={classes.details}>
                <Grid item className={classes.detail}>
                    <div className={classes.status}>
                        <div className={classes.detailLabel}>Status:</div>
                        <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_FIELDS} readonlyComponent={<div><ActivityStatusAliasView activityIds={[activityInfo?.status?.id]} clientId={activityInfo?.vehicle?.clientId} readonly={true} /></div>}>
                            <ActivityStatusAliasView
                                activityIds={statusValues?.map(s => s.id)}
                                clientId={activityInfo?.vehicle?.clientId}
                                readonly={false}
                                dropdownValues={statusValues}
                                value={status}
                                onChange={e => onSaveAction(parseInt(e))}
                                label={''}
                            />
                        </Authorize>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default MechanicalWorkDetails;