import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { tagInputStyle } from './style'

export const TagInput = ({
    tagList,
    tagString,
    onChange,
    placeholder = 'Add a tag',
    validateFunc = () => true,
    disabled = false
}) => {
    const classes = tagInputStyle();

    const tags = tagList ? tagList : tagString ? tagString?.split(',') : [];

    const onChangeTags = (newTags) => {
        if (tagList) {
            onChange(newTags);
            return;
        }
        if (tagString !== undefined) {
            onChange(newTags?.join(','))
        }
    }

    const renderTag = (props) => {
        let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
        return (
            <span key={key} {...other} className={classes.tag}>
                {getTagDisplayValue(tag)}
                {!disabled &&
                    <a className={classes.removeTagBtn} onClick={(e) => onRemove(key)} >x</a>
                }
            </span>
        )
    }

    return (
        <TagsInput
            value={tags}
            onChange={onChangeTags}
            onlyUnique
            addKeys={[9, 13, 188]}
            className={classes.wrapper}
            inputProps={{
                placeholder: disabled ? '' : placeholder,
                className: classes.input
            }}
            renderTag={renderTag}
            validate={validateFunc}
            addOnBlur
            disabled={disabled}
        />
    )
}