import { Grid } from "@material-ui/core";
import ExportButton from "components/ExportButton/ExportButton";
import { activityStatuses } from "features/activity/activityConsts";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { IconDownload } from "icons";
import { groupBy, sum } from "lodash";
import { Bar, BarChart, Cell, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts";
import FormatService from "services/formatService";
import { theme } from "../../../../../styles/theme";
import GraphHeader from "../GraphHeader/GraphHeader";
import { GraphLoader } from "../GraphLoader";
import { workorderDashboardPageStyles } from "../WorkorderDashboardPageStyles";
import { dashboardColors } from "../workorderDashboardConsts";

const WorkorderCostByTypeGraph = ({ workorders, showAverage = true, loading }) => {

    const classes = workorderDashboardPageStyles();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const completed = workorders?.filter(a => a.activityStatus == activityStatuses.Completed) ?? [];

    const groupedByActivityType = groupBy(completed, 'activityItemId');

    const averages = [];
    Object.keys(groupedByActivityType).map(key => {
        const activitiesForType = groupedByActivityType[key];
        const totalCost = sum(activitiesForType?.map(a => a.revenue));
        const avg = showAverage ? totalCost > 0 ? totalCost / 12 : 0 : totalCost;

        averages.push({
            activityItemId: key,
            activityName: activityItems?.find(a => a.id == key)?.name ?? 'Unknown',
            count: Math.ceil(avg),
        });
    });

    const CustomXAxisTick = ({ x, y, payload }) => {
        if (payload && payload.value) {
            return (
                <Text
                    fontSize={"0.6em"}
                    width={"8px"}
                    x={x}
                    y={y}
                    color={theme.palette.primary.main}
                    textAnchor="middle"
                    verticalAnchor="start"
                >{payload.value}</Text>
            );
        }
        return null;
    };

    const mapExportData = (a => {
        return {
            ['Activity']: a.activityName,
            [showAverage ? 'Average Charge' : 'Charge']: FormatService.formatCurrency(a.count),
        };
    })

    return (
        <div style={{ height: 350 }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <GraphHeader
                    title={showAverage ? 'Average Cost Per Activity' : 'Cost Per Activity'}
                    rightContent={<ExportButton
                        className={classes.exportButton}
                        btnStyle='text' label=""
                        icon={<IconDownload />}
                        fileLabel="Export"
                        data={averages}
                        mapExportData={mapExportData}
                    />}
                />
            </Grid>
            <ResponsiveContainer width="100%" height="80%">
                <BarChart data={averages}>
                    <XAxis
                        interval={2}
                        axisLine={false}
                        dataKey="activityName"
                        tickLine={false}
                        tick={<CustomXAxisTick />}
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ color: theme.palette.primary.main, fontSize: '0.7em' }}
                        tickFormatter={amount => {
                            return FormatService.formatCurrency(amount)
                        }}
                    />
                    <Tooltip
                        content={(val) => <GraphTooltip val={val} />}
                    />
                    <Bar
                        dataKey="count"
                        fill={theme.palette.primary.light}
                    >
                        {averages.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={dashboardColors[index % dashboardColors.length]} />
                        ))}
                        {/* <LabelList dataKey="count" content={({ value }) => `$${value}`} position="insideTop" style={{ fill: theme.palette.feedback.light }} /> */}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <GraphLoader loading={loading} />
        </div>
    )
}

export default WorkorderCostByTypeGraph;

const GraphTooltip = ({ val }) => {
    const classes = workorderDashboardPageStyles();
    return (
        <div className={classes.tooltip}>
            <div><b>{val?.label}</b></div>
            <div>{FormatService.formatCurrency(val.payload?.[0]?.value)}</div>
        </div>
    )
}