import { makeStyles } from "@material-ui/core";
import { activityColors } from "features/activity/activityConsts";

export const activityTableStyle = makeStyles((theme) => ({
    errorIcon: {
        fill: 'white',
    },
    errorIconWrapper: {
        background: '#fd8f8f',
    },
    errorTooltip: {
        padding: '2em',
        width: '500px'
    },
    title: {
        fontWeight: 550,
        fontSize: 17
    },
    activityTableCount: {
        padding: '0.2em 0.5em',
        marginLeft: '0.5em',
        borderRadius: '5px',
        fontWeight: '400',
        fontSize: '0.8em',
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
        minWidth: '3em',
        textAlign: 'center'
    },
    dot: {
        height: '10px',
        width: '10px',
        borderRadius: '50%'
    },
    activityTableCount1: {
        background: activityColors[1]
    },
    activityTableCount2: {
        background: activityColors[2]
    },
    activityTableCount3: {
        background: activityColors[3]
    },
    activityTableCount4: {
        background: activityColors[4]
    },
    activityTableCount5: {
        background: activityColors[5]
    },
    activityTableCount6: {
        background: activityColors[6]
    },
    activityTableCount7: {
        background: activityColors[7]
    },
    activityTableCount8: {
        background: activityColors[8]
    },
    activityTableCount9: {
        background: activityColors[9]
    },
    activityTableCount10: {
        background: activityColors[10]
    },
    activityTableCount11: {
        background: activityColors[11]
    },
    activityTableCount12: {
        background: activityColors[12]
    },
    activityTableCountAttention: {
        background: '#E8A48E'
    },
    buttonIcon: {
        width: '10'
    },
    flaggedRow: {
        position: 'relative',
        "&::before": {
            content: "''",
            background: '#ffff0029',
            position: "absolute",
            width: '100%',
            height: '100%',
            pointerEvents: 'none'
        },
    },
    listContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        position: 'relative',
    },
    listWrapper: {
        flex: 6,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '0.7em',
        width: '100%',
        overflowX: 'auto',
    },
    header: {
        padding: '0 1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row"
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 20,
        paddingLeft: 8,
        marginRight: 32
    },
    activityListConfirmWrapper: {
        display: 'flex',
        justifyContent: 'center',
        padding:'0.5rem 0.5rem',
        height: '100%',
    },
    activityListConfirm: {
        width: '600px',
        padding: '0.5rem'
    },
}));