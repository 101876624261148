import { makeStyles } from "@material-ui/core";

export const ReservationDocumentsStyle = makeStyles((theme) => ({
    documentsWrapper: {
        minHeight: '300px',
        maxHeight: '530px',
        overflowY: 'auto',
        padding: '1rem',
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
        }
    },
    typeDocumentWaper: {
        borderTop: `1px solid  ${theme.palette.primary.main}`,
        margin: '0.5em 0',

        border: '1px solid transparent',
        width: '100%',
    },
    boxTitle: {
        color: theme.palette.primary.main,
        marginTop: '1em',
        marginBottom: '1em'
    },
    attacment: {
        padding: '1em'
    }
}));