import { modes } from "../modes";
import { roles } from "../roles";
import { permissions } from "../permissions/permissions";

export const inspectionPermissionProfile = {
    INSPECTION_CREATE: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.INSPECTION.INSPECTIONS_ACCESS],
    },
    INSPECTION_ACCESS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
        [modes.EDIT_PERMISSION]: [permissions.INSPECTION.INSPECTIONS_ACCESS],
    }
}