import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const invoicePermissionProfile = {
    INVOICES_VIEW: {
        [modes.EDIT_PERMISSION]: [
            permissions.INVOICES.READ_TRANSPORTATION_INVOICE
            , permissions.INVOICES.READ_STORAGE_INVOICE
            , permissions.INVOICES.READ_MECHANICAL_INVOICE
        ]
    },
    INVOICES_MANAGE: {
        [modes.EDIT_PERMISSION]: [permissions.STORAGE_INVOICES.MANAGE]
    },
    EDIT_TRANSPORTATION_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.EDIT_TRANSPORTATION_INVOICE]
    },
    APPROVE_DELETE_TRANSPORTATION_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.APPROVE_DELETE_TRANSPORTATION_INVOICE]
    },
    READ_TRANSPORTATION_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.READ_TRANSPORTATION_INVOICE]
    },
    READ_STORAGE_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.READ_STORAGE_INVOICE]
    },
    APPROVE_DELETE_MECHANICAL_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.APPROVE_DELETE_MECHANICAL_INVOICE]
    },
    READ_MECHANICAL_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.READ_MECHANICAL_INVOICE]
    },
    VIEW_QUICKBOOKS_ACCOUNT_MAPPING: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.VIEW_QUICKBOOKS_ACCOUNT_MAPPING],
        //[modes.EDIT]: [roles.OWNER]
    },
    EDIT_QUICKBOOKS_ACCOUNT_MAPPING: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.EDIT_QUICKBOOKS_ACCOUNT_MAPPING],
        //[modes.EDIT]: [roles.OWNER]
    }
}