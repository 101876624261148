import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useGetWorkOrderInfoQuery } from "features/activity/activitySlice";
import WorkOrderCard from '../WorkOrderCard/WorkOrderCard';

const ApproveWorkOrderDialog = ({ open, setOpen, workOrderId }) => {
    let { data: workorder, error: workorderError, isLoading: isLoadingWorkorder } = useGetWorkOrderInfoQuery(workOrderId, { skip: !open || !workOrderId });
    workorder = workorder?.data;

    const handleDone = () => {
        setOpen(false);
    }

    return (
        <Authorize profile={permissionProfiles.ACTIVITY.APPROVE_PENDING_CLIENT}>
            <Dialog open={open} onClose={() => { }} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <WorkOrderCard workOrder={workorder} isLoadingData={isLoadingWorkorder} handleDone={handleDone} />
                </DialogContent>
            </Dialog>
        </Authorize>
    );
}

export default ApproveWorkOrderDialog;