
import { Grid, Paper, Typography } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import LoadingSpinner from 'components/LoadingSpinner';
import { activityColors, activityStatusById, activityStatuses } from "features/activity/activityConsts";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import FormatService from "services/formatService";
import WorkOrderActions from "../../../WorkorderDashboard/WorkOrderCard/WorkOrderActions";
import { workorderApprovalStyle } from "../style";
import { arrayToObject } from "utils/object-util";
import { FlagAsset } from "features/vehicles/Flags/FlagAsset";
import { FlagEntityTypes } from "features/flag/flagsConsts";

const WorkOrderCard = ({ workOrder, isLoadingData, handleDone, showActions = true }) => {
    const classes = workorderApprovalStyle();
    let { data: activityItems, error: activityItemsError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const activityItemsLookup = arrayToObject(activityItems);

    const activityType = activityItemsLookup?.[workOrder?.activityItemId];
    const status = workOrder?.activityId;
    const stillPending = status == activityStatuses.PendingClientApproval;

    const isLoading = () => {
        return isLoadingData || isLoadingActivityItems;
    }

    return (
        <>

            <div className={classes.divWrap}>
                <LoadingSpinner loading={isLoading()} />
                {workOrder && <Paper className={classes.card} style={{ borderStyle: 'solid', borderColor: `${activityColors[status]}`, borderWidth: '0 0 0 7px' }}>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2} className={classes.container}>

                        <Grid item xs={8} className={classes.textWrapper} alignContent="center">
                            <div className={classes.wrapper}>
                                <Typography className={classes.lightText}>
                                    {workOrder?.clientName}
                                </Typography>

                                {stillPending && <Typography className={classes.lightText}>
                                    {FormatService.formatDateNoConversion(workOrder?.activityDateFormatted)}
                                </Typography>}
                            </div>

                            <Grid container className={classes.textWrapper}>
                                <Grid item xs={7} >
                                    <Typography className={classes.regText}>
                                        Type of activity
                                    </Typography>
                                    <Typography className={classes.boldText}>
                                        {activityType?.name ?? 'Unknown'}
                                    </Typography>
                                    <Typography className={classes.regText}>
                                        {`Asset ID: ${workOrder?.assetId || '-'}`}
                                    </Typography>
                                    <Typography className={classes.regText}>
                                        {`${workOrder?.descriptor}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography className={classes.regText}>
                                        <div className={classes.indicatorWrapper}>
                                            <div item xs={2}>
                                                <div className={classes.indicator} style={{ background: activityColors[status] }}></div>
                                            </div>
                                            {activityStatusById?.[status]?.name}
                                        </div>
                                    </Typography>
                                    <Authorize profile={permissionProfiles.ACTIVITY.ACTIVITY_EDIT}>
                                        <Typography className={classes.boldText}>
                                            Cost: ${workOrder?.price}
                                        </Typography>
                                    </Authorize>
                                    <Typography className={classes.regText}>
                                        Price: ${workOrder?.revenue}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                        {showActions ? <WorkOrderActions workOrder={workOrder} handleDone={handleDone} stillPending={stillPending} fill={false} />
                            : <Grid item xs={4} className={classes.actionWrapper}>
                                <FlagAsset
                                    selectedRows={[workOrder.id]}
                                    allData={[{ ...workOrder }]}
                                    entityType={FlagEntityTypes.ACTIVITY}
                                    readonly
                                    getFlagFromApi={false}
                                    flagObj={workOrder.latestFlag}
                                    flagOn={workOrder?.latestFlag}
                                />
                            </Grid>}

                    </Grid>
                </Paper>}
            </div>
        </>
    )
}

export default WorkOrderCard;