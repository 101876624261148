import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const userPermissionProfiles = {
    VIEW_CLIENTS: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR, roles.ACCOUNT_MANAGER],
    },
    VIEW_ALL_CLIENTS_LOTS: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR],
    },
    VIEW_LIMITED_CLIENTS_LOTS: {
        [modes.EDIT]: [roles.ACCOUNT_MANAGER],
    },
    VIEW_USERS: {
        [modes.EDIT]: [roles.OWNER],
    },
    CREATE_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER.CREATE, permissions.USER_MANAGEMENT.CREATE]
    },
    MANAGE_PERMISSIONS: {
        [modes.EDIT_PERMISSION]: [permissions.USER.MANAGE_PERMISSIONS],
    },
    USER_RESTORE_PASSWORD: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR],
    }
}