import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Image } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from "@material-ui/icons/Edit";
import * as icons from "icons/documentTypes/";
import React, { useEffect, useState } from "react";
import { arrayToObject } from "utils/object-util";
import CustomInput from "../../../../../src/components/CustomInput";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import BasicModal from '../../../../components/BasicModal';
import ConfirmationPopup from "../../../../components/ConfirmationPopup";
import mobileBreakpoint from "../../../../utils/mobileBreakpoint";
import NotesTooltip from "../../../notes/NotesTooltip";
import Carousel from "./Carrousel";
import { fadeInImageStyle } from "./FadeInImageStyle";
import { Tooltip } from "@material-ui/core";

const FadeInImage = ({
    img, alt, 
    readonly = false, 
    showFileName = false,
    isDocument = false, 
    onDelete, onEdit, 
    imageTypes = null,
    changeImageTypeId = null, 
    existingImages = null,
    showCarousel = false, imagesForCarousel = null, 
    noteType,
    imageGroupFieldName ="createdDate", 
    permissionDeleteImage = permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_DELETE_PHOTO,
    editPermissionProfile = permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_EDIT_ADD_PHOTO,
    documentTypes }) => {
       
    const classes = fadeInImageStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const [loaded, setOnLoaded] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [imageTypeId, setImageTypeId] = useState(img?.imageTypeId || 0);
    let [showingImages, setShowingImages] = useState(false);
    let [imagesToShow, setImagesToShow] = useState([]);
    let [defaultIndexImage, setDefaultIndexImage] = useState(0);
    const [imageGroupSelected, setImageGroupSelected] = useState(null);
    const [docTypeIcon, setDocTypeIcon] = useState();
    const [docTypeToEdit, setDocTypeToEdit] = useState(img?.documentType);
    const [docTypeName, setDocTypeName] = useState();
    
    const documentTypesLookup = arrayToObject(documentTypes);
       
    useEffect(() => {
        if(img?.documentType)
        {
            setDocTypeToEdit(img?.documentType);
            setDocTypeName(documentTypesLookup[img?.documentType]?.name)
            const iconName = documentTypesLookup[img?.documentType]?.icon;
            const iconFunc = icons[iconName];
            const docIcon = iconFunc ? iconFunc() : undefined;
            setDocTypeIcon(docIcon);
        }
    }, [img])

    const onLoad = () => setOnLoaded(true);

    const openModalConfirmDelete = () => {
        setIsEditing(false);
        setOpenPopup(true);
    }

    const openModalConfirmEdit = () => {
        setIsEditing(true);
        setOpenPopup(true);
        setDocTypeToEdit(img?.documentType)
    }

    const onSelectImageType = (index, val) => {
        let imageTypeEntity = imageTypes.find(x => x.id == val);
        let imageEntity = existingImages.find(x => x.id == index);
        let image =
        {
            id: imageEntity?.id,
            url: imageEntity?.url,
            imageTypeId: val,
            fileName: imageTypeEntity?.label
        };
        changeImageTypeId(image);
        setImageTypeId(val);
    }

    const onCancelEvent = () => {
        setOpenPopup(false);
        setDocTypeToEdit();
    }

    const closeShowingPhotos = () => {
        setShowingImages(false);
    }

    const openShowingPhotos = (group) => {
        imagesForCarousel[group]?.forEach((element, index) => {
            if (element.url == img?.url) {
                setDefaultIndexImage(index);
            }
        });
        setImageGroupSelected(group);
        setImagesToShow(imagesForCarousel[group]);
        setShowingImages(true);
    }
    const isDocForIcons = img?.type === 'pdf' || (img?.url ? img?.url?.endsWith("pdf") : img?.fileUrl?.endsWith("pdf"));
    const isDoc = isDocument || isDocForIcons;

    const iconToShow = isDocForIcons ? (docTypeIcon ? <Tooltip title={docTypeName}>{React.cloneElement(docTypeIcon, { className: classes.placeholderIcon })}</Tooltip>
                        : <DescriptionIcon color="secondary" className={classes.placeholderIcon} />) 
                        : !(img?.thumbnail || img?.fileUrl || img?.url) && <Image color="secondary" className={classes.placeholderIcon} />

    return (
        <div className={`${classes.imageContainer} ${isMobile && classes.imageContainerMobile}`}>
            <div className={classes.placeholder}>
                {<IconButton 
                    onClick={() => { openShowingPhotos(img?.[imageGroupFieldName]) }} 
                    className={classes.icon}
                >
                    {iconToShow}
                </IconButton>}
            </div>
            {
                !showCarousel ?
                    <a href={img?.url ?? img?.fileUrl} target="_blank">
                        <img
                            className={`${classes.image} ${loaded ? classes.imageVisible : ""}`}
                            src={img?.thumbnail || img?.fileUrl || img?.url}
                            alt={alt}
                            onLoad={onLoad}
                        />
                        {(showFileName || (img?.fileName && img?.url.endsWith("pdf"))) &&
                            <div className={classes.fileName}>{img?.fileName}</div>
                        }
                    </a>
                    : <div onClick={() => { openShowingPhotos(img?.[imageGroupFieldName]) }}>
                        <img
                            className={`${classes.image} ${loaded ? classes.imageVisible : ""}`}
                            src={img?.thumbnail || img?.fileUrl || img?.url}
                            alt={alt}
                            onLoad={onLoad}
                        />
                        {(showFileName || (img?.fileName && img?.url.endsWith("pdf"))) &&
                            <div className={classes.fileName}>{img?.fileName}</div>
                        }
                    </div>
            }
            {(img?.id && !readonly) && //!isDoc
                <div className={classes.buttomsContainer}>
                    {onDelete &&
                        <Authorize profile={permissionDeleteImage}>
                            <IconButton
                                title={'Delete image'}
                                edge='end'
                                aria-label='delete'
                                className={{ ...classes.imageDeleteIcon }}
                                onClick={openModalConfirmDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Authorize>
                    }
                    {onEdit &&
                        <Authorize profile={editPermissionProfile}>
                            <IconButton
                                title={isDoc ? 'Edit Document Type' : 'Edit image'}
                                edge='end'
                                aria-label='edit'
                                className={classes.imageDeleteIcon}
                                onClick={openModalConfirmEdit}>
                                <EditIcon />
                            </IconButton>
                        </Authorize>
                    }
                    {noteType && <Authorize profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_EDIT_ADD_NOTE_PHOTO}>
                        <NotesTooltip entityId={img?.id} entityType={noteType} />
                    </Authorize>}
                </div>
            }
            <ConfirmationPopup
                title={!isEditing ? 'Delete Image' : isDoc ? "Edit Document Type" : "Edit Image"}
                onCancel={() => onCancelEvent()}
                onConfirm={async () => {
                    setProcessing(true);
                    if (!isEditing) {
                        await onDelete(img, isDoc);
                    }
                    else if (isEditing && !isDoc) {
                        onEdit && (await onEdit(img, isDoc));
                    }
                    else if (isEditing && isDoc) {
                        onEdit && (await onEdit({ id: img?.id, documentType: docTypeToEdit }));
                    }
                    setProcessing(false);
                    setOpenPopup(false);
                }}
                open={openPopup}
                loading={processing}
            >
                {!isEditing ?
                    <div>
                        <div>{'Are you sure you want to delete this file?'}</div>
                        <div className={classes.imagePreviewWrapper}>
                            <img
                                className={`${classes.image} ${loaded ? classes.imageVisible : ""}`}
                                src={img?.url ?? img?.fileUrl}
                                alt={alt}
                                onLoad={onLoad}
                            />
                        </div>
                    </div>
                    : !isDoc ?
                        <div className={classes.imagePreviewWrapperEditting}>
                            <img
                                className={`${classes.imageEditing} ${loaded ? classes.imageVisible : ""}`}
                                src={img?.url ?? img?.fileUrl}
                                alt={alt}
                                onLoad={onLoad}
                            />
                            {(imageTypes && !img.isExternal) &&
                                <div style={{ marginTop: 10 }}>
                                    <CustomInput
                                        id={imageTypeId}
                                        label="Image type"
                                        value={imageTypeId}
                                        elementType="dropdown"
                                        onChange={val => onSelectImageType(img.id, +val)}
                                        values={imageTypes}
                                        showEmpty={true}
                                    />
                                </div>
                            }
                        </div>
                        : <div>
                            <CustomInput
                                id="documentType"
                                elementType='dropdown'
                                value={docTypeToEdit}
                                values={documentTypes}
                                onChange={(val) => setDocTypeToEdit(+val)}
                                showEmpty
                            />
                        </div>
                }
            </ConfirmationPopup>

            <BasicModal
                open={showingImages}
                header={`${imageGroupSelected}`}
                wide={true}
                handleClose={closeShowingPhotos}
                component={<Carousel
                    images={imagesToShow}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    imageTypes={imageTypes}
                    changeImageTypeId={changeImageTypeId}
                    existingImages={existingImages}
                    defaultValue={defaultIndexImage}
                    noteType={noteType}
                />}
            />
        </div>
    );
};

export default FadeInImage;
