export const workOrderStatuses = {
    RequiredAttention: 1,
    PendingApproval: 2,
}

export const workOrderStatusList = {
    [workOrderStatuses.RequiredAttention]: {
        id: workOrderStatuses.RequiredAttention,
        name: 'Flagged',
        color: '#db6f85'
    },
    [workOrderStatuses.PendingApproval]: {
        id: workOrderStatuses.PendingApproval,
        name: 'Pending Approval',
        color: '#ebb6c1'
    },
};