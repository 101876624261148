import { Grid, Paper } from "@material-ui/core";
import DropdownFilter from "components/DropdownFilter";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormatService from "services/formatService.js";
import { weeklyStorageHeaderColumns } from "../../storageInvoiceConsts.js";
import { setClient, setFilters, useGetStorageInvoicesListQuery } from "../../storageInvoiceSlice.ts";
import { storageHeaderStyles } from "./style";

export const WeeklyStorageInvoiceHeader = ({ invoiceCycleType }) => {
    const classes = storageHeaderStyles();
    const { filters, searchVal } = useSelector(state => state.storageInvoiceState);

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: invoices, error: invoicesError, isLoading: isLoadingInvoices } = useGetStorageInvoicesListQuery({ clientId: filters?.['clientId'] }, { skip: !filters?.['clientId'] });

    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const startDateParam = params.get('startDate');
    const endDateParam = params.get('endDate');
    const weekList = invoices?.map(w => {
        const startDate = FormatService.formatDateNoConversion(w.cycleStart);
        const endDate = FormatService.formatDateNoConversion(w.cycleEnd);
        var value = `${startDate} - ${endDate}`;
        return {
            name: value,
            id: value,
            startDate,
            endDate
        }
    });// getLastSixMonthsWeeks()?.map(w => ({ ...w, name: `${w.startDate} - ${w.endDate}`, id: `${w.startDate} - ${w.endDate}` }))

    const handleChange = (prop, filterValue) => {
        if (prop === "clientId") {
            dispatch(setClient(filterValue));
        } else if (prop === "cycle") {
            if (filterValue) {
                const v = weekList?.find(w => w.id == filterValue);
                dispatch(setFilters({ ...filters, cycle: filterValue, startDate: v?.startDate, endDate: v?.endDate }));
            }
        } else {
            dispatch(setFilters({ ...filters, ...{ [prop]: filterValue } }));
        }
    }

    const filterConfig = [
        {
            key: 'clientId',
            title: 'Client',
            values: clients,
            value: filters?.['clientId']
        }

    ]

    filterConfig.push(
        {
            key: 'cycle',
            title: 'Week',
            values: weekList,
            value: filters?.['cycle']
        }
    )


    useEffect(() => {
        if (endDateParam && startDateParam && filters?.startDate == '' && filters?.endDate == '') {
            dispatch(setFilters({
                ...filters,
                ...{
                    cycle: `${startDateParam} - ${endDateParam}`,
                    startDate: startDateParam,
                    endDate: endDateParam
                }
            }));
        }
    }, [params?.clientId, startDateParam, endDateParam]);

    return (
        <Paper className={classes.wrapper}>
            <Grid container spacing={2}>
                {filterConfig.map(filter =>
                    <Grid item xs={6} key={filter.key}>
                        <DropdownFilter
                            title={filter?.title}
                            showEmpty
                            values={filter.values}
                            onSelect={(selected) => handleChange(filter.key, selected)}
                            value={filter?.value}
                        />
                    </Grid>)}
            </Grid>

            {<Grid container >
                {weeklyStorageHeaderColumns?.map((col, index) =>
                    <Grid className={classes.column} xs={col.size} item key={`col_${index}`}>
                        {col?.label}
                    </Grid>)}
            </Grid>}
        </Paper>
    )
}
