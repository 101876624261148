import { Button, Grid, Paper, Tooltip } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import { useState } from "react";
import { StorageInvoiceStatus, weeklyStorageHeaderColumns } from "../../storageInvoiceConsts";
import StorageInvoiceDetailsItemized from "./StorageInvoiceDetailsItemized";
import { storageInvoiceItemizedStyles } from "./style";

const StorageInvoiceItemized = ({ invoice }) => {
    const classes = storageInvoiceItemizedStyles();
    const [expanded, setExpanded] = useState(false);

    return (
        <Paper className={classes.wrapper}>
            <Grid className={classes.title} container spacing={1} alignItems='center' >
                {weeklyStorageHeaderColumns?.map((col, index) =>
                    <Grid className={`${classes.column}`} xs={col.size} item key={`${invoice.id}_${index}_col.key`}>
                        {col?.value ? col.value(invoice) : invoice?.[col.key]}
                    </Grid>
                )}

                {invoice.status === StorageInvoiceStatus.FAILED && <Grid className={classes.column} xs={1} item container key={`failed_message`} justifyContent='flex-end'>
                    <>
                        <Tooltip title="We failed to send this invoice to QuickBooks. This might be due to a missing mapping in Parq. Please contact support.">
                            <div className={classes.warning}><WarningIcon /></div>
                        </Tooltip>
                    </>
                </Grid>}


                <Grid className={classes.column} xs={1} item container key={`col_actions`} justifyContent='flex-end'>
                    <Button
                        color='primary'
                        onClick={() => setExpanded(!expanded)}
                        endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        {expanded ? 'Less' : 'More'}
                    </Button>
                </Grid>
            </Grid>
            {<StorageInvoiceDetailsItemized invoice={invoice} expanded={expanded} />}
        </Paper>
    );
};

export default StorageInvoiceItemized;
