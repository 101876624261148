import { makeStyles } from "@material-ui/core"

export const VehicleDocumentsStyle = makeStyles(theme => ({
    icon: {
        cursor: 'pointer',
        padding: '1em',
        '& svg': {
            width: '90px',
            height: '90px'
        }
    },
    deleteIcon: {
        '& svg': {
            width: '15px',
            height: '15px'
        }
    },
    hoverContainer: {
        position: 'relative',
        '&:hover $actions': {
            opacity: 1,
        },
    },
    actions: {
        opacity: 0,
        transition: 'opacity 0.3s ease',
    },
    documentTypes:{
        marginBottom: '1em'
    },
    title: {
        margin: 0,
        padding: '3em 0 1em',
        fontWeight: 500,
        fontSize: '15px',
        marginRight: "8px",
        color: theme.typography.color
    }
}));
