import { Grid, Paper } from '@material-ui/core';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DataSearchBar from 'components/DataSearchBar';
import TableFilters from 'components/DataTable/TableFilters';
import { ExpandFiltersPanel } from 'components/DataTable/TableFilters/ExpandFiltersPanel';
import { SelectedFilters } from 'components/DataTable/TableFilters/SelectedFilters/SelectedFilters';
import ActivityListConfirm from './ActivityListConfirm';
import { activityTableStyle } from './style';

const ActivityListConfirmContainer = ({ activities, title, onDropFilter, onResetFilters, filters, filterConfig, setSearch, searchVal }) => {
    const classes = activityTableStyle()

    return (
        <Authorize profile={permissionProfiles.ACTIVITY.APPROVE_PENDING_CLIENT}>
            <div className={classes.listContainer}>
                <TableFilters
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    onResetFilters={onResetFilters}
                />
                <div className={classes.listWrapper}>
                    <Paper className={classes.header}>
                        <Grid container direction="row" alignItems="center" justifyContent="space-between">
                            <Grid item
                                className={classes.titleContainer}
                                alignItems="center">
                                <ExpandFiltersPanel filtersState={filters} />
                                <Grid item>
                                    <h3>{title ?? ''}</h3>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DataSearchBar
                                onSearch={setSearch}
                                searchVal={searchVal}
                            />
                        </Grid>
                        <SelectedFilters
                            filters={filters}
                            filterConfig={filterConfig}
                            onClearFilter={(filterKey) => onDropFilter(null, filterKey)}
                        />
                    </Paper>

                    <div className={classes.activityListConfirmWrapper}>
                        <Grid container spacing={2} className={classes.activityListConfirm}>
                            <ActivityListConfirm activities={activities} />
                        </Grid>
                    </div>
                </div>
            </div>
        </Authorize>
    )
}

export default ActivityListConfirmContainer;