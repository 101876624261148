import * as React from "react";

function IconBOL(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M15.7485 18.6625H7.74854C7.33854 18.6625 6.99854 18.3225 6.99854 17.9125C6.99854 17.5025 7.33854 17.1625 7.74854 17.1625H15.7485C16.1585 17.1625 16.4985 17.5025 16.4985 17.9125C16.4985 18.3225 16.1585 18.6625 15.7485 18.6625Z" fill="#0090FE"/>
            <path d="M10.3985 8.7025H13.0985C13.6485 8.7025 14.0785 8.8625 14.3585 9.1525C14.6385 9.4325 14.8085 9.8625 14.8085 10.4125V13.1025C14.8085 13.6525 14.6485 14.0825 14.3585 14.3625C14.0785 14.6425 13.6485 14.8125 13.0985 14.8125H10.4085C9.85853 14.8125 9.42853 14.6525 9.14853 14.3625C8.86853 14.0825 8.69853 13.6525 8.69853 13.1025V10.4025C8.69853 9.8525 8.85853 9.4225 9.14853 9.1425C9.42853 8.8625 9.85853 8.6925 10.4085 8.6925L10.3985 8.7025ZM11.4685 13.9725C11.5385 14.0425 11.6485 14.0925 11.7485 14.0925C11.8485 14.0925 11.9585 14.0525 12.0285 13.9725L13.4085 12.5925C13.4885 12.5125 13.5285 12.4125 13.5285 12.3125C13.5285 12.2125 13.4885 12.1125 13.4085 12.0325C13.2485 11.8725 12.9985 11.8725 12.8385 12.0325L12.1485 12.7225V9.8325C12.1485 9.6125 11.9685 9.4325 11.7485 9.4325C11.5285 9.4325 11.3485 9.6125 11.3485 9.8325V12.7225L10.6585 12.0325C10.4985 11.8725 10.2485 11.8725 10.0885 12.0325C9.92854 12.1925 9.92854 12.4425 10.0885 12.6025L11.4685 13.9825V13.9725Z" fill="#0090FE"/>
            <path d="M7.75854 22.5125C5.13854 22.5125 2.99854 20.3825 2.99854 17.7525V5.7725C2.99854 3.6725 4.33854 1.8525 6.33854 1.2325C6.53854 1.1725 6.75853 1.1925 6.94853 1.3025L9.04854 2.5625L11.3485 1.1125C11.5885 0.9625 11.8985 0.9625 12.1485 1.1125L14.4485 2.5625L16.5685 1.3025C16.7485 1.1925 16.9785 1.1725 17.1785 1.2325C19.1685 1.8525 20.5085 3.6725 20.5085 5.7725V17.7525C20.5085 20.3725 18.3685 22.5125 15.7485 22.5125H7.75854ZM6.47854 2.7825C5.27854 3.2825 4.49854 4.4425 4.49854 5.7725V17.7525C4.49854 19.5525 5.95854 21.0125 7.75854 21.0125H15.7385C17.5385 21.0125 18.9985 19.5525 18.9985 17.7525V5.7725C18.9985 4.4525 18.2185 3.2825 17.0285 2.7825L14.8285 4.1025C14.5885 4.2525 14.2785 4.2425 14.0485 4.1025L11.7585 2.6625L9.46854 4.1025C9.22854 4.2525 8.92853 4.2525 8.68853 4.1025L6.48853 2.7825H6.47854Z" fill="#0090FE"/>
        </svg>
    )
}

export default IconBOL;