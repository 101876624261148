import * as React from "react";

function IconACHAutomatedCleaning(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M17.5799 22H6.41714C3.97993 22 1.99854 20.0186 1.99854 17.5814V6.4186C1.99854 3.9814 3.97993 2 6.41714 2H17.5799C20.0171 2 21.9985 3.9814 21.9985 6.4186V17.5814C21.9985 20.0186 20.0171 22 17.5799 22ZM6.41714 3.39535C4.75202 3.39535 3.39388 4.75349 3.39388 6.4186V17.5814C3.39388 19.2465 4.75202 20.6047 6.41714 20.6047H17.5799C19.245 20.6047 20.6032 19.2465 20.6032 17.5814V6.4186C20.6032 4.75349 19.245 3.39535 17.5799 3.39535H6.41714Z" fill="#0090FE"/>
            <path d="M18.2869 16.3628H5.71016C5.32877 16.3628 5.01249 16.0465 5.01249 15.6651V8.33488C5.01249 7.95349 5.32877 7.63721 5.71016 7.63721H18.2776C18.659 7.63721 18.9753 7.95349 18.9753 8.33488V15.6651C18.9753 16.0465 18.659 16.3628 18.2776 16.3628H18.2869ZM6.40784 14.9674H17.5799V9.03256H6.40784V14.9674Z" fill="#0092FF"/>
            <path d="M17.5799 12.6698H6.40784V14H17.5799V12.6698Z" fill="#0092FF"/>
        </svg>
    )
}

export default IconACHAutomatedCleaning;
