import * as React from "react";

function IconBillOfSale(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M15.7485 18.6625H7.74854C7.33854 18.6625 6.99854 18.3225 6.99854 17.9125C6.99854 17.5025 7.33854 17.1625 7.74854 17.1625H15.7485C16.1585 17.1625 16.4985 17.5025 16.4985 17.9125C16.4985 18.3225 16.1585 18.6625 15.7485 18.6625Z" fill="#0090FE"/>
            <path d="M11.1285 14.7025C10.1685 14.7025 9.38854 13.9325 9.38854 12.9825C9.38854 12.7025 9.60854 12.4825 9.88854 12.4825C10.1685 12.4825 10.3885 12.7025 10.3885 12.9825C10.3885 13.3825 10.7185 13.7025 11.1285 13.7025H12.5285C12.8485 13.7025 13.1085 13.4525 13.1085 13.1425C13.1085 12.7925 13.0185 12.7025 12.7185 12.6125L10.4785 11.8825C10.2085 11.7925 9.38854 11.5325 9.38854 10.3925C9.38854 9.5325 10.0985 8.8325 10.9685 8.8325H12.3685C13.3285 8.8325 14.1085 9.6025 14.1085 10.5525C14.1085 10.8325 13.8885 11.0525 13.6085 11.0525C13.3285 11.0525 13.1085 10.8325 13.1085 10.5525C13.1085 10.1525 12.7785 9.8325 12.3685 9.8325H10.9685C10.6485 9.8325 10.3885 10.0825 10.3885 10.3925C10.3885 10.7425 10.4785 10.8225 10.7885 10.9225L13.0285 11.6525C13.4885 11.8025 14.1185 12.1125 14.1185 13.1425C14.1185 14.0025 13.4085 14.7025 12.5385 14.7025H11.1285Z" fill="#0090FE"/>
            <path d="M11.7385 15.4025C11.4585 15.4025 11.2385 15.1825 11.2385 14.9025V8.6225C11.2385 8.3425 11.4585 8.1225 11.7385 8.1225C12.0185 8.1225 12.2385 8.3425 12.2385 8.6225V14.9025C12.2385 15.1825 12.0185 15.4025 11.7385 15.4025Z" fill="#0090FE"/>
            <path d="M7.75854 22.5125C5.13854 22.5125 2.99854 20.3825 2.99854 17.7525V5.7725C2.99854 3.6725 4.33854 1.8525 6.33854 1.2325C6.53854 1.1725 6.75853 1.1925 6.94853 1.3025L9.04854 2.5625L11.3485 1.1125C11.5885 0.9625 11.8985 0.9625 12.1485 1.1125L14.4485 2.5625L16.5685 1.3025C16.7485 1.1925 16.9785 1.1725 17.1785 1.2325C19.1685 1.8525 20.5085 3.6725 20.5085 5.7725V17.7525C20.5085 20.3725 18.3685 22.5125 15.7485 22.5125H7.75854ZM6.47854 2.7825C5.27854 3.2825 4.49854 4.4425 4.49854 5.7725V17.7525C4.49854 19.5525 5.95854 21.0125 7.75854 21.0125H15.7385C17.5385 21.0125 18.9985 19.5525 18.9985 17.7525V5.7725C18.9985 4.4525 18.2185 3.2825 17.0285 2.7825L14.8285 4.1025C14.5885 4.2525 14.2885 4.2425 14.0485 4.1025L11.7585 2.6625L9.46854 4.1025C9.22854 4.2525 8.92853 4.2525 8.68853 4.1025L6.48853 2.7825H6.47854Z" fill="#0090FE"/>
        </svg>
    )
}

export default IconBillOfSale;