import { modes } from "../modes";
import { roles } from "../roles";

export const transporterPermissionProfiles = {
    TRANSPORTER_VIEW_LANES: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR, roles.ACCOUNT_MANAGER]
    },
    TRANSPORTER_EDIT: {
        [modes.EDIT]: [roles.OWNER, roles.SUPERVISOR, roles.ACCOUNT_MANAGER]
    },
}