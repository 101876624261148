import { Badge, CircularProgress, Grid, IconButton, Tooltip as MuiTooltip, Popover } from "@material-ui/core";
import { widgetTitleStyle } from "./style";
import { IconFilters } from "icons";
import { useState } from "react";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';


const WidgetTitle = ({ title, info, filtersList = [], filters = null, loading = false }) => {
    const classes = widgetTitleStyle();
    const [anchorEl, setAnchorEl] = useState();
    const [showFilters, setShowFilters] = useState(false)

    const openFilters = (e) => {
        setAnchorEl(e.currentTarget);
        setShowFilters(true);
    }

    const closeFilters = (e) => {
        setAnchorEl();
        setShowFilters(false);
    }

    return <>
        <Grid container className={classes.header}>
            {loading &&
                <CircularProgress
                    size='1em'
                    style={{ position: 'absolute' }}
                />}
            <Grid className={classes.widgetTitle} item xs={10}>
                {title}
            </Grid>
            <Grid  item xs={1}>
                {info && <MuiTooltip title={info}>
                    <InfoOutlinedIcon />
                </MuiTooltip>}
            </Grid>
            {filters && <Grid item xs={1}>
                <Badge
                    className={classes.openFiltersBadge} badgeContent={Object.values(filtersList ?? {})?.filter(f => f?.value !== null && f?.value?.length !== 0)?.length ?? 0} color="error"
                    classes={{ badge: classes.badge }}>
                    <MuiTooltip title={showFilters ? 'Close filters' : 'Show filters'}>
                        <IconButton size="small" className={classes.filterIcon} onClick={openFilters}> <IconFilters /></IconButton>
                    </MuiTooltip>
                </Badge>
            </Grid>}
        <Popover
            open={showFilters}
            onClose={closeFilters}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            disableScrollLock
            disablePortal
            keepMounted
        >
            <div className={classes.filters}>{filters}</div>
        </Popover>
        </Grid>
    </>
}
export default WidgetTitle;