import { Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import usePrompt from 'hooks/usePrompt.js';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StorageInvoiceStatus, storageInvoiceTypes } from '../../storageInvoiceConsts';
import { useUpdateStorageInvoiceMutation } from '../../storageInvoiceSlice.ts';
import DatePicker from "components/DatePicker";
import moment from 'moment';
import NotificationProvider from 'components/NotificationProvider';
import FadeLoader from "react-spinners/FadeLoader";
import { theme } from 'styles/theme';

const StorageInvoiceReGenerate = ({
    invoiceData,
    invoiceCycleType,
}) => {
    const { triggerPrompt } = usePrompt();
    const { filters } = useSelector(state => state.storageInvoiceState);
    const [openDialog, setOpenDialog] = useState(false);

    const [startDate, setStartDate] = useState(filters?.['startDate']);
    const [endDate, setEndDate] = useState(filters?.['endDate']);

    const [updateStorageInvoice, { isLoading }] = useUpdateStorageInvoiceMutation();

    const onSubmitRegenerateData = async () => {
        let option = {
            clientId: parseInt(filters?.['clientId']),
            month: parseInt(filters?.['month']),
            year: parseInt(filters?.['year']),
            startDate: invoiceCycleType == storageInvoiceTypes.WEEKLY ? startDate : null,
            endDate: invoiceCycleType == storageInvoiceTypes.WEEKLY ? endDate : null,
            invoiceCycleType: invoiceCycleType
        }
        const res = await updateStorageInvoice(option);
        if (res?.data?.error) {
            NotificationProvider.error("Failed to generate the invoice");
        } else {
            NotificationProvider.success("The invoice was generated successfully");
        }

    };

    const onClickGenerate = () => {
        if (invoiceCycleType != storageInvoiceTypes.WEEKLY) {
            onSubmitRegenerateData();
            return;
        }
        setOpenDialog(true);
    }

    return (
        <>
            {(invoiceCycleType == storageInvoiceTypes.WEEKLY || !invoiceData?.id || invoiceData?.status === StorageInvoiceStatus.PENDING) && <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickGenerate}
                    disabled={isLoading || !filters?.['clientId']}>
                    Generate Invoice
                </Button>
            </Grid>}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogContent>
                    <Grid container direction='column' spacing={2}>
                        <Grid item xs={12} >
                            <b>Select Cycle Start and End Date</b>
                        </Grid>
                        <Grid item xs={12} >
                            <DatePicker
                                title='Cycle Start'
                                value={startDate}
                                onSelect={setStartDate}
                                required
                                maxDate={moment(Date.now()).format("YYYY-MM-DD")}
                            // maxDate={moment(new Date(Math.max((new Date()).getTime(), (new Date(endDate ?? null)).getTime()))).format("YYYY-MM-DD")}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <DatePicker
                                title='Cycle End'
                                value={endDate}
                                onSelect={setEndDate}
                                required
                                minDate={moment(startDate).format("YYYY-MM-DD")}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <FadeLoader
                                color={theme.palette.primary.main}
                                loading={isLoading}
                                width={4}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!endDate || !startDate || isLoading}
                        onClick={onSubmitRegenerateData}
                    >
                        {isLoading ? "Generating" : "Generate Invoice"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StorageInvoiceReGenerate;
