export const userPermissions = {
    CREATE: {
        key: 'User.Create',
        name: 'Create users'
    },
    MANAGE_PERMISSIONS: {
        key: 'Permission.ManagePermissions',
        name: 'Manage permissions',
    },
    ARCHIVE_PERMISSIONS: {
        key: 'Permission.ArchivePermissions',
        name: 'Archive permissions',
    },
};
