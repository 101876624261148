import { createSlice } from "@reduxjs/toolkit";
import { baseApi } from "app/baseApi";
import { apiTags } from "app/store/apiTags";

const basePath = 'clientQuickBooksMapping';

export const quickBooksAccountMappingApi = baseApi.injectEndpoints({
    reducerPath: 'quickBooksAccountMappingApi',
    endpoints: (builder) => ({
        getMappings: builder.query({
            query: () => ({
                url: `${basePath}`,
                method: 'GET'
            }),
            providesTags: [apiTags.QB_MAPPING]
        }),
        addClientQuickBooksMapping: builder.mutation({
            query: mapping => ({
                url: `${basePath}`,
                method: "POST",
                body: mapping
            }),
            invalidatesTags: [apiTags.QB_MAPPING]
        }),
        deleteClientQuickBooksMapping: builder.mutation({
            query: id => ({
                url: `${basePath}/${id}`,
                method: "PUT"
            }),
            invalidatesTags: [apiTags.QB_MAPPING]
        }),
        updateClientQuickBooksMapping: builder.mutation({
            query: body => ({
                url: `${basePath}`,
                method: "PUT",
                body: body
            }),
            invalidatesTags: [apiTags.QB_MAPPING]
        })
    })
})

export const { useGetMappingsQuery, 
    useAddClientQuickBooksMappingMutation, 
    useDeleteClientQuickBooksMappingMutation,
    useUpdateClientQuickBooksMappingMutation 
} = quickBooksAccountMappingApi;


export const quickBooksMappingFiltersSlice = createSlice({
    name: 'quickBooksMappingFiltersSlice',
    initialState: {
        filters: {
            'type': { value: '' },
            clientId: { value: null },
            lotId: { value: null }
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        resetFilters: (state, action) => {
            state.filters = {
                'type': { value: '' },
                clientId: { value: null },
                lotId: { value: null }
            }
        }
    }
});

export const { setFilters, resetFilters } = quickBooksMappingFiltersSlice.actions;
export default quickBooksMappingFiltersSlice.reducer;