export const dashboardPermissions = {
    KPI_DASHBOARD_VIEW: {
        key: 'dashboard.view.kpi',
        name: 'View KPI Dashboard'
    },
    KPI_DASHBOARD_VIEW_TAB_INSPECTION: {
        key: 'dashboard.view.kpi.tab.inspection',
        name: 'View KPI Dashboard Tab Inspection'
    },
    KPI_DASHBOARD_VIEW_TAB_OVERVIEW: {
        key: 'dashboard.view.kpi.tab.overview',
        name: 'View KPI Dashboard Tab Overview'
    },
    WASH_DASHBOARD_COMPLIANCE_GRAPH: {
        key: 'dashboard.wash.compliance.graph',
        name: 'Wash Dashboard Compliance Graph'
    },
    MECHANICAL_DASHBOARD_VIEW: {
        key: 'dashboard.view.mechanical',
        name: 'View Mechanical Dashboard'
    },
    MECHANICAL_DASHBOARD_CREATE_WO: {
        key: 'dashboard.view.mechanical.create.wo',
        name: 'Mechanical Dashboard Create Work Order'
    },
    MECHANICAL_DASHBOARD_IMPORT_ACTIVITIES: {
        key: 'dashboard.view.mechanical.import.activities',
        name: 'Mechanical Dashboard Import Activities'
    },
    MECHANICAL_DASHBOARD_CAN_SEE_FLAG: {
        key: 'dashboard.view.mechanical.see.flag',
        name: 'Mechanical Dashboard can see flag'
    },
    MECHANICAL_DASHBOARD_EDIT_FLAG: {
        key: 'dashboard.view.mechanical.edit.flag',
        name: 'Mechanical Dashboard edit flag'
    },
    MECHANICAL_DASHBOARD_VIEW_NOTE: {
        key: 'dashboard.view.mechanical.note',
        name: 'Mechanical Dashboard view note'
    },
    MECHANICAL_DASHBOARD_EDIT_NOTE: {//This permission is not working correctly
        key: 'dashboard.view.mechanical.edit.note',
        name: 'Mechanical Dashboard edit note'
    },
    DISPATCH_DASHBOARD_VIEW: {
        key: 'dashboard.view.dispatch',
        name: 'View Dispatch Dashboard'
    },
    FLEET_MANAGEMENT_DASHBOARD_VIEW: {
        key: 'dashboard.view.fleetManagement',
        name: 'View fleet Management Dashboard'
    },
}