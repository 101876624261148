import { makeStyles } from "@material-ui/core";

export const WorkOrderCardStyle = makeStyles((theme) => ({
    divWrap: {
        minHeight: "100px",
        padding:'1rem',
    },
    card: {
        marginBottom: '1rem',
        paddingLeft: '1rem',
        height: '100%',
        boxShadow: '2px 3px 10px #d7d6d6',
        position: 'relative',
        minWidth: '400px',
    },
    container: {
        alignItems: "stretch !important"
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    textWrapper: {
        padding: '1rem !important'
    },
    lightText: {
        fontSize: 13,
        fontWeight: 350
    },
    regText: {
        fontSize: 13,
        fontWeight: 450
    },
    boldText: {
        fontSize: 16,
        fontWeight: 750
    },
    indicator: {
        height: '0.8em',
        width: '0.8em',
        borderRadius: '50%',
        minWidth: '0.8em',
        cursor: 'pointer',
        marginRight: '0.5em',
    },
    indicatorWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    actionWrapper: {
        textAlign: "center",
        alignItems: "stretch",
        display: 'flex',
        justifyContent: 'flex-end',
        direction: 'row',
        padding: '0 !important'
    },
    actionButton: {
        width: "50%",
        height: "100%",
        maxWidth: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem',
        fontSize: 12,
        cursor: 'pointer',
        transition: 'transform 0.1s ease, box-shadow 0.1s ease',
        '&:hover': {
            boxShadow: theme.shadows[2],
            transform: 'scale(1.03)',
        },
        '&:active': {
            transform: 'scale(0.95)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        },
    },
    rejectButton: {
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
    },
    editButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    editButton: {
        margin: '0 0.5rem 0.5rem',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        minWidth: "95px"
    },
    actionPopup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '300px',
        minHeight: '150px',
        padding: '0.7rem'
    },
    actionIcon: {
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    actionText: {
        padding: '1rem',
        fontWeight: 500
    },
    actionContinue: {
        width: '100%'
    }
}));