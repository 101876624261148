import { modes } from "../modes";
import { permissions } from "../permissions/permissions";

export const conditionReportPermissionProfile = {
    ConditionReport: {
        [modes.EDIT_PERMISSION]: [
            permissions.Condition_Report.COLLECTIBLE,
            permissions.Condition_Report.FREE_ACCESS,
            permissions.VEHICLE.ASSETS_DETAIL_PAGE_DOWNLOAD_CONDITION_REPORT,
            permissions.VEHICLE.ASSETS_DETAIL_PAGE_DOWNLOAD_CONDITION_REPORT_FREE_ACCESS,
        ]
    },
}