export const invoicePermissions = {
    EDIT_TRANSPORTATION_INVOICE: {
        key: 'invoices.edit.transportation',
        name: 'Edit Transportation Invoice'
    },
    APPROVE_DELETE_TRANSPORTATION_INVOICE: {
        key: 'invoices.approve_delete.transportation',
        name: 'Approve and delete Transportation Invoice'
    },
    READ_TRANSPORTATION_INVOICE: {
        key: 'invoices.read.transportation',
        name: 'Read Transportation Invoice'
    },
    READ_STORAGE_INVOICE: {
        key: 'invoices.read.storage',
        name: 'Read Storage Invoice'
    },
    APPROVE_DELETE_MECHANICAL_INVOICE: {
        key: 'invoices.approve_delete.mechanical',
        name: 'Approve and delete Mechanical Invoice'
    },
    READ_MECHANICAL_INVOICE: {
        key: 'invoices.read.mechanical',
        name: 'Read Mechanical Invoice'
    },
    VIEW_QUICKBOOKS_ACCOUNT_MAPPING: {
        key: 'invoices.view.quickbooks.account.mapping',
        name: 'View QuickBooks Account Mapping Page'
    },
    EDIT_QUICKBOOKS_ACCOUNT_MAPPING: {
        key: 'invoices.edit.quickbooks.account.mapping',
        name: 'Edit QuickBooks Account Mapping Page'
    }
};