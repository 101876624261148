import { baseApi } from "app/baseApi";
import { baseQuery } from "app/baseQuery";
import { apiTags } from "app/store/apiTags";

const basePath = 'vehicleAttachments';

export const vehicleAttachmentsApi = baseApi.injectEndpoints({
    reducerPath: 'vehicleAttachmentsApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        uploadDocument: builder.mutation({
            query: document => ({
                url: `${basePath}/uploaddocument`,
                method: "POST",
                body: document
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        deleteDocument: builder.mutation({
            query: document => ({
                url: `${basePath}/deleteDocument`,
                method: "POST",
                body: document
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        updateVehicleDocumentType: builder.mutation({ 
            query: (document) => ({
                url: `${basePath}/documentType`,
                method: 'POST',
                body: document
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        uploadImage: builder.mutation({
            query: image => ({
                url: `${basePath}/image`,
                method: "POST",
                body: image
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        deleteVehicleImage: builder.mutation({
            query: image => ({
                url: `${basePath}/deleteImage`,
                method: "POST",
                body: image
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        updateImage: builder.mutation({
            query: (image) => ({
                url: `${basePath}/editDocument`,
                method: "POST",
                body: image
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        updateImageType: builder.mutation({
            query: (image) => ({
                url: `${basePath}/updateImageType`,
                method: "POST",
                body: image
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
    })
})

export const {
    useUploadDocumentMutation, useDeleteDocumentMutation, useUpdateVehicleDocumentTypeMutation,
    useUploadImageMutation, useDeleteVehicleImageMutation, useUpdateImageMutation, useUpdateImageTypeMutation
} = vehicleAttachmentsApi;