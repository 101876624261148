
import { Button, Dialog, DialogContent } from "@material-ui/core";
import { CheckCircleOutline, ErrorOutlineRounded } from "@material-ui/icons";
import * as React from 'react';
import FadeLoader from "react-spinners/FadeLoader";
import { theme } from "styles/theme";
import { WorkOrderCardStyle } from "./WorkOrderCardStyle";

const ActionPopup = ({ open, handleClose, loading, error, loadingText = 'Loading...', confirmationText = 'Done!', confirmationIcon = <CheckCircleOutline style={{ fill: "#82cc80" }} /> }) => {
    const classes = WorkOrderCardStyle();

    const errorIcon = <ErrorOutlineRounded style={{ fill: '#e15b56' }} />;
    const finalIcon = error ? errorIcon : confirmationIcon;

    return (
        <Dialog open={open} maxWidth={'xs'}>
            <DialogContent className={classes.actionPopup}>
                {loading ?
                    <>
                        <div>
                            <FadeLoader
                                color={theme.palette.primary.main}
                                loading={true}
                                width={4}
                            />
                        </div>
                        <div className={classes.actionText}>
                            {loadingText}
                        </div>
                    </> :
                    <>
                        {open && <>
                            <div className={classes.actionIcon}>
                                {React.cloneElement(finalIcon, {
                                    style: {
                                        ...finalIcon.props.style,
                                        width: "100%",
                                        height: "100%"
                                    }
                                })}
                            </div>
                            <div className={classes.actionText}>
                                {error || confirmationText}
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.actionContinue}
                                onClick={handleClose}
                            >
                                Continue
                            </Button>
                        </>}
                    </>}

            </DialogContent>
        </Dialog>
    )
}
export default ActionPopup;