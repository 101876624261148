import { IconCheck, IconLot, IconSend } from "icons";
import FormatService from "services/formatService"
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { Tooltip } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
import InvoiceStatusDropdown from "../shared/InvoiceStatusDropdown";
import { NoteEntityTypes } from "features/notes/notesConsts";
import { Link } from "react-router-dom";
import { useUpdatePCOInvoiceStatusMutation } from "features/invoice/storage/storageInvoiceSlice.ts";
import NotesTooltip from "features/notes/NotesTooltip";

export const storageHeaderColumns = [
    {
        label: 'Available Lots',
        size: 4,
        key: 'lot',
        value: (invoice) => invoice?.lotInfo
    },
    {
        label: 'Base Days PMF',
        size: 2,
        key: 'daysPMF',
    },
    {
        label: 'Overage Days PMF',
        size: 2,
        key: 'overageDaysPMF'
    },
    {
        label: 'Storage Fee',
        size: 1,
        key: 'lotFee',
        value: (invoice) => `${FormatService.formatCurrency(invoice?.lotFee)}`
    }
]


export const storageDetailsColumns = [
    {
        label: 'Days PMF',
        key: 'daysPMF',
        editable: true,
    },
    {
        label: 'Days On Lot',
        key: 'daysOnLot',
        editable: true,
    },
    {
        label: 'Base Rate',
        key: 'baseRate',
        value: (v) => `${FormatService.formatCurrency(v)}`,
    },
    {
        label: 'Charge',
        key: 'baseFee',
        value: (v) => `${FormatService.formatCurrency(v)}`,
    },
    {
        label: 'Overage Days PMF',
        key: 'overageDaysPMF',
        editable: true,
    },
    {
        label: 'Overage Days on Lot',
        key: 'overageDaysOnLot',
        editable: true
    },
    {
        label: 'Overage Rate',
        key: 'overageRate',
        value: (v) => `${FormatService.formatCurrency(v)}`
    },
    {
        label: 'Overage Charge',
        key: 'overageFeeToClient',
        value: (v) => `${FormatService.formatCurrency(v)}`
    },
    {
        label: 'Lot Fee',
        key: 'lotFee',
        value: (v) => `${FormatService.formatCurrency(v)}`,
        editable: true
    },

]

export const secondLevelData = [
    {
        label: 'Retainer Portion',
        key: 'retainerPortion',
        value: (v) => `${FormatService.formatCurrency(v)}`,
    },
    {
        label: 'Overage Portion',
        key: 'overagePortion',
        value: (v) => `${FormatService.formatCurrency(v)}`,
    },
    {
        label: 'Submarket Portion',
        key: 'subMarketPortion',
        value: (v) => `${FormatService.formatCurrency(v)}`,
    },
    {
        label: 'Tax Rate',
        key: 'taxRate',
        value: (v) => `${parseFloat(v).toFixed(2)} %`,
    },
    // {
    //     label: 'Total',
    //     key: 'lotFeeWithTax',
    //     value: (v) => `${FormatService.formatCurrency(v)}`,
    // },
    {
        label: 'Total',
        key: 'lotFeeWithAllAdditionsAndTaxes',
        value: (v) => `${FormatService.formatCurrency(v)}`,
    },
]

export const StorageInvoiceStatus = {
    PENDING: 1,
    APPROVED: 2,
    SENT_TO_QB: 3,
    FAILED: 4,
    NEEDS_ATTENTION: 5,
    CANCELED: 6,
};

export const StorageInvoiceStatuses = {
    [StorageInvoiceStatus.PENDING]: {
        id: StorageInvoiceStatus.PENDING,
        name: 'Pending',
        color: '#f39a59',
        icon: <HourglassEmptyIcon style={{ fill: 'white' }} />
    },
    [StorageInvoiceStatus.APPROVED]: {
        id: StorageInvoiceStatus.APPROVED,
        name: 'Approved',
        color: '#90C534',
        icon: <IconCheck />
    },
    [StorageInvoiceStatus.SENT_TO_QB]: {
        id: StorageInvoiceStatus.SENT_TO_QB,
        name: 'Sent To QB',
        color: '#0DBF45',
        icon: <IconSend />
    },
    [StorageInvoiceStatus.FAILED]: {
        id: StorageInvoiceStatus.FAILED,
        name: 'Failed',
        color: '#ed782f',
        icon: <IconSend />,
        warningComponent: <Tooltip title="Not all invoices were sent to QB" ><WarningIcon style={{ fill: '#ff6f00' }} /></Tooltip>
    },
    [StorageInvoiceStatus.NEEDS_ATTENTION]: {
        id: StorageInvoiceStatus.NEEDS_ATTENTION,
        name: 'Needs Attention',
        color: '#FFB533',
        icon: <IconSend />,
    },
    [StorageInvoiceStatus.CANCELED]: {
        id: StorageInvoiceStatus.CANCELED,
        name: 'Canceled',
        color: 'red',
        icon: <IconSend />,
    }
}

export const StorageInvoiceStatusesList = Object.values(StorageInvoiceStatuses)


export const calculateStorageInvoiceBy = [
    { id: 1, name: "Days on lot" },
    { id: 2, name: "PMF Days" }
];

export const storageInvoiceTypes = {
    NONE: 1,
    MONTHLY: 2,
    PER_CHECKOUT: 3,
    WEEKLY: 4
};

export const storageInvoiceTypesList = [
    { id: storageInvoiceTypes.NONE, name: "None" },
    { id: storageInvoiceTypes.MONTHLY, name: "Monthly" },
    { id: storageInvoiceTypes.PER_CHECKOUT, name: "Per checkout" },
    { id: storageInvoiceTypes.WEEKLY, name: "Weekly" }
];

export const PCOInvoiceColumns = [
    {
        name: 'Customer',
        key: 'customerName',
        editable: false
    },
    { name: "Notes", editable: false, width: 70, key: "id", component: (row) => <NotesTooltip hideViewByClient={true} entityId={row?.storageInvoiceId} entityType={NoteEntityTypes.STORAGEINVOICES} /> },
    {
        name: 'Status',
        key: 'status',
        editable: false,
        exportKey: 'statusName',
        width: 150,
        component: (row) => <InvoiceStatusDropdown
            invoiceId={row?.storageInvoiceId}
            filled={false}
            showConfirmationFor={StorageInvoiceStatus.CANCELED}
            noteEntityType={NoteEntityTypes.STORAGEINVOICES}
            invoice={row}
            confirmationTitle={"Cancel"}
            invoiceStatusList={StorageInvoiceStatusesList}
            updateMutation={useUpdatePCOInvoiceStatusMutation} />
    },
    {
        name: 'Invoice Date',
        key: 'cycleEnd',
        editable: true,
        component: (row) => <>{FormatService.formatToDatePicker(row?.cycleEnd)}</>
    },
    {
        name: 'Lot',
        key: 'lotName',
        editable: false,
        component: (row) => <><IconLot height='16' width='16' fill='#666666' /><div style={{ padding: '0 0 0 0.4rem' }}>{row?.lotName}</div></>
    },
    {
        name: 'VIN',
        key: 'vin',
        editable: false,
        width: 200,
        component: (row) => <Link disabled={!row?.vehicleId} to={`/assets/detail/${row?.vehicleId}`} target="_blank">{row?.vin}</Link>
    },
    {
        name: 'Mail was sent',
        key: 'emailWasSend',
        editable: false,
        width: 90,
        component: (row) => (
            row.emailWasSend ? <span>Yes</span> : <span>No</span>
        )
    },
    {
        name: 'Asset Id',
        key: 'assetId',
        editable: false,
        width: 200,
        component: (row) => <Link disabled={!row?.assetId} to={`/assets/detail/${row?.vehicleId}`} target="_blank">{row?.assetId}</Link>
    },
    {
        name: 'Cycle Start',
        key: 'cycleStart',
        editable: true,
        component: (row) => <>{FormatService.formatToDatePicker(row?.cycleStart)}</>
    },
    {
        name: 'Cycle End',
        key: 'cycleEnd',
        editable: false,
        component: (row) => <>{FormatService.formatToDatePicker(row?.cycleEnd)}</>
    },
    {
        name: 'Billed Days',
        key: 'billedDays',
        width: 100,
        editable: false,
    },
    {
        name: 'Days PMF',
        key: 'daysPmf',
        width: 100,
        editable: false,
    },
    {
        name: 'Days On Lot',
        key: 'daysOnLot',
        width: 100,
        editable: false,
    },
    {
        name: 'Check In Fee',
        key: 'checkInFee',
        width: 100,
        editable: false,
    },
    {
        name: 'Check In Fee Days',
        key: 'checkInFeeDayCount',
        width: 100,
        editable: false,
    },
    {
        name: 'Number of Lines',
        key: 'numberOfLines',
        width: 100,
        editable: false
    },
    {
        name: 'Charge',
        key: 'totalCharge',
        width: 100,
        editable: false,
        component: (row) => `${FormatService.formatCurrency(row?.totalCharge)}`,
    },

]

export const WeeklyInvoiceActivityColumns = [
    {
        name: 'VIN',
        key: 'vin',
        width: 2,
        component: (row) => <Link disabled={!row?.vehicleId} to={`/assets/detail/${row?.vehicleId}`} target="_blank">{row?.vin}</Link>
    },
    {
        name: 'Asset Id',
        key: 'assetId',
        width: 2,
        component: (row) => <Link disabled={!row?.assetId} to={`/assets/detail/${row?.vehicleId}`} target="_blank">{row?.assetId}</Link>
    },
    {
        name: 'Description',
        key: 'description',
        width: 4,
        component: (row) => <>{row?.description}</>
    },
    {
        name: 'Activity Date',
        key: 'activityDate',
        component: (row) => <>{FormatService.formatToDatePicker(row?.activityDate)}</>
    },
    {
        name: 'Charge',
        key: 'totalCharge',
        component: (row) => <>{FormatService.formatCurrency(row?.totalCharge)}</>
    },



]
export const WeeklyInvoiceVehicleColumns = [
    {
        name: 'VIN',
        key: 'vin',
        editable: false,
        width: 2,
        component: (row) => <Link disabled={!row?.vehicleId} to={`/assets/detail/${row?.vehicleId}`} target="_blank">{row?.vin}</Link>
    },
    {
        name: 'Asset Id',
        key: 'assetId',
        width: 2,
        editable: false,
        component: (row) => <Link disabled={!row?.assetId} to={`/assets/detail/${row?.vehicleId}`} target="_blank">{row?.assetId}</Link>
    },
    {
        name: 'Cycle Start',
        key: 'cycleStart',
        editable: true,
        component: (row) => <>{FormatService.formatToDatePicker(row?.cycleStart)}</>
    },
    {
        name: 'Cycle End',
        key: 'cycleEnd',
        editable: false,
        component: (row) => <>{FormatService.formatToDatePicker(row?.cycleEnd)}</>
    },
    {
        name: 'Days PMF',
        key: 'daysPmf',
    },
    {
        name: 'Days On Lot',
        key: 'daysOnLot',
    },
    {
        name: 'Check In Fee',
        key: 'checkInFee',
    },
    {
        name: 'Check In Count',
        key: 'checkInCountInCycle',
    },
    {
        name: 'Check Out Fee',
        key: 'checkOutFee',
    },
    {
        name: 'Check Out Count',
        key: 'checkOutCountInCycle',
    }
];

export const weeklyStorageHeaderColumns = [
    {
        label: 'Lot Name',
        size: 4,
        key: 'lot',
        value: (invoice) => invoice?.lotInfo
    },
    {
        label: 'Base Days PMF',
        size: 1,
        key: 'daysPMF',
    },
    {
        label: 'Base Days',
        size: 1,
        key: 'daysOnLot',
    },
    {
        label: 'Mechanical Work',
        key: 'mechWorkCount',
        size: 2,
        value: (v) => `${v?.storageInvoiceActivities?.length ?? 0}`,
    },
    {
        label: 'Base Storage Rate',
        key: 'baseRate',
        size: 2,
        value: (v) => `${FormatService.formatCurrency(v?.baseRate)}`,
    },
    {
        label: 'Total',
        size: 1,
        key: 'lotFeeWithAllAdditions',
        value: (invoice) => `${FormatService.formatCurrency(invoice?.lotFeeWithAllAdditions)}`
    }
]

export const storageInvoiceServiceTypes = {
    STORAGE: 1,
    ACTIVITY: 2,
};



export const getLastSixMonthsWeeks = () => {
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const weeks = [];
    const today = new Date();

    // Calculate the date 6 months ago
    const sixMonthsAgo = new Date(today);
    sixMonthsAgo.setMonth(today.getMonth() - 6);

    // Adjust to the previous Sunday (or the same day if today is Sunday)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());

    // Loop through each week in the last 6 months
    let currentStartDate = startOfWeek;
    while (currentStartDate >= sixMonthsAgo) {
        const currentEndDate = new Date(currentStartDate);
        currentEndDate.setDate(currentStartDate.getDate() + 6); // Saturday of the current week

        weeks.push({
            startDate: formatDate(currentStartDate),
            endDate: formatDate(currentEndDate),
        });

        // Move to the previous week (subtract 7 days)
        currentStartDate.setDate(currentStartDate.getDate() - 7);
    }

    return weeks;
}