import { createSlice } from "@reduxjs/toolkit";

export const fleetManagementDashboardSlice = createSlice({
    name: 'fleetManagementDashboardSlice',
    initialState: {
        dashboardFilters: {
            clientId: ''
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.dashboardFilters = action.payload;
        },
    },
});

export const { setFilters } = fleetManagementDashboardSlice.actions;
