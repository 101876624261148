import { useHistory } from "react-router-dom";
import DropdownFilter from "components/DropdownFilter";
import { InvoiceTypeRoute, InvoiceTypes } from "../../invoiceConsts.ts";
import { isAuthorized } from "../../../../components/Authorize/authorizeUtil";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";

const InvoiceScreenSelector = ({ selected }) => {
    const history = useHistory();

    const hasReadMechanicalInvoice = isAuthorized(permissionProfiles.INVOICE.READ_MECHANICAL_INVOICE);
    const hasReadStorageInvoice = isAuthorized(permissionProfiles.INVOICE.READ_STORAGE_INVOICE);
    const hasReadTransportationInvoice = isAuthorized(permissionProfiles.INVOICE.READ_TRANSPORTATION_INVOICE);

    const onChange = (selected) => {
        history.push(`${InvoiceTypeRoute[selected]}`);
    }

    const filteredInvoiceTypesList = [
        hasReadTransportationInvoice && { id: InvoiceTypes.TRANSPORTS, name: 'Transports' },
        hasReadStorageInvoice && { id: InvoiceTypes.STORAGE, name: 'Storage' },
        // hasReadMechanicalInvoice && { id: InvoiceTypes.ACTIVITY, name: 'Activity' }
    ].filter(Boolean);

    return (
        <div style={{ paddingTop: '1em', width: '100%' }}>
            <DropdownFilter
                values={filteredInvoiceTypesList}
                value={selected}
                onSelect={onChange}
            />
        </div>
    );
}

export default InvoiceScreenSelector;
