import { makeStyles } from "@material-ui/core";

export const assetsListWidgetStyle = makeStyles((theme) => ({
    searchBar: {
        padding: '1em 0',
    },
    indicator: {
        height: '0.8em',
        width: '0.8em',
        borderRadius: '50%',
        minWidth: '0.8em',
        marginRight: '0.5em',
    },
    indicatorWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        color: theme.palette.primary.main
    },
    details: {
        paddingTop: '0.3rem'
    },
    listWrapper: {
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        "& ul": {
            flex: '1'
        }
    },
    body: {
        margin: '0 1rem'
    },
    graphWrapper: {
        height: '100%',
        minHeight: '20rem'
    },
    filterIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));