export const reportsPermissions = {
    STORAGE: {
        key: 'Report.Storage',
        name: 'View Storage Reports',
    },
    BILLING: {
        key: 'Report.Billing',
        name: 'View Amz Billing Report'
    },
    VIEW_COST_CODES: {
        key: 'Report.ViewCostCodes',
        name: 'View and edit cost codes'
    }
   
}