import React, { useState, useMemo } from 'react';
import { Grid, Select, MenuItem, Tooltip } from '@material-ui/core';
import usersPageStyle from './styles';
import { IconDownload, IconQuestion } from "../../../../icons";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import InactiveUserListDialog from "./inactiveUserListDialog";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";
import HelpIcon from '@material-ui/icons/Help';

const InactiveUsers = ({ users, permission, clients, lots, roles, mapExportData, refetchUsers }) => {
    const classes = usersPageStyle();
    const [dropdownValue, setDropdownValue] = useState(3);

    const filterUsersByLastLoginMonths = useMemo(() => {
        const monthsMapping = {
            1: (diff) => diff >= 1,
            3: (diff) => diff >= 3,
            6: (diff) => diff >= 6,
            12: (diff) => diff >= 12,
        };

        return (users || []).filter(user => {
            const diff = user.lastLoginMonthsDifference;
            return user.isInactiveUser && !user.isPurged && !user.isBlackList && monthsMapping[dropdownValue](diff);
        });
    }, [users, dropdownValue]);

    const totalUsers = filterUsersByLastLoginMonths?.length ?? 0;

    const handleDropdownChange = (event) => {
        setDropdownValue(event.target.value);
    };

    return (
        <Grid className={classes.cardWrapper} container direction="column">
            <div className={classes.actions}>
                <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_INACTIVE_VIEW_ACCESS_TABLE}>
                    <Select
                        labelId="select-label"
                        value={dropdownValue}
                        onChange={handleDropdownChange}>

                        <MenuItem value={1}>1 Month</MenuItem>
                        <MenuItem value={3}>3 Months</MenuItem>
                        <MenuItem value={6}>6 Months</MenuItem>
                        <MenuItem value={12}>12+ Months</MenuItem>
                    </Select>
                    <InactiveUserListDialog
                        permission={permission}
                        clients={clients}
                        lots={lots}
                        roles={roles}
                        user={filterUsersByLastLoginMonths}
                        refetchUsers={refetchUsers}
                    />
                </Authorize>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_INACTIVE_VIEW_ACCESS_EXPORT}>
                    <ExportButton
                        className={classes.exportButton}
                        btnStyle='text'
                        label=""
                        icon={<IconDownload />}
                        fileLabel="Export"
                        data={filterUsersByLastLoginMonths}
                        mapExportData={mapExportData}
                    />
                </Authorize>
            </div>

            <Grid item>

                <div>
                    <Grid item className={classes.count} >
                        {totalUsers}
                    </Grid>
                    <div className={classes.text} >
                        Inactive Users
                        <Tooltip title="A user is inactive if they have not logged into ParQ for the selected number of months">
                            <HelpIcon style={{ height: '0.7em' }} />
                        </Tooltip>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};
export default InactiveUsers;