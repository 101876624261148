function IconOtherConditionReport(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M9.46854 11.52C9.37854 11.52 9.27853 11.5 9.18853 11.46C9.09853 11.43 9.00853 11.37 8.93853 11.3C8.86853 11.23 8.81853 11.15 8.77853 11.06C8.73853 10.97 8.71854 10.87 8.71854 10.77C8.71854 10.67 8.73853 10.58 8.77853 10.49C8.81853 10.39 8.86853 10.31 8.93853 10.24C9.00853 10.17 9.08853 10.12 9.18853 10.08C9.36853 10.01 9.57854 10 9.75854 10.08C9.84854 10.12 9.92854 10.17 9.99854 10.24C10.0685 10.31 10.1285 10.39 10.1685 10.49C10.1985 10.58 10.2185 10.67 10.2185 10.77C10.2185 10.87 10.1985 10.97 10.1685 11.06C10.1285 11.15 10.0685 11.23 9.99854 11.3C9.85854 11.44 9.66854 11.52 9.46854 11.52Z" fill="#0090FE"/>
            <path d="M11.9685 11.52C11.5585 11.52 11.2185 11.18 11.2185 10.77V8.76C11.2185 8.35 11.5585 8.01 11.9685 8.01C12.3785 8.01 12.7185 8.35 12.7185 8.76V10.77C12.7185 11.18 12.3785 11.52 11.9685 11.52Z" fill="#0090FE"/>
            <path d="M14.4685 11.52C14.0585 11.52 13.7185 11.18 13.7185 10.77V6.75C13.7185 6.34 14.0585 6 14.4685 6C14.8785 6 15.2185 6.34 15.2185 6.75V10.77C15.2185 11.18 14.8785 11.52 14.4685 11.52Z" fill="#0090FE"/>
            <path d="M15.7385 22.5H7.75854C5.13854 22.5 2.99854 20.37 2.99854 17.74V5.76C2.99854 3.14 5.13854 1 7.75854 1H15.7385C18.3585 1 20.4985 3.13 20.4985 5.76V17.74C20.4985 20.36 18.3585 22.5 15.7385 22.5ZM7.75854 2.5C5.95854 2.5 4.49854 3.96 4.49854 5.76V17.74C4.49854 19.54 5.95854 21 7.75854 21H15.7385C17.5385 21 18.9985 19.54 18.9985 17.74V5.76C18.9985 3.96 17.5385 2.5 15.7385 2.5H7.75854Z" fill="#0090FE"/>
            <path d="M15.7485 18.65H7.74854C7.33854 18.65 6.99854 18.31 6.99854 17.9C6.99854 17.49 7.33854 17.15 7.74854 17.15H15.7485C16.1585 17.15 16.4985 17.49 16.4985 17.9C16.4985 18.31 16.1585 18.65 15.7485 18.65Z" fill="#0090FE"/>
        </svg>
    )
}

export default IconOtherConditionReport;