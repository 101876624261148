import { makeStyles } from "@material-ui/core";

export const storageInvoiceItemizedStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: '1em',

    },
    collapseWrapper: {
        paddingBottom: '2em'
    },
    title: {
        padding: '1em 2em',
    },
    detailsWrapper: {
        padding: '0.5em 2em',
        '& a': {
            textDecoration: 'none !important',
            color: theme.palette.primary.main
        }
    },
    column: {
        fontWeight: 'bold'
    },
    sectionTitle: {
        fontWeight: 'bold',
        color: theme.palette.secondary.light,
        //borderTop: '1px solid ',
        borderColor: '#ccc',
        //background: '#efefef',
        marginTop: '1em',
        padding: '0.5em 2em',
        //textTransform: 'uppercase'
    }

}));
