
import { Button, Grid, Typography } from "@material-ui/core";
import { CancelOutlined, CheckCircleOutline } from "@material-ui/icons";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { activityStatuses } from "features/activity/activityConsts";
import { useMoveToStatusMutation } from "features/activity/activitySlice";
import { IconEdit } from "icons";
import { useState } from "react";
import FormatService from "services/formatService";
import ActionPopup from './ActionPopup';
import { WorkOrderCardStyle } from "./WorkOrderCardStyle";

const WorkOrderActions = ({ workOrder, handleDone, stillPending = true, fill = true }) => {
    const classes = WorkOrderCardStyle();
    const [updateStatus, { isLoadingUpdate }] = useMoveToStatusMutation();

    const [openApproveAction, setOpenApproveAction] = useState(false);
    const [openRejectAction, setOpenRejectAction] = useState(false);
    const [error, setError] = useState(null);
    const [isLoadingAction, setIsLoadingAction] = useState(false);

    const moveToStatus = async (newStatus) => {
        setIsLoadingAction(true);
        const res = await updateStatus({
            activityLogIds: [workOrder?.id],
            moveToStatus: newStatus
        });
        if (res?.error)
            setError(`Failed to update activity.`);
        else
            setError(null);
        setIsLoadingAction(false);
    }

    const handleAccept = async () => {
        setOpenApproveAction(true);
        await moveToStatus(activityStatuses.PricingApprovedByClient);
    }

    const handleReject = async () => {
        setOpenRejectAction(true);
        await moveToStatus(activityStatuses.Cancelled);
    }

    const handleEdit = async () => {
        await moveToStatus(activityStatuses.PendingClientApproval);
    }

    const handleCloseActionsLoading = () => {
        setOpenApproveAction(false);
        setOpenRejectAction(false);
        setError(null);
        handleDone();
    }

    return (
        <>
            {stillPending ? <Grid item xs={4} className={classes.actionWrapper}>
                <Authorize profile={permissionProfiles.ACTIVITY.APPROVE_PENDING_CLIENT}>
                    <div
                        onClick={handleAccept}
                        className={classes.actionButton}
                        style={{ backgroundColor: `${fill ? "#D4EDDA" : 'white'}`, borderLeft: '1px solid #F3F5F6' }}>
                        <div>
                            <CheckCircleOutline style={{ fill: '#82cc80' }} />
                            Accept
                        </div>
                    </div>
                    <div
                        onClick={handleReject}
                        className={`${classes.actionButton} ${classes.rejectButton}`}
                        style={{ backgroundColor: `${fill ? "#F8D7DA" : 'white'}`, borderLeft: '1px solid #F3F5F6' }}>
                        <div>
                            <CancelOutlined style={{ fill: '#e15b56' }} />
                            Reject
                        </div>
                    </div>
                </Authorize>
            </Grid> :
                <Grid item xs={4} className={classes.editButtonWrapper}>
                    <Typography className={classes.lightText} style={{ padding: '0.5rem' }}>
                        {FormatService.formatDateNoConversion(workOrder?.activityDate)}
                    </Typography>
                    <Button
                        className={classes.editButton}
                        variant="fill"
                        startIcon={<IconEdit />}
                        onClick={handleEdit}>
                        Edit
                    </Button>
                </Grid>}
            <ActionPopup open={openApproveAction} handleClose={handleCloseActionsLoading} loading={isLoadingAction} error={error} loadingText="Approving..." confirmationText={"Work Order Approved!"} />
            <ActionPopup open={openRejectAction} handleClose={handleCloseActionsLoading} loading={isLoadingAction} error={error} loadingText="Rejecting..." confirmationText={"Work Order Rejected!"} confirmationIcon={<CancelOutlined style={{ fill: '#e15b56' }} />} />
        </>
    )
}

export default WorkOrderActions;