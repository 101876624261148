import { modes } from "../modes";
import { roles } from "../roles";

export const tasksPermissionProfile = {
    TASK_VIEW_CLIENT: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN,roles.SUPERVISOR, roles.ACCOUNT_MANAGER, roles.SUPERVISOR]
    },
    TASK_LIST: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR,roles.ACCOUNT_MANAGER, roles.MECHANIC, roles.SUPERVISOR]
    },
    SUPERS_TASK_LIST: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR]
    },
    USERS_TASK_LIST: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR]
    }
}