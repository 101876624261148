import { modes } from "../modes";
import { roles } from "../roles";

export const mechanicalsPermissionProfile = {
    VIEW_CONFIG: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.CLIENT, roles.ADMIN, roles.SUPERVISOR, roles.ACCOUNT_MANAGER]
    },
    SAVE_CONFIG: {
        [modes.EDIT]: [roles.OWNER],
    },
    VIEW_ACTIVITY_DATA: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR, roles.ACCOUNT_MANAGER],
    },
    MANAGE_ACTIVITY_STATUS_ALIASES: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ADMIN, roles.CLIENT, roles.ACCOUNT_MANAGER]
    },
    VIEW_ALIAS_TOOLTIP: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.SUPERVISOR],
    },
    VIEW_ALIAS_NO_TOOLTIP: {
        [modes.EDIT]: [roles.CLIENT],
    },
}