import * as React from "react";
import * as icons from "icons/mechanicals/";

function IconMechanicalWrapper({ activityItem, className }) {
    let name = activityItem?.icon;
    const activityIconFunc = icons[name];
    const activityIcon = activityIconFunc ? activityIconFunc() : <icons.IconActivityDefault />

    return (<span className={className}>
        {activityIcon}
    </span>
    );
}

export default IconMechanicalWrapper;
