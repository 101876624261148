import { Button, Grid } from "@material-ui/core";
import { IconAdd, IconDownload, IconProfile } from "../../../../icons";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import usersPageStyle from "./styles";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";
import CreateUserDialog from "../UserDialogs/CreateUserDialog";

const useStyles = usersPageStyle;

const HeaderUserManagement = ({ users, roles, clients, lots, refetchUsers, mapExportData }) => {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [clickedUser, setClickedUser] = useState(null);
    const history = useHistory();

    const onClickCreate = () => {
        setOpenDialog(true);
        setClickedUser(null);
    }

    return (
        <Grid container justifyContent="space-between">
            <Grid item>
                <span className={classes.headerTitle}>
                    <IconProfile />
                    Users Management
                </span>
            </Grid>
            <Grid item>
                <Grid container spacing={1}>
                    <Authorize profile={permissionProfiles.USER.CREATE_USER}>
                        <Grid item>
                            <Button variant="contained" color="primary" startIcon={<IconAdd />} onClick={onClickCreate}>
                                Create User
                            </Button>
                        </Grid>
                    </Authorize>
                    <Grid item>
                        <ExportButton
                            fileLabel="users"
                            btnStyle="outlined"
                            icon={<IconDownload />}
                            label="Export"
                            data={users}
                            mapExportData={mapExportData}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {openDialog && <CreateUserDialog refetchUsers={refetchUsers} roles={roles} clients={clients} lots={lots} open={openDialog} setOpenDialog={setOpenDialog} user={clickedUser}></CreateUserDialog>}
        </Grid>
    );
};

export default memo(HeaderUserManagement);
