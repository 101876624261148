import { Collapse, Grid } from "@material-ui/core";
import { WeeklyInvoiceActivityColumns, WeeklyInvoiceVehicleColumns } from "../../storageInvoiceConsts";
import { storageInvoiceItemizedStyles } from "./style";

const StorageInvoiceDetailsItemized = ({ invoice, expanded }) => {
    const classes = storageInvoiceItemizedStyles();


    return (
        <>
            <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                <Grid container direction="column">
                    {!!invoice?.storageInvoiceVehicles?.length && <>
                        <Grid item className={classes.sectionTitle} >Storage</Grid>
                        <Grid item container className={classes.detailsWrapper} spacing={2}>
                            {WeeklyInvoiceVehicleColumns?.map((col, index) =>
                                <Grid className={classes.column} xs={col.width ?? 1} item container direction='column' key={`col_${index}`}>
                                    <Grid className={classes.column}>{col?.name}</Grid>
                                </Grid>)}
                        </Grid>
                        {invoice?.storageInvoiceVehicles?.map(v => <Grid item container className={classes.detailsWrapper} spacing={2}>
                            {WeeklyInvoiceVehicleColumns?.map((col, index) =>
                                <Grid className={classes.field} xs={col.width ?? 1} item container direction='column' key={`col_${index}`}>
                                    {col.component ? col.component(v) : v?.[col.key] ?? 0}
                                </Grid>)}

                        </Grid>)}
                    </>}
                    {!!invoice?.storageInvoiceActivities?.length && <>
                        <Grid item className={classes.sectionTitle} >Mechanical Work</Grid>
                        <Grid item container className={classes.detailsWrapper} spacing={2}>
                            {WeeklyInvoiceActivityColumns?.map((col, index) =>
                                <Grid className={classes.column} xs={col.width ?? 1} item container direction='column' key={`col_${index}`}>
                                    <Grid className={classes.column}>{col?.name}</Grid>
                                </Grid>)}
                        </Grid>
                        {invoice?.storageInvoiceActivities?.map(a => <Grid item container className={classes.detailsWrapper} spacing={2}>
                            {WeeklyInvoiceActivityColumns?.map((col, index) =>
                                <Grid className={classes.field} xs={col.width ?? 1} item container direction='column' key={`col_${index}`}>
                                    {col.component ? col.component(a) : a?.[col.key] ?? 0}
                                </Grid>)}

                        </Grid>)}
                    </>}
                </Grid>
            </Collapse>
        </>
    )
}

export default StorageInvoiceDetailsItemized;