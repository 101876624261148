function IconPAVEConditionReport(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M15.7385 22.5H7.75854C5.13854 22.5 2.99854 20.37 2.99854 17.74V5.76C2.99854 3.14 5.13854 1 7.75854 1H15.7385C18.3585 1 20.4985 3.13 20.4985 5.76V17.74C20.4985 20.36 18.3585 22.5 15.7385 22.5ZM7.75854 2.5C5.95854 2.5 4.49854 3.96 4.49854 5.76V17.74C4.49854 19.54 5.95854 21 7.75854 21H15.7385C17.5385 21 18.9985 19.54 18.9985 17.74V5.76C18.9985 3.96 17.5385 2.5 15.7385 2.5H7.75854Z" fill="#0090FE"/>
            <path d="M15.7485 18.65H7.74854C7.33854 18.65 6.99854 18.31 6.99854 17.9C6.99854 17.49 7.33854 17.15 7.74854 17.15H15.7485C16.1585 17.15 16.4985 17.49 16.4985 17.9C16.4985 18.31 16.1585 18.65 15.7485 18.65Z" fill="#0090FE"/>
            <path d="M13.0584 4.91016H8.83836C8.27836 4.91016 7.81836 5.37016 7.81836 5.93016V10.6402H9.21836V9.04016H13.0484C14.1384 9.04016 15.0284 8.15016 15.0284 7.06016V6.89016C15.0284 5.80016 14.1384 4.91016 13.0484 4.91016H13.0584ZM13.4484 7.41016C13.3284 7.53016 13.1684 7.60016 12.9884 7.60016H9.21836V6.29016H12.9884C13.3484 6.29016 13.6484 6.58016 13.6484 6.95016C13.6484 7.13016 13.5784 7.30016 13.4584 7.41016H13.4484Z" fill="#0092FF"/>
            <path d="M15.6784 11.1002H10.3984V12.2602H15.6784V11.1002Z" fill="#0092FF"/>
        </svg>
    )
}

export default IconPAVEConditionReport;