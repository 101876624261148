
function SvgIconReject(props) {
    return (
        <svg width={props?.size ?? 24} height={props?.size ?? 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.34645 15.0064L9.34035 15.0125L9.33447 15.0188C9.30422 15.0513 9.24536 15.08 9.17 15.08C9.10545 15.08 9.04484 15.0577 8.99355 15.0064C8.89882 14.9117 8.89882 14.7483 8.99355 14.6536L10.94 12.7071L11.2929 13.06L9.34645 15.0064ZM10.94 11.2929L8.99355 9.34645C8.89882 9.25171 8.89882 9.08829 8.99355 8.99355C9.08829 8.89882 9.25171 8.89882 9.34645 8.99355L11.2929 10.94L10.94 11.2929ZM12 12.3529L11.6471 12L12 11.6471L12.3529 12L12 12.3529ZM13.06 11.2929L12.7071 10.94L14.6536 8.99355C14.7483 8.89882 14.9117 8.89882 15.0064 8.99355C15.1012 9.08829 15.1012 9.25171 15.0064 9.34645L13.06 11.2929ZM12.7071 13.06L13.06 12.7071L15.0064 14.6536C15.1012 14.7483 15.1012 14.9117 15.0064 15.0064C14.9552 15.0577 14.8945 15.08 14.83 15.08C14.7655 15.08 14.7048 15.0577 14.6536 15.0064L12.7071 13.06ZM12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C17.6539 1.75 22.25 6.34614 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25ZM12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62386 17.3761 2.25 12 2.25Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconReject;
