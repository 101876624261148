import { Grid } from "@material-ui/core";
import WidgetTitle from "../WidgetTitle/WidgetTitle";
import LotsDropdown from "components/SharedDropdowns/LotsDropDown";
import { useGetAllActivityItemsQuery, useGetMechanicalActivitiesQuery } from "features/activity/activitySlice";
import { useState } from "react";
import FilterService, { fieldTypes } from "services/filterService";
import { activitiesStyle } from "./style";
import DateRange from "components/DateRange";
import ActivityWidgetTabs from "./ActivityWidgetTabs";
import { groupBy } from "lodash";
import { arrayToObject } from "utils/object-util";
import ActivitiesCount from "./ActivitiesCount";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useSelector } from "react-redux";
import { IconDatePicker, IconLot } from "icons";

const ActivitiesWidget = () => {
    const classes = activitiesStyle();

    let { data: mechanicalActivities, error: mechanicalActivitiesError, refetch, isFetching: isLoadingMechanicalActivities } = useGetMechanicalActivitiesQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots, refetch: refetchLots } = useGetLotsQuery();
    lots = lots || [];

    const activityItemsLookup = arrayToObject(activityItems)

    const { dashboardFilters } = useSelector(state => state.fleetManagementDashboardState);

    mechanicalActivities = mechanicalActivities?.filter(
        activity => activityItemsLookup?.[activity.activityItemId]?.typeTag != 1 &&
            activityItemsLookup?.[activity.activityItemId]?.typeTag != 6 &&
            (!dashboardFilters?.clientId || dashboardFilters?.clientId == activity?.clientId)
    ) || [];

    const [filters, setFilters] = useState({
        'lotId': { value: [] },
        'activityDate': { value: null }
    })

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    let filterConfig = [
        { type: fieldTypes.ASSOCIATED_LOT, size: 3, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.DATERANGE, size: 3, title: 'Date', key: 'activityDate', values: [] },
    ];

    const filteredData = FilterService.filter(filters, null, mechanicalActivities, filterConfig);

    const dataGrouped = groupBy(filteredData, 'activityItemId');

    const [selectedTab, setSelectedTab] = useState(0);

    const loading = isLoadingMechanicalActivities || isLoadingLots;

    return <Grid container direction="column" className={classes.graphWrapper}>
        <Grid item >
            <WidgetTitle
                title='Activity Counts'
                info={`The top 10 Activities performed for your fleet over the selected time frame. 
                        You can toggle to Pricing to see the top 10 activities performed for your fleet by cost.`}
                loading={loading}
                filtersList={filters}
                filters={
                    <Grid container spacing={1}>
                        <Grid className={classes.filterIcon} item xs={2}>
                            <IconLot />
                        </Grid>
                        <Grid item xs={10}>
                            <LotsDropdown
                                title='Lot'
                                identifier='lot'
                                values={lots}
                                onSelect={(val) => onDropFilter(val, 'lotId')}
                                value={filters?.['lotId']?.value}
                                showEmpty={true}
                                multiple={true}
                            />
                        </Grid>
                        <Grid className={classes.filterIcon} item xs={2}>
                            <IconDatePicker />
                        </Grid>
                        <Grid item xs={10}>
                            <DateRange
                                title="Activity Date"
                                identifier='activityDate'
                                onSelect={(val) => onDropFilter(val, 'activityDate')}
                                dateValue={filters?.['activityDate']?.value}
                                filterButton
                            />
                        </Grid>
                    </Grid>
                } />
        </Grid>

        <Grid item style={{ height: 300, paddingRight: '0.5rem' }}>
            <ActivityWidgetTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <ActivitiesCount activities={dataGrouped} count={selectedTab == 0} />
        </Grid>

    </Grid>
}
export default ActivitiesWidget;