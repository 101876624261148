import { Grid } from "@material-ui/core";
import { workOrderStatuses, workOrderStatusList } from "../consts";
import WorkorderButton from "./WorkorderButton";
import { workorderApprovalStyle } from "../style";

const WorkorderButtons = ({ isPendingApproval, setIsPendingApproval, NACount, PACount }) => {
    const classes = workorderApprovalStyle();

    return <Grid container direction="row" className={classes.filterContainer} spacing={2}>
        <WorkorderButton
            onClick={() => setIsPendingApproval(false)}
            name={workOrderStatusList[workOrderStatuses.RequiredAttention]?.name}
            color={workOrderStatusList[workOrderStatuses.RequiredAttention]?.color}
            active={!isPendingApproval}
            count={NACount}
        />
        <WorkorderButton
            onClick={() => setIsPendingApproval(true)}
            name={workOrderStatusList[workOrderStatuses.PendingApproval]?.name}
            color={workOrderStatusList[workOrderStatuses.PendingApproval]?.color}
            active={isPendingApproval}
            count={PACount}
            activityListDialog={true}
        />
    </Grid>
}
export default WorkorderButtons;