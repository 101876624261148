import { modes } from "../modes";
import { roles } from "../roles";
import { permissions } from "../permissions/permissions";

export const notesPermissionProfiles = {
    EDIT_NOTES: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.MECHANIC, roles.SUPERVISOR],
    },
    DELETE: {
        [modes.EDIT_PERMISSION]: [permissions.NOTES.DELETE]
    },
    VIEW_BY_CLIENT: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER,roles.SUPERVISOR]
    }
}