import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    activityText: {
        marginLeft: '0.5rem'
    },
    ectivityTextHeader: {
        fontWeight: 600
    },
    dialogWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    dialogTitle: {
        fontSize: '1.1rem',
        marginRight: '3rem'
    },
    downloadButton: {
        padding: '0.7rem',
        marginLeft: '27em',
        marginTop: '0.2rem'
    },
    activityIdLists: {
        maxHeight:'350px',
        overflow:'auto',
        marginBottom:'10px',
        gap: '.5em',
        flexWrap: 'nowrap'
    },
    dialog: {
        maxWidth: '100%',
        width: 2000
    },
    directions: {
        fontWeight: 'bold',
        paddingLeft: '.5em'
    },
    box: {
        background: theme.palette.neutral.light,
        border: '1px solid lightGrey',
        borderRadius: '3px',
        padding: '5px',
        overflow:'auto'
    }
}));
