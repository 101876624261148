import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
export const activityListTabsList = {
    Count: 0,
    Pricing: 1

}
export const activityListTabs = [
    {
        key: activityListTabsList.Count,
        label: 'Count',
    },
    {
        key: activityListTabsList.Pricing,
        label: 'Pricing',
    },
];

const ActivityWidgetTabs = ({ selectedTab, setSelectedTab }) => {

    return (
        <TabsWrapper tabs={activityListTabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
    )
}

export default ActivityWidgetTabs;
