import { makeStyles } from "@material-ui/core";

export const mechanicalConfigStyles = makeStyles(theme => ({
    lotWrapper: {
        padding: '1em',
        cursor: 'pointer'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: "rgb(212, 236, 255)",
        borderRadius: "50%",
        maxWidth: "fit-content",
        padding: "5px",
        margin: "0 1rem"
    },
    lotName: {
        fontWeight: 'bold',
        fontSize: '1.1em',
        color: theme.palette.secondary.light
    },
    inputField: {
        width: 260,
        padding: '0.5em',
        paddingLeft: "0em"
    },
    inputLabel:{
        padding: '0.5rem 0 0 0.3rem'
    },
    marginWrapper: {
        marginTop: '.4em',
        padding: '.5em'
    },
    paper: {
        margin: '1.5rem 0 1rem',
        padding: '1rem'
    },
    title: {
        margin: '1rem 0 0 1rem',
        fontWeight: 450,
        paddingTop: "5px",
        color: theme.palette.primary.main,
        fontSize: '1.2em',
    },
    addButton: {
        display: "flex",
        justifyContent: "end",
        padding: '0.5rem 0 0 0'
    },
    selectServiceProviders: {
        padding: 20,
        width: '500px'
    },
    multiSelect: {
        width: '250px',
        flexWrap: 'wrap',
        display: 'flex',
    },
    selectActionButtons: {
        padding: '0rem 0 1rem',
        textAlign: 'right',
        margin: '1rem'
    },
    listItem: {
        padding: '0.5em 0',
        margin: '1rem 0'
    },
    spacing: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    listItemStatuses: {
        margin: '1rem 0 1rem',
    },
    save: {
        justifyContent: 'flex-end',
        display: 'flex',
   },
   alias: {
        paddingRight: '2rem'
   }
}));