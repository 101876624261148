import React from "react";
import { Tooltip, PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { washDashboardStyles } from "../WashDashboardStyles";
import { useSelector } from "react-redux";
import { groupBy, _ } from "lodash";

const SlaChartCard = ({ vehiclesReadiness }) => {

    const [readinessPersentige, setReadinessPersentige] = React.useState(0);

    const classes = washDashboardStyles();
    const { filters } = useSelector(state => state.dashboardState);

    React.useEffect(() => {

        let readinessWithinSLAPercentage = 0, numOfClients = 0, readinessByClientFiltered = [];
        let readinessWithSLAFiltered = vehiclesReadiness?.filter(r => r.underSlaInLastCycle != null &&
            (!filters?.lotId?.length || filters?.lotId?.includes(r.lotId)) &&
            (!filters?.clientId || filters?.clientId == r.clientId));

        if (readinessWithSLAFiltered?.length > 0) {

            readinessByClientFiltered = groupBy(readinessWithSLAFiltered, readiness => readiness.clientId);

            Object.keys(readinessByClientFiltered).forEach(element => {
                const readinessWithinSLA = readinessByClientFiltered[element].filter(r => r.underSlaInLastCycle == true);
                readinessWithinSLAPercentage = readinessWithinSLAPercentage + ((readinessWithinSLA?.length / (readinessByClientFiltered[element]?.length)) * 100);
                numOfClients++;
            });
        }

        readinessWithinSLAPercentage = Math.round(readinessWithinSLAPercentage / numOfClients);
        setReadinessPersentige(readinessWithinSLAPercentage);
    }, [vehiclesReadiness, filters]);

    const SLAData = [
        { name: "Within SLA", value: readinessPersentige },
        { name: "Above SLA", value: 100 - readinessPersentige },
    ];

    const COLORS = ['#b6ed7b', '#ee9492'];

    const renderColorfulLegendText = (value) => {
        return (
            <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
                {value}
            </span>
        );
    };

    const CustomTooltip = ({ payload }) => {
        return (
            <div>
                <div className={classes.tooltip} >
                    <p>
                        <b>{payload?.[0]?.payload?.name} </b>
                        {payload?.[0]?.payload?.value}%
                    </p>
                </div>
            </div>
        );
    };

    return (
        <ResponsiveContainer>
        <PieChart>
            <Pie
                data={SLAData}
                dataKey="value" nameKey="name"
                cx={"50%"} cy={"50%"}
                innerRadius={50} outerRadius={90}
                paddingAngle={0.5} >
                {SLAData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Legend
                height={36}
                iconType="circle"
                layout="vertical"
                verticalAlign="middle"
                iconSize={10}
                padding={5}
                formatter={renderColorfulLegendText}
                wrapperStyle={{ top: 200, left: 15 }}
            />
            <Tooltip content={<CustomTooltip />} />
        </PieChart>
    </ResponsiveContainer> 
    )
}

export default SlaChartCard;