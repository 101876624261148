import { Paper } from "@material-ui/core";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import { TagInput } from "components/TagInput/TagInput";
import Validator from "services/validator";
import ContractHeader from "../../../ContractHeader/ContractHeader";
import { mechanicalConfigStyles } from "../../style";
import ClientServiceProviders from "../ServiceProviders/ClientServiceProviders";

export const GeneralTab = ({ mechanicalsConfigParams, selectedLot, onConfigChange, configName, clientToEdit }) => {
    const classes = mechanicalConfigStyles();

    return (
        <>
            {!selectedLot && <ContractHeader
                label={'Mechanicals Program'}
                flag={mechanicalsConfigParams?.useMechanicals}
                onChange={() => { onConfigChange(configName, "useMechanicals", !mechanicalsConfigParams?.useMechanicals) }}
            />}

            <Paper className={classes.marginWrapper}>
                <div className={classes.inputField}>
                    <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                        <CustomInput
                            disabled={!mechanicalsConfigParams?.useMechanicals}
                            id="margin"
                            value={mechanicalsConfigParams?.margin}
                            onChange={(val) => onConfigChange(configName, "margin", +val)}
                            elementType="input"
                            numberOnly
                            step="0.1"
                            label={"Margin"}
                            icon={<MonetizationOnOutlinedIcon width={30} height={30} className={classes.inputIcon} />}
                        />
                    </Authorize>
                </div>
                <div className={classes.inputLabel}>Send pending approval email to</div>
                <div className={classes.inputField}>
                    <Authorize
                        profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}
                        readonlyComponent={<TagInput tagString={mechanicalsConfigParams?.approvalEmails ?? ""} disabled />}
                    >
                        <TagInput
                            placeholder={'Add email'}
                            tagString={mechanicalsConfigParams?.approvalEmails ?? ""}
                            onChange={(val) => {
                                onConfigChange(configName, 'approvalEmails', val)
                            }}
                            validateFunc={(tag) => {
                                const res = Validator.validateEmail(tag)
                                return !!res;
                            }}
                        />
                    </Authorize>
                </div>
            </Paper>

            <ClientServiceProviders
                mechanicalsConfigParams={mechanicalsConfigParams}
                clientId={clientToEdit?.id}
                serviceProviders={clientToEdit?.clientServiceProviders}
                onConfigChange={onConfigChange}
            />
        </>
    )
}