function IconRepairInvoice(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M11.3785 8.3825C10.7185 7.7225 9.73853 7.5925 8.94853 7.9725L10.3485 9.3725L10.3185 9.5825L10.2185 10.2225L9.57854 10.3225L9.36854 10.3525L7.96854 8.9525C7.57854 9.7425 7.71854 10.7225 8.37854 11.3825C8.90854 11.9125 9.62854 12.1025 10.3085 11.9625L13.7485 15.4025C14.2085 15.8625 14.9485 15.8625 15.4085 15.4025C15.8685 14.9425 15.8685 14.2025 15.4085 13.7425L11.9585 10.2925C12.0985 9.6125 11.9085 8.8925 11.3785 8.3625V8.3825ZM14.9885 15.0025C14.8085 15.1825 14.5385 15.1825 14.3585 15.0025C14.1785 14.8225 14.1785 14.5525 14.3585 14.3725C14.5385 14.1925 14.8185 14.2025 14.9885 14.3725C15.1585 14.5425 15.1585 14.8325 14.9885 15.0025Z" fill="#0090FE"/>
            <path d="M15.7485 18.6625H7.74854C7.33854 18.6625 6.99854 18.3225 6.99854 17.9125C6.99854 17.5025 7.33854 17.1625 7.74854 17.1625H15.7485C16.1585 17.1625 16.4985 17.5025 16.4985 17.9125C16.4985 18.3225 16.1585 18.6625 15.7485 18.6625Z" fill="#0090FE"/>
            <path d="M7.75854 22.5125C5.13854 22.5125 2.99854 20.3825 2.99854 17.7525V5.7725C2.99854 3.6725 4.33854 1.8525 6.33854 1.2325C6.53854 1.1725 6.75853 1.1925 6.94853 1.3025L9.05854 2.5625L11.3585 1.1125C11.5985 0.9625 11.9085 0.9625 12.1585 1.1125L14.4585 2.5625L16.5785 1.3025C16.7585 1.1925 16.9785 1.1725 17.1885 1.2325C19.1785 1.8525 20.5185 3.6725 20.5185 5.7725V17.7525C20.5185 20.3725 18.3885 22.5125 15.7585 22.5125H7.75854ZM6.47854 2.7825C5.27854 3.2825 4.49854 4.4425 4.49854 5.7725V17.7525C4.49854 19.5525 5.95854 21.0125 7.75854 21.0125H15.7385C17.5385 21.0125 18.9985 19.5525 18.9985 17.7525V5.7725C18.9985 4.4525 18.2185 3.2825 17.0285 2.7825L14.8285 4.1025C14.5885 4.2525 14.2885 4.2425 14.0485 4.1025L11.7585 2.6625L9.46854 4.1025C9.22854 4.2525 8.92854 4.2525 8.67854 4.1025L6.47854 2.7825Z" fill="#0090FE"/>
        </svg>
    )
}

export default IconRepairInvoice;