import { Grid } from "@material-ui/core";
import Permission from "./Permission";
import roleManagementStyles from "./roleManagementStyles";
import { memo } from "react";

const useStyles = roleManagementStyles;
const RoleCategory = ({ category, onChangePermission, onChangeAllPermissions }) => {
    const classes = useStyles();

    const onChangePermissionBridge = async (p_permission, p_val, attribute) => {
        await onChangePermission(category, p_permission, p_val, attribute);
    }

    return (<Grid container direction="row" spacing={2} xs={12}>
        {
            category?.permissions?.map((permission) => (
                <Grid item xs={12}>
                    <Permission permission={permission} onChangePermission={onChangePermissionBridge} />
                </Grid>
            ))
        }
    </Grid>);
}
export default RoleCategory;