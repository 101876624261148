export const calculateReadinessStatus = (activitiesforAsset, load) => {

    const daysToMs = days => days * 24 * 60 * 60 * 1000;

    //for latest wash
    const dateDifference = (latestCompletedWashDate, depatureDate) => {
        const timeFromLastWash = new Date(new Date(depatureDate).setHours(0, 0, 0, 0)).getTime() - new Date(new Date(latestCompletedWashDate).setHours(0, 0, 0, 0)).getTime()
        if (timeFromLastWash <= daysToMs(30))
            return 1;
        if (timeFromLastWash > daysToMs(30) &&
            timeFromLastWash < daysToMs(37))
            return 2;
        if (timeFromLastWash >= daysToMs(37))
            return 3;
        //Verify this, if no wash 
        return 3;
    }

    //for inspection status
    const checkForAmeritInspectionStatus = (latestInspectionDate, load) => {
        const depatureDate = load?.pickupStartDate || new Date();
        const createdDate = load?.createdDate || new Date();
        const daysTillLoadDepature = new Date(new Date(depatureDate).setHours(0, 0, 0, 0)).getTime() - new Date(new Date(latestInspectionDate).setHours(0, 0, 0, 0)).getTime();
        if (latestInspectionDate && (new Date(new Date(createdDate).setHours(0, 0, 0, 0)).getTime() <= new Date(new Date(latestInspectionDate).setHours(0, 0, 0, 0)).getTime()))
            return 1;
        if (!latestInspectionDate && daysTillLoadDepature > daysToMs(3))
            return 2;
        if (!latestInspectionDate && daysTillLoadDepature <= daysToMs(3))
            return 3;
        return 3;
    }

    if (load?.statuses?.find(s => s.status == 5)) {
        load = undefined;
    }

    const latestCompletedWash = activitiesforAsset?.find(a => a.action?.toLowerCase()?.includes('wash') && a.action?.toLowerCase()?.includes('complete'));
    const latestAmeritInspection = activitiesforAsset?.find(a => a.action?.toLowerCase()?.includes('inspection') && a.action?.toLowerCase()?.includes('complete'));
    const washStatus = dateDifference(latestCompletedWash?.date, load?.pickupStartDate || new Date());
    const ameritInspectionStatus = checkForAmeritInspectionStatus(latestAmeritInspection?.date, load);

    let readinessStatus = Math.max(washStatus, ameritInspectionStatus)

    return { latestCompletedWash, latestAmeritInspection, status: readinessStatus, washStatus, ameritInspectionStatus, load }


    //return undefined;
}

export const readinessColors = {
    1: '#a6ef69',
    2: '#ffda4c',
    3: '#fd8f8f'
}

export const readinessTypes = {
    AUTO: 1,
    MANUAL: 2,
    Element:3
}

export const readinessStatuses = {
    GREEN: 1,
    YELLOW: 2,
    RED: 3
}